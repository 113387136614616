import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";

import { API } from "aws-amplify";
import "./VisitorBook.css";
//import LoaderButton2 from "../components/LoaderButton2";
//import LoaderButton3 from "../components/LoaderButton3";

import LoaderButtonX from "../components/LoaderButtonX";


import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import { BsArrowRepeat } from "react-icons/bs";


import Table from "react-bootstrap/Table";

import { makeStyles, withStyles, styled } from '@material-ui/core/styles';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Button from '@material-ui/core/Button';
//import Button from "../components/LoaderButtonX";
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';

import ButtonBase from '@material-ui/core/ButtonBase';


import Fab from '@material-ui/core/Fab';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';



import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

//import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
//import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
//import Brightness3Icon from '@material-ui/icons/Brightness3';
//import InvertColorsIcon from '@material-ui/icons/InvertColors';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

//import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';

import iTopOvernightOff from '../images/preview/Pay Per Night - Grey.svg';
import iTopBaysOff from '../images/preview/Parking - Grey.svg';
import iTopFacilitiesOff from '../images/preview/Facilities Only - Grey.svg';

import iTopOvernight from '../images/preview/Pay Per Night - Blue.svg';
import iTopBays from '../images/preview/Parking - Blue.svg';
import iTopFacilities from '../images/preview/Facilities Only - Blue.svg';


import iRecyclingOff from '../images/facilities/Recycling - Grey.svg';
import iBlackWaterOff from '../images/facilities/Black Water Disposal - Grey.svg';
import iElectricOff from '../images/facilities/Electric Hook Up - Grey.svg';
import iGreyWaterOff from '../images/facilities/Grey Water Disposal - Grey.svg';
import iLaundryOff from '../images/facilities/Laundry - Grey.svg';
import iOvernightOff from '../images/facilities/Overnight Parking - Grey.svg';
import iRubbishOff from '../images/facilities/Rubbish - Grey.svg';
import iShowerOff from '../images/facilities/Shower - Grey.svg';
import iToiletOff from '../images/facilities/Toilet - Grey.svg';
import iWaterOff from '../images/facilities/Water - Grey.svg';
import iWifiOff from '../images/facilities/Wifi - Grey.svg';

import iRecycling from '../images/facilities/Recycling - Coral.svg';
import iBlackWater from '../images/facilities/Black Water Disposal - Coral.svg';
import iElectric from '../images/facilities/Electric Hook Up - Coral.svg';
import iGreyWater from '../images/facilities/Grey Water Disposal - Coral.svg';
import iLaundry from '../images/facilities/Laundry - Coral.svg';
import iOvernight from '../images/facilities/Overnight Parking - Coral.svg';
import iRubbish from '../images/facilities/Rubbish - Coral.svg';
import iShower from '../images/facilities/Shower - Coral.svg';
import iToilet from '../images/facilities/Toilet - Coral.svg';
import iWater from '../images/facilities/Water - Coral.svg';
import iWifi from '../images/facilities/Wifi - Coral.svg';



import iCampra from '../images/campra/Campra Accredited - Green.svg';

import defaultSiteImage from '../images/Default-Image-Road.jpg';
import spinner from '../images/Driving-SVG.gif';


import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';


import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import Container from '@material-ui/core/Container';


import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';


import Form from "react-bootstrap/Form";

import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline';

import parse from 'html-react-parser';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

import iLogo from "../images/logo.jpg";
import iQRLogo from "../images/qrcode_logo.svg";

import iCampraA from '../images/campra/Campra Accredited - Green.svg';
import iCampraX from '../images/campra/Campra Accredited - Grey.svg';


import QRCode from 'qrcode';
import { createCanvas, loadImage } from "node-canvas";

import '../fonts/Montserrat-Regular-normal.js';


//import Calendar from 'react-calendar';
//import 'react-calendar/dist/Calendar.css';

//import CalendarPicker from 'react-native-calendar-picker';

//import DatePicker from "react-multi-date-picker";
import { Calendar, DateObject } from "react-multi-date-picker"
import "react-multi-date-picker/styles/colors/xxx.css"
import "react-multi-date-picker/styles/colors/red.css"
import colors from "react-multi-date-picker/plugins/colors"

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Collapse from '@material-ui/core/Collapse';


import Papa from 'papaparse';


import DownloadCSV from '../components/DownloadCSV';









export default function Uploader() {
  

  const history = useHistory();
  //const { userHasAuthenticated, isAuthenticated, isCampra, setShowSites} = useAppContext();

  const [fields, handleFieldChange] = useFormFields({
    email: "",
    reg: "",
  });
  const [tncs, setTncs] = useState(false);

  const { isAuthenticated, isCampra } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isSwitching, setIsSwitching] = useState(false);
  const [switchingId, setSwitchingId] = useState(null);

  const [open, setOpen] = React.useState([]);
  const [paymentResult, setPaymentResult] = React.useState(false);
  const [sessionValues, setSessionValues] = React.useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [alertVals, handleAlertChange] = useFormFields({
    title: "",
    message: "",
  });



  const [currentSite, setCurrentSite] = useState(null);
  const [content, setContent] = useState("");
  const [siteOpen, setSiteOpen] = useState(false);
  //const [base64Image, setBase64Image] = useState("");
  //const [siteCode, setSiteCode] = useState("");
  //const [desc, setDesc] = useState("");
  //const [rules, setRules] = useState("");
  const [address, setAddress] = useState("");
  //const [accreditations, setAccreditations] = useState([]);


  const [sites, setSites] = useState([]);



  const [calendarVal, onCalendarVal] = useState([
    //new DateObject().setDay(5),
    ///new DateObject().setDay(12).color="red",
    //new DateObject().setDay(14).add(1, "month"),
    //new DateObject().setDay(23).add(1, "month"),

  ]);

  const [closedDatesChanged, setClosedDatesChanged] = React.useState([]);
  const [calendarExpanded, setCalendarExpanded] = React.useState([]);
  const handleExpandCalendarClick = (idx) => {
    const curr = calendarExpanded[idx];
    let newArr = new Array(calendarExpanded.length).fill(false);
    newArr[idx] = !curr;
    setCalendarExpanded(newArr);
  };


  const [forceUpdate, setForceUpdate] = React.useState(false);

  const [dateRange, setDateRange] = useState([
    new DateObject()
  ])

  const [isUploading, setIsUploading] = useState(false);











  

  useEffect(() => {

    onLoad();
  }, []);


  async function onLoad() {
    if (!isAuthenticated) {history.push("/"); return;}


  }

  ///////////////// UPLOADER //////////////////////////

  function createSite(site) {
    return API.post("aires", "/sites", {
      body: site
    });
  }
  async function buildSite(content) {
    try {
      console.log(content);
      //const result = await createSite({ content: content, image: "", itemStatus: 8}); // itemStatus -> DEfault = 8 (i.e. NON-Airestop site)
      return true;
    } catch (e) {
      onError(e);
      return false;
    }


  }


var datafiles;
var fileIndex = 0;

const handleFile = e => {
    const content = e.target.result;
  
    const results = Papa.parse(content, {
        header: true, // Set to true if the first row contains column names
        skipEmptyLines: true, // Skip empty lines
        delimiter: ',', // Specify the delimiter if not a comma
    });
    //console.log(results.data);
    
let doneone = false;
    results.data.forEach(site => {
        const isOvernight = site.sD.includes("Overnight parking");
        const hasFacilities = site.sD.includes("with services");
        const content = {"siteName": site.Name?site.Name:"no name"};
        content["priceFacilities"] = site["Cost Min"]?site["Cost Min"]:0;
        content["priceStay"] = site["Cost Max"]?site["Cost Max"]:0;
         content["desc"] = site.sD?site.sD:"";
         content["facilities"] = {
          "facONS": site.Type=="ASN" || site.Type=="APN",
          "facBWD": false,
          "facGWD": false,
          "facEHU": false,
          "facLND": false,
          "facREC": false,
          "facRUB": false,
          "facSWR": false,
          "facTLT": false,
          "facWTR": false,
          "facWFI": false
      };

        const facs =  site["Facilities"]?site["Facilities"]:"";
      const facArr = facs.split(",");
      for (let i=0;i<facArr.length; i++) {
        //console.log(Number(facArr[i]));
        switch (Number(facArr[i])) {
          case 1:
            content.facilities.facWTR = true;
            break;
              case 2:
                content.facilities.facGWD = true;
                break;
                case 3:
                  content.facilities.facBWD = true;
                  break;
                  case 4:
                    content.facilities.facTLT = true;
                    break;
                    case 5:
                      content.facilities.facEHU = true;
                      break;
                      case 6:
                        content.facilities.facSWR = true;
                        break;
                        case 7:
                          content.facilities.facWFI = true;
                          break;
                          case 11:
                            content.facilities.facLND = true;
                            break;
        }
      }
      //console.log(site.Type, site["Type"]);
      //console.log(facArr);

        content["facebook"] = site["facebook"]?site["facebook"]:"";
        content["email"] = site["Email"]?site["Email"]:"";
        content["website"] = site["Href"]?site["Href"]:"";
        content["address"] = site["Address"]?site["Address"]:"";
        content["location"] = {
          "lat": Number(site["Latitude"]),
          "lng": Number(site["Longitude"])
      };
      content["id"] = site["ID"];
      content["id1"] = site["ID_1"];
      //console.log(content);

            //if (!doneone) {
            //  doneone = true;
              let rslt = buildSite(content);
              //console.log(rslt);
            //}

    });

    
    

    setIsUploading(false);






   

























  /*
    try {
      const result = await uploadData({ aaa:111});
      readNextFile();
    } catch (e) {
      onError(e);
      setIsUploading(false);
    }
      */
  }
  /*
  function readNextFile() {
    fileIndex += 1;
    if (fileIndex >= datafiles.length) {
      setIsUploading(false);
      return;
    }
    var reader = new FileReader();
    reader.onloadend = handleFile;
    var f = datafiles[fileIndex];
    reader.readAsText(f);
  }
    */

  function readNextFile() {
    //console.log(datafiles);
    fileIndex += 1;
    if (fileIndex >= datafiles.length) {
      setIsUploading(false);
      return;
    }
    var reader = new FileReader();
    reader.onloadend = handleFile;
    var f = datafiles[fileIndex];
    reader.readAsText(f);
  }
  
  function uploadData(data) {
    //console.log(data);
    
    return API.post("spivolaaatsss", "/org/sitesaaaaaaaaaa", {
      body: data
    });
    
  }
    
  
  const handleUploadClick = async (event) => {
    event.preventDefault();
    if (isUploading) {
      return;
    }
    
  
    //console.log("uploading...");
    
  
  
    if (event.target.files.length == 0) {
      
      return;
    }
    setIsUploading(true);
  
    fileIndex = -1;
    datafiles = event.target.files;
    readNextFile();
  }

  function renderLoader() {
    return (
      <div id="splash" style={{ paddingTop: 100, zIndex: 999 }}>

        <center id="splash">
          <img src={spinner} height="30px" width="auto" />
          {/*}<Typography style={{padding: '4px'}} variant="caption">{label}</Typography>*/}
        </center>
      </div>


    );

  }


  function renderLander() {
    return (
        <Box display="flex" alignItems='center' flexDirection="row" flexWrap = 'nowrap' >
    <label htmlFor="upload-data">
    <input
      accept=".CSV"
      
      id="upload-data"
      name="upload-data"
      multiple
      type="file"
      onChange={handleUploadClick}
      hidden
      />
              <Button color="primary" variant="contained" component="span" startIcon={<CloudUploadIcon/>}>Upload</Button>
 
    </label>
    
    
    </Box>
    )
  }

  
  return (

    <div className="Sites">
      {isLoading ? renderLoader() : renderLander()}
    </div>
  );
}