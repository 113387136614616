import PropTypes from "prop-types";
import styles from "./FooterPublicLrg.module.css";

import { useHistory } from "react-router-dom";

import logo from'../images/logo-12@2x.png';
import { Link } from 'react-router-dom';



export default function FooterPublicLrg() {
  const history = useHistory();
  //let className = "";
  function handleAbout(event) {
    event.preventDefault();
    history.push("/about");
    //setExpanded(false);
  }

  function handleThinking(event) {
    event.preventDefault();
    history.push("/hosting");
    //setExpanded(false);
  }
  
  function handleSignup(event) {
    event.preventDefault();
    history.push("/signup");
  }
  
  function handleContactClick(event) {
    event.preventDefault();
    history.push("/contact", { from: 'thinking' } );
  }
  

  
  return (
    <footer className={styles.footerpublicLrg}>
      <div className={styles.content}>
        <div className={styles.companyInfo}>
          <div className={styles.logoRegmarkLarge}>
            <img className={styles.logoIcon} alt="" src={logo} />
          </div>
          <div className={styles.airestopLimitedCompany}>
            AireStop Limited. Company Number: SC707418
          </div>
          <div className={styles.socialIcons}>
            <div className={styles.entypoSocialfacebookWithCi}>
              <img className={styles.vectorIcon} alt="" src="/vector-24.svg" />
            </div>
            <div className={styles.entypoSocialtwitterWithCir}>
              <img className={styles.vectorIcon1} alt="" src="/vector-25.svg" />
            </div>
            <div className={styles.entypoSocialinstagramWithC}>
              <img className={styles.vectorIcon2} alt="" src="/vector-26.svg" />
            </div>
          </div>
          <div
            className={styles.airestopLimitedAll}
          >{`©️2025 AireStop Limited. All rights reserved. `}</div>
        </div>
        <div className={styles.menus}>
          <div className={styles.company}>
            <div className={styles.company1}>Company</div>
           <Link className={styles.aboutUs} style={{color:"#FFF"}} to="/#"  onClick={handleAbout} >About Us</Link>
            <a className={styles.aboutUs} style={{color:"white"}} href={"https://airestop.co.uk/docs/tncs.pdf"} target="_blank">Terms of Use</a>
            <a className={styles.aboutUs} style={{color:"#FFF"}}  href={"https://airestop.co.uk/docs/privacy.pdf"} target="_blank">Privacy Notice</a>
          </div>
          <div className={styles.support}>
            <div className={styles.support1}>Support</div>          
            <Link className={styles.aboutUs} style={{color:"#FFF"}} to="/#"  onClick={handleContactClick} >Contact us</Link>            
            <a className={styles.aboutUs} style={{color:"#FFF"}}  href={"https://airestop.co.uk/docs/faqs.pdf"} target="_blank">FAQ</a>
          </div>
          <div className={styles.hosting}>
            <div className={styles.hosting1}>Hosting</div>
            <Link className={styles.aboutUs} style={{color:"#FFF"}} to="/#"  onClick={handleThinking} >Thinking about Hosting</Link>
            <Link className={styles.aboutUs} style={{color:"#FFF"}} to="/#"  onClick={handleSignup} >Become a Host</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
