import React , { useState, useEffect } from 'react';
import { API } from "aws-amplify";
//import { useHistory } from 'react-router-dom';
import "./DailyTransactions.css";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
/*
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import TickIcon from '@material-ui/icons/CheckCircleRounded';
import ExclamationIcon from '@material-ui/icons/ErrorRounded';
import SelectedIcon from '@material-ui/icons/RadioButtonChecked';
import CancelIcon from '@material-ui/icons/Cancel';
import { green } from '@material-ui/core/colors';
import { orange } from '@material-ui/core/colors';
import { red } from '@material-ui/core/colors';
*/

import iTopOvernight from '../images/preview/Pay Per Night - Blue.svg';

import iTopFacilities from '../images/preview/Facilities Only - Blue.svg';

import LoaderButtonX from "../components/LoaderButtonX";

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DateSelection from './DateSelection';
import CalendarView from './CalendarView';
import HostBookingDetails from './HostBookingDetails';



const DailyTransactions = ({site, reload, scrollPos, setScrollPos}) => {
  const content=site.content;




  const bookings=site.bookedDates;
  //const history = useHistory();
  const [bookingState, setBookingState] = useState({open: false, dialogType: "none",current: "dateselection", prev:""});
  const today = new Date();
  //console.log(site.itemStatus);
  const emptyBookingsArr = new Array(Number(content.bays)).fill("");
  const todayStr = today.toISOString().split('T')[0];
  const tomorrow = new Date(today)
  tomorrow.setDate(today.getDate() + 1)
  const tomorrowStr = tomorrow.toISOString().split('T')[0];
  const yesterday = new Date(today)
  yesterday.setDate(today.getDate() - 1)
  const yesterdayStr = yesterday.toISOString().split('T')[0];
  today.setHours(12,0,0,0);
  //console.log(site);
  console.log(bookings);
  let startDate;
  let endDate;
  if (bookings && bookings.length>0) {
   startDate = bookings[0].date;
   endDate = bookings[bookings.length-1].date;
  } else {
    startDate = yesterdayStr;
    endDate = (new Date(today.getDate() + 15)).toISOString().split('T')[0];
  }
  const [noStripePaymentSuccess, setNoStripePaymentSuccess] = React.useState(false);
  const [cancelBookingSuccess, setCancelBookingSuccess] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [dragData, setDragData] = useState({});

  const [isPaying, setIsPaying] = useState(false);
  const [confirmCancellation, setConfirmCancellation] = useState(false);


  //const [isLoading, setIsLoading] = useState(true);
  //const [isPaying, setIsPaying] = useState(false);

  //const [isMakingPayment, setIsMakingPayment] = useState(false);
  //bookingState.card.date.

  const [bookingData, setBookingData] = useState({
    selectedDates: [],
    email: '',
    payType: '',
    payMethod: '',
    vehicleRegistration: '',
    phoneNumber: '',
    totalAmount: 0,
    donation: 0,
    paying: false
  });
  const [pitchfeatures, setPitchfeatures] = useState({reqLength:0, hookup: false, surfaces:{grass:false, gravel:false,hard:false}});





  //console.log(startDate,endDate);

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  let firstDate = new Date(startDate);
  if (firstDate.getTime() > today.getTime()) {
    firstDate = today;
  }
  let secondDate = new Date(endDate);
  let diffDaysAhead = (secondDate-today)/ oneDay;
  if (secondDate-today<0) {
      secondDate=today;
      diffDaysAhead = 30;
  } else {
    if (diffDaysAhead>30) {
      diffDaysAhead = 0;
    } else {
      diffDaysAhead = 30 - diffDaysAhead;
      
    }
  }
  //console.log(firstDate);
  //console.log(secondDate);
  //console.log(diffDaysAhead);

  //diffDaysAhead=0;


  let diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
  const diffDaysFromToday = (Math.round(Math.abs((firstDate - today) / oneDay)))-1;
  //const [scrollPos, setScrollPos] =  useState(((diffDaysFromToday-1)*180)-40);
  //console.log(bookings);

  //console.log(diffDays,diffDaysFromToday);

  ////console.log("startttttt.....",scrollPos,((diffDaysFromToday-1)*180)-40);

  //console.log(scrollPos);
  //console.log(scrollPos[site.GSI1SK]);


  const generateDateCards = () => {
    
    const dateCards = [];

    /*
    let cutoff = 0;
    if (content.maxBookAhead && content.maxBookAhead>0) {
        cutoff = content.maxBookAhead;
    }
    */
    //for (let i = -5; i < 30; i++) {

    //calc full date range 

     //console.log(diffDays, startDate, endDate);
     //console.log(bookings);
     let dateList=[];
     bookings.forEach(booking => {
        dateList.push(booking.date);
     });
     //console.log(dateList);

    for (let i = 0; i <= (diffDays+diffDaysAhead); i++) {
      let dayBookings=null;
      const date = new Date(firstDate);
      date.setDate(firstDate.getDate() + i);
      date.setHours(12,0,0,0);
      const datesStr = date.toISOString().split('T')[0];
      //console.log(datesStr);
      //let isBooking = false;

      const idx = dateList.indexOf(datesStr);
      let bayCount = bookings[idx]?bookings[idx].overnight.length:0;
      if (content.bayList && content.bayList.length>0) { //VER 
        dayBookings = {facilities:[], overnight: []};
        dayBookings.facilities=bookings[idx]?bookings[idx].facilities:[];
        content.bayList.forEach(bay=>{
          let bk = null;
          
          if (bookings[idx]) {
            for (let bookingIdx=0;bookingIdx<bookings[idx].overnight.length;bookingIdx++) {
              if (bookings[idx].overnight[bookingIdx].pitch) {
                //const pitch = JSON.parse(bookings[idx].overnight[bookingIdx].pitch);
                if (bookings[idx].overnight[bookingIdx].pitch.id == bay.id) {
                  //console.log(",,,,,,,,,,,,,,");
                  //console.log(bookings[idx].overnight[bookingIdx]);
                  //console.log(",,,,,,,,,,,,,,,,");
                  //tranId = bookings[idx].tranId;
                  bk = bookings[idx].overnight[bookingIdx];
                  break;
                }
              }
            }
          }
          dayBookings.overnight.push({bay: bay, booking: bk});        
        })
        //dayBookings.overnight = bayList;
      } else {// OLD VER
        if (idx>-1)  {
            //dayBookings=bookings[idx];
            dayBookings = {facilities:[], overnight: []};
            dayBookings.facilities=bookings[idx]?bookings[idx].facilities:[];
            //console.log(dayBookings.overnight);
            for (let bookingIdx2=0;bookingIdx2<bookings[idx].overnight.length;bookingIdx2++) {
              //console.log("..............");
              //console.log(bookings[idx]);
              //console.log("..............");
              dayBookings.overnight.push({bay: null, booking: bookings[idx].overnight[bookingIdx2]}); 
              //isBooking = true;
            }
            for (let idx2=0;idx2<(content.bays-bayCount);idx2++) {
              //console.log("push:"+idx2);
              //dayBookings.overnight.push({nobooking: true});
              dayBookings.overnight.push({bay: null, booking: null}); 
            }
        }  
      }
      //if (isBooking) {
        //console.log(dayBookings);
      //}



 



        //console.log(datesStr);
        let dayName="";
        if (datesStr==todayStr) {
            dayName="Today";
        } else if (datesStr==yesterdayStr) {
            dayName="Yesterday";
        } else if (datesStr==tomorrowStr) {
            dayName="Tomorrow";
        }
        let inPast=false;
        if (date.getTime()<today.getTime()) {
            inPast=true;
        }
        let status = "ok"; 
        /*let bayReserveCount = 0;
        if (bookings && bookings.length>0) {
            bookings.forEach(booking => {
                //if (booking.date == date.getTime()) {
                if (booking.date == datesStr) {
                    bayReserveCount = booking.bayReserveCount;
                    if (content.bays - bayReserveCount<=0) {
                        status="full";
                    }
                }
            });
        }
    

        if (content.closedDates && content.closedDates.length>0) {
            if (content.closedDates.includes(date.getTime())) {
                status="closed";
            }
        }
        //console.log("i=", i, cutoff);
        if (cutoff>0 && i>cutoff) {
            status="unavailable";
        }
        if (today>date) {
            status="prevday";
        }
             */    
      

      //console.log(dayBookings);

      dateCards.push({date:date, status: status, dayBookings: dayBookings, dayName: dayName, inPast: inPast, bayCount: bayCount});
    }
//DELETE BELOW TEST DATA
    //dateCards.push({date:new Date("2024-11-06"), status: "ok", dayBookings: null, dayName: "", inPast: false});
    //dateCards.push({date:new Date("2024-11-08"), status: "ok", dayBookings: null, dayName: "", inPast: false});
    //dateCards.push({date:new Date("2024-11-09"), status: "ok", dayBookings: null, dayName: "", inPast: false});
    //dateCards.push({date:new Date("2024-11-11"), status: "ok", dayBookings: null, dayName: "", inPast: false});



    return dateCards;
  };

  
  
  useEffect(() => {
    document.getElementById("carousel").scrollLeft=scrollPos[site.GSI1SK];
    /*
    if (dragData && dragData.scrollPosdragData.scrollState>0) {
      document.getElementById("carousel").scrollLeft=dragData.scrollPos;
      if (!dragData.showDialog) {
        setDragData({...dragData,scrollState:0});
      }
    } else {
    //console.log(bookingState);
   if (bookingState.scrollState>0) {
      document.getElementById("carousel").scrollLeft=bookingState.scrollPos;
      console.log(bookingState.scrollPos);
    } else {
      document.getElementById("carousel").scrollLeft=((diffDaysFromToday-1)*180)-40;
    }
  
  }
    */
  }, [generateDateCards]);


  







  async function handleStripePayClick() {
    //setIsLoading(true);
    if (!bookingData.paying) {return;}
    //setIsMakingPayment(true);
    let regStatus = {};
    //const regResult = await getRegCheck(fields.reg);
    const regResult = await getRegCheck(bookingData.vehicleRegistration);
    if (regResult.status == false) {
      if (regResult.error.message.indexOf("400") > -1) {
        //console.log("Invalid reg number format");
        regStatus = { result: "400", data: { message: regResult.error.message } };
      } else if (regResult.error.message.indexOf("404") > -1) {
        //console.log("Reg not found");
        regStatus = { result: "404", data: { message: regResult.error.message } };
      } else {
        regStatus = { result: "000", data: { message: regResult.error.message } };
      }
    } else {
      regStatus = { result: "200", data: regResult.data };
    }



    const currentUrl = window.location.href.split("?")[0];
    
       
    //console.log(bookingData);
    //console.log(bookingState);

    


    //console.log(site);
    // Note when=3 always for version 2 ie 3=use startdate in stripePayment.js
    const metadata = { version: 2, campraDonation: bookingData.donation*100, when: 3, vehicle_reg: bookingData.vehicleRegistration, regStatus: JSON.stringify(regStatus), location: {}, siteCode: site.itemCode, siteId: site.itemId, payType: bookingData.payType, ownerId: site.pk, email: bookingData.email, marketing: 0, fee: content.fee, dates: bookingData.selectedDates.join(), pitch:JSON.stringify(bookingData.pitch), pitchRequirements: JSON.stringify(bookingData.pitchRequirements)};
    //console.log(metadata);
    //return;



    let result = await createPayment({ url: currentUrl, email: bookingData.email, metadata: metadata });



    //console.log(result);
    setBookingState({open: false, dialogType: "none",current: "dateselection", prev:""});
    setBookingData({
      selectedDates: [],
      email: '',
      payType: '',
      payMethod: '',
      vehicleRegistration: '',
      phoneNumber: '',
      totalAmount: 0,
      donation: 0,
      paying: false
    });
    if (result.status && result.noStripe) {     
      reload();
      setNoStripePaymentSuccess(true);
    } else {
      if (result.error) {
        setErrorMessage(result);
      } else {
        setErrorMessage({ error: "Network error, please try again later.", code: 0 });
      }
    }

  }

  function handleErrorClose() {
      setErrorMessage(null);
    
  }

  function getRegCheck(reg) {
    try {
      return API.post("aires", "/dvla", {
        body: { reg: reg }
      });
    } catch {
      //setIsLoading(false);
    }
  }

  

  function createPayment(content) {
    try {
      return API.post("aires", "/payments", {
        body: { type: 'hostBookingPayment', content: content }
      });
    } catch {
      //setIsLoading(false);
    }
  }


  function cancelBooking(content) {
    try {
      return API.post("aires", "/payments", {
        body: content
      });
    } catch {
      //setIsLoading(false);
    }
  }






  const nthNumber = (number) => {
    if (number > 3 && number < 21) return "th";
    switch (number % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const handleAddBooking = (card,index) => {
    //const scrollPos=document.getElementById("carousel").scrollLeft;
    //console.log(scrollPos);
    //console.log(card.dayBookings.overnight[index]);
    if (card.dayBookings || index==0) {
      if (card.dayBookings && card.dayBookings.overnight[index].booking) { //pitch with index has already been booked
        
        setScrollPos( {...scrollPos, [site.GSI1SK]: document.getElementById("carousel").scrollLeft});
        //setScrollPos(document.getElementById("carousel").scrollLeft);
        //console.log(index);
        //console.log(card);
        //console.log(card.dayBookings.overnight[index].booking);
        //console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
      setBookingState({...bookingState, open: true, dialogType: "viewer", card: card, index: index, booking:card.dayBookings.overnight[index].booking, bookingType: "overnight"});
      
    } else { // can add booking for pitch if not in the past
      if (card.inPast) {
        return;
      } else { 
        //setScrollPos(document.getElementById("carousel").scrollLeft);
        setScrollPos( {...scrollPos, [site.GSI1SK]: document.getElementById("carousel").scrollLeft});
        setBookingState({...bookingState, open: true, dialogType: "booking", card: card, index: index, current: "dateselection", prev:""});
        //console.log(card);
        }
    }
  }
    //console.log(card);
    //console.log(index);
  };

  const handleAddFacilitiesPayment = (card, index) => {
    //console.log(card, index);
    if (card.dayBookings || index==0) {
      if (card.dayBookings && card.dayBookings.facilities[index]) {
        setScrollPos( {...scrollPos, [site.GSI1SK]: document.getElementById("carousel").scrollLeft});
        setBookingState({...bookingState, open: true, dialogType: "viewer", card: card, index: index, booking:card.dayBookings.facilities[index], bookingType: "facilities"});
      
      } else {
        //if (card.inPast) {
        //  return;
        //} else { 
          setScrollPos( {...scrollPos, [site.GSI1SK]: document.getElementById("carousel").scrollLeft});
          setBookingData({...bookingData, selectedDates: [card.date.toISOString().split('T')[0]]});
          setBookingState({...bookingState, open: true, dialogType: "facilities", card: card, index: index, current: "userdetails", prev:""});
        //}
      }
    }
  };

  const handlePaymentClose = (e) => {
    
    if (isPaying) {return;}
    e.preventDefault();
    setBookingData({
      selectedDates: [],
      email: '',
      vehicleRegistration: '',
      phoneNumber: '',
      totalAmount: 0,
      donation: 0
    });
    setConfirmCancellation(false);
    setBookingState({...bookingState, open: false, dialogType: "none", card: null});
   };





   const handleCancelBooking = async (e, booking) => {
    e.preventDefault();
    //console.log(booking);
    //return;
    setIsPaying(true);
    
    let canProceed = true;



    let transactionType = null;

    if (booking.itemStatus==1) {
      transactionType = "airestopRefund";
    } else if (booking.itemStatus==2) {
      transactionType = "hostBookingCancellation";
    } else {
      canProceed = false;
    }
    const currentUrl = window.location.href.split("?")[0];
    if (canProceed) {
      let result = await cancelBooking({type: transactionType, content: { url: currentUrl, email: booking.email, paymentIntent: booking.paymentIntent, metadata:{tranId: booking.tranId, siteCode: booking.siteCode, ownerId: site.pk}}});
      //console.log(result);
      if (result.status) {   
        setBookingData({
          selectedDates: [],
          email: '',
          vehicleRegistration: '',
          phoneNumber: '',
          totalAmount: 0,
          donation: 0
        });
        setBookingState({...bookingState, open: false, dialogType: "none", card: null});
        reload();
        setIsPaying(false);
        setCancelBookingSuccess(true);
      } else {
        setIsPaying(false);
        setBookingData({
          selectedDates: [],
          email: '',
          vehicleRegistration: '',
          phoneNumber: '',
          totalAmount: 0,
          donation: 0
        });
        setBookingState({...bookingState, open: false, dialogType: "none", card: null});
        if (result.error) {
          setErrorMessage(result.error);
        } else {
          setErrorMessage({ error: "Network error, please try again later.", code: 0 });
        }
      }




    } else {
      setIsPaying(false);
      setBookingData({
        selectedDates: [],
        email: '',
        vehicleRegistration: '',
        phoneNumber: '',
        totalAmount: 0,
        donation: 0
      });
      setBookingState({...bookingState, open: false, dialogType: "none", card: null});
  
    }

    











   










   };













  const closeNoStripePaymentSuccess = (e) => {
    setNoStripePaymentSuccess(false);
    setCancelBookingSuccess(false);
  }
  

  const getBookingsForDateSelector = () => {
    let selectorBookings = [];
    bookings.forEach(card => {
      //console.log(card);
      //console.log(card.overnight.map(function (booking) { return booking} ));
      selectorBookings.push({date: card.date, bayReserveCount: card.overnight.length, pitches: card.overnight.map(function (booking) { return {id: booking.pitch?booking.pitch.id:-1} })});
    })
    //console.log(selectorBookings);
    return selectorBookings;
  };


/*
  const handleCalendarView = () => {
    //history.push('/calendar');
    setBookingData({ ...bookingData, selectedDates: selectedDates });
    
    setBookingState({current:"calendarview", prev:"dateselection"});

  };

  const handleSelectDates = () => {
    if (selectedDates.length > 0) {
        setPayType("overnight");
        setBookingData({ ...bookingData, selectedDates: selectedDates });
        setBookingState({current:"userdetails", prev:"dateselection"});
    }
  };

  const handleFacilitiesUse = () => {
    setPayType("facilities");
    setBookingState({current:"userdetails", prev:"dateselection"});
};



  function dateRange(startDate, endDate, steps = 1) {
    const dateArray = [];
    let currentDate = startDate;
    const maxNights = content.maxNights || 0;
    while (currentDate <= endDate && (maxNights==0 || dateArray.length<=maxNights-1)) {
        if ((content.closedDates && content.closedDates.includes(currentDate.getTime())) || isFullOnDate(String(currentDate.getTime()))) {
            return dateArray;
        }
      dateArray.push(currentDate.toISOString().split('T')[0]);
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }
    return dateArray;
}


function isFullOnDate(timestamp) {
    let isFull = false;
    if (bookings && bookings.length>0) {
        bookings.forEach(booking => {
            if (booking.date == timestamp) {
                if (content.bays - booking.bayReserveCount<=0) {
                    isFull = true;
                }
            }
        });
    } 
    return isFull;
}







*/
 let prevColor = "";
 let dragDate = "";
 let draggedPitchIndex = -1;
 
//const [dragData, setDragData] = useState({});
  // Are we hovering over the noDrop div?
  //const [noDrop, setNoDrop] = useState("");

  const handleDragStart = (e,card,index) => {
    draggedPitchIndex = index;
    dragDate = card.date.toISOString().split('T')[0];
    prevColor = e.target.parentElement.style.backgroundColor;
    
    //setDragData({ id: e });
    //console.log(card);
  };

  // If we enter the noDrop zone the state will be updated
  // Used for styling.
  const handleDragEnter = (e,card,index) => {
    e.preventDefault();
    //if (card.dayBookings.overnight[index].booking || dragDate!=(card.date.toISOString().split('T')[0])) {
    if (draggedPitchIndex==index || dragDate!=(card.date.toISOString().split('T')[0])) {
        e.dataTransfer.dropEffect = 'none';
      return;
    }
    e.target.style.backgroundColor = "#33cc33";
    e.target.parentElement.style.backgroundColor = "#33cc33";
    e.target.parentElement.parentElement.style.backgroundColor = "#33cc33";
   };

  
  const handleDragOver = (e,card,index) => {
    e.preventDefault();
    //if (card.dayBookings.overnight[index].booking || dragDate!=(card.date.toISOString().split('T')[0])) {
    if (draggedPitchIndex==index || dragDate!=(card.date.toISOString().split('T')[0])) {
        e.dataTransfer.dropEffect = 'none';
      return;
    }
    //e.target.style.backgroundColor = "#cc3333";
    //e.target.parentElement.style.backgroundColor = "#cc3333";
    //e.target.parentElement.parentElement.style.backgroundColor = "#cc3333";
   // card.dayBookings.overnight[index]["highlight"] = true;
    //console.log(card.dayBookings.overnight[index]);
  };
  
  const handleDragLeave = (e,card,index) => {
    //setNoDrop("");
    e.preventDefault();
    //if (card.dayBookings.overnight[index].booking || dragDate!=(card.date.toISOString().split('T')[0])) {
    if (draggedPitchIndex==index || dragDate!=(card.date.toISOString().split('T')[0])) {
        return;
    }
    e.target.style.backgroundColor = "#ffffff";
    e.target.parentElement.style.backgroundColor = prevColor;
    e.target.parentElement.parentElement.style.backgroundColor = prevColor;
  
  };
  
  const handleDragDrop = (e,card,index) => {
    //console.log(index);
    e.preventDefault();
    //if (card.dayBookings.overnight[index].booking || dragDate!=(card.date.toISOString().split('T')[0])) {
    if (draggedPitchIndex==index || dragDate!=(card.date.toISOString().split('T')[0])) {
        return;
    }
    e.target.style.backgroundColor = "#ff0000";
    e.target.parentElement.style.backgroundColor = prevColor;
    e.target.parentElement.parentElement.style.backgroundColor = prevColor;


///calc all stuff here and put in dragData
    let title = "Change pitch";
    let message = "Are you sure you wish to change this bookings bay?";
    //console.log(card.dayBookings.overnight[draggedPitchIndex]);
    //console.log(card.dayBookings.overnight[index]);

    //setScrollPos(document.getElementById("carousel").scrollLeft);
    setScrollPos( {...scrollPos, [site.GSI1SK]: document.getElementById("carousel").scrollLeft});
    setDragData({showDialog: true, card: card, index: index, fromIndex:draggedPitchIndex, dropTarget: e.target, message:message, title:title});
  
  };

  const closeDragDrop = (changePitch) => {
    //if (dragData && dragData.card) {
    if (dragData && dragData.dropTarget) {
      dragData.dropTarget.style.backgroundColor = "#ffffff";
      dragData.dropTarget.parentElement.style.backgroundColor = prevColor;
      dragData.dropTarget.parentElement.parentElement.style.backgroundColor = prevColor;  
   }
    if (changePitch) {
        //console.log(dragData.index,dragData.fromIndex)
        //console.log(dragData.card);
        //updateBayBooking();
    }
    //setDragData({...dragData, showDialog: false});
    setDragData(null);
  }
  

  return (
    <>
          <Dialog
        maxWidth='xs'
        open={errorMessage?true:false}
        onClose={handleErrorClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" style={{ color: "#10385A" }} >{"Transaction Unsuccessful"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Unfortunately we were unable to process this transaction for the following reason: {errorMessage && errorMessage.error}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorClose} color="primary" >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
          <Dialog
        maxWidth='xs'
        open={noStripePaymentSuccess}
        onClose={closeNoStripePaymentSuccess}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" style={{ color: "#10385A" }} >{"Transaction Successful"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Thank you, your transaction was successful.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeNoStripePaymentSuccess} color="primary" >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth='xs'
        open={cancelBookingSuccess}
        onClose={closeNoStripePaymentSuccess}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" style={{ color: "#10385A" }} >{"Cancellation Successful"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Thank you, your booking cancellation was successful.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeNoStripePaymentSuccess} color="primary" >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth='xs'
        open={(dragData && dragData.showDialog)?dragData.showDialog:false}
        onClose={closeDragDrop}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" style={{ color: "#10385A" }} >{dragData && dragData.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          {dragData && dragData.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={e=>{closeDragDrop(true)}} color="primary" >
            Change Pitch for Booking
          </Button>
          <Button onClick={e=>{closeDragDrop(false)}} color="primary" >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    <Dialog
    maxWidth='md'
    open={(bookingState.dialogType!="none" && bookingState.open && bookingState.current!="")?true:false}
    onClose={handlePaymentClose}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle id="alert-dialog-slide-title" style={{ color: "#10385A" }} >{bookingState.dialogType=="viewer"?"Booking Details" + (bookingState.bookingType=="facilities"?" (facilities use)":" (overnight stay)"):bookingState.dialogType=="facilities"?"Add Facilities Payment for " + bookingState.card.date.toLocaleDateString('en-UK') :bookingState.dialogType=="booking"?"Add Booking" + (bookingState.current=="userdetails"?" (" + (new Date(bookingData.selectedDates[0])).toLocaleDateString('en-UK') + " to " + (new Date(bookingData.selectedDates[bookingData.selectedDates.length-1])).toLocaleDateString('en-UK') + ")":""):"invalid"}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        {bookingState.dialogType=="viewer"?
        
      
      <>
      {/*}
       <Box p={2}>
      {JSON.stringify(bookingState.booking)}
      </Box>
      */}
     {bookingState.bookingType=="facilities"?
     <>
      <b>Booking ID:</b> {bookingState.booking.tranId}<br/>
       
      <b>Booking created on:</b> {(new Date(bookingState.booking.timestamp)).toLocaleDateString('en-UK')}<br/>
     
      <b>Booking created by:</b> {bookingState.booking.itemStatus==1?"guest (" + bookingState.booking.email + ")":bookingState.booking.itemStatus==2?"you":""}<br/>
                 
      <b>Date of facilities use:</b> {(new Date(bookingState.booking.timestamp)).toLocaleDateString('en-UK')}<br/>
      </>
      :
      <>
      <b>Booking ID:</b> {bookingState.booking.tranId}<br/>
       
      <b>Booking created on:</b> {(new Date(bookingState.booking.timestamp)).toLocaleDateString('en-UK')}<br/>
     
      <b>Booking created by:</b> {bookingState.booking.itemStatus==1?"guest (" + bookingState.booking.email + ")":bookingState.booking.itemStatus==2?"you":""}<br/>
     {bookingState.booking.pitch &&
     <>
      <b>Bay:</b> {bookingState.booking.pitch.name + " (" + (bookingState.booking.pitch.maxlength/10.0) + "meters"} {bookingState.booking.pitch.electric?", EHU":""} {bookingState.booking.pitch.surface==0?"":bookingState.booking.pitch.surface==1?", grass surface":bookingState.booking.pitch.surface==2?", gravel surface":bookingState.booking.pitch.surface==3?", hard surface":""})<br/>
      </>
     }
      <b>Duration:</b> {(bookingState.booking.dates.split(",")).length + ((bookingState.booking.dates.split(",")).length>1?" nights":" night")}<br/>
      
      <b>Dates:</b> {(new Date(bookingState.booking.dates.split(",")[0])).toLocaleDateString('en-UK') + ((bookingState.booking.dates.split(",")).length>1?" - " + (new Date(bookingState.booking.dates.split(",")[(bookingState.booking.dates.split(",")).length-1])).toLocaleDateString('en-UK'):"")}<br/>
     </>
     }


      {/*{e=> handleCancelBooking(e,bookingState.booking)}*/}

      {!bookingState.card.inPast && !confirmCancellation &&
    
      <Box  sx={{
        display: 'flex',
        alignItems: 'center',
        p: 0,
        m: 2,
        
      }}>
      <LoaderButtonX 
      type="button"
      disabled={isPaying}
      isLoading={false}
      size="small"
      onClick={e=> {setConfirmCancellation(true)}}
      >
      Cancel and Refund
      </LoaderButtonX>
      </Box>
      
      }
      {confirmCancellation && 
      <Box  sx={{
        display: 'flex',
        alignItems: 'center',
        color: 'red',
        p: 0,
        m: 2,
        
      }}>Are you sure you wish to cancel this booking? This cannot be undone.</Box>
      }

      </>
      
      : bookingState.dialogType!="none"?
      <>
      {/*bookingState.card.bay && JSON.stringify(bookingState.card.bay)*/}

      {bookingState.current=="dateselection"?
          <DateSelection bookingData={bookingData} setBookingData={setBookingData} bookingState={bookingState} setBookingState={setBookingState} content={content} bookings={getBookingsForDateSelector()} setPayType={null} pitchfeatures={pitchfeatures} setPitchfeatures={setPitchfeatures}/>
          :bookingState.current=="calendarview"?
          <CalendarView bookingData={bookingData} setBookingData={setBookingData} bookingState={bookingState} setBookingState={setBookingState} content={content} bookings={getBookingsForDateSelector()} setPayType={null} pitchfeatures={pitchfeatures} setPitchfeatures={setPitchfeatures}/>
          :bookingState.current=="userdetails"?
          <HostBookingDetails bookingData={bookingData} setBookingData={setBookingData} bookingState={bookingState} setBookingState={setBookingState} content={content} bookings={getBookingsForDateSelector()} setPayType={null} pitchfeatures={pitchfeatures} setPitchfeatures={setPitchfeatures} handleStripePayClick={handleStripePayClick}/>
          :
          <></>
      }
      </>
      :<></>
      }
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {confirmCancellation?
      <>
      <Button disabled={isPaying} onClick={e=> handleCancelBooking(e,bookingState.booking)} color="primary" >
      YES
      </Button>
      <Button disabled={isPaying} onClick={handlePaymentClose} color="primary" >
      NO
      </Button>
      </>
      :
      <Button disabled={isPaying} onClick={handlePaymentClose} color="primary" >
      {bookingState.dialogType=="viewer"?"Done":"Cancel"}
      </Button>
}
    </DialogActions>
  </Dialog>



    <div className="daily-trans">      
      <div id="carousel" className="dailytrans-carousel">
        {generateDateCards().map((card, index) => (
          <div key={"dailytrans-card"+index} className="dailytrans-card">
            {card.dayName!="" ? <div className="dailytrans-day"><b>{card.dayName}</b></div> : <div className="dailytrans-day"><b>{card.date.toLocaleDateString('en-UK', { weekday: 'long' })}</b></div>}
            <div className="dailytrans-day">{card.date.toLocaleDateString('en-UK', {day: 'numeric' })+nthNumber(card.date.getDate())+" "+card.date.toLocaleDateString('en-UK', {month: 'long'})+(card.date.getFullYear()!=today.getFullYear()?(" " + card.date.toLocaleDateString('en-UK', {year: 'numeric'})):"")}</div>           
            <div className="dailytrans-month"><img src={iTopOvernight} width='auto' height='28' /></div>
            <div className="dailytrans-pitches">{card.bayCount + " overnight pitch" + (card.bayCount==1?"":"es") + " booked"}</div>
            {card.dayBookings ? card.dayBookings.overnight.map((bay, idx2) => (
              <div key={"pitch-card" + idx2} className={`pitch-card ${bay.highlight?'dragover':''}`}>{content.bayList && <div><b>{content.bayList[idx2].name}</b> {content.bayList[idx2].maxlength>0?(content.bayList[idx2].maxlength/10.0)+"m":""} {content.bayList[idx2].electric?"electric":""} {content.bayList[idx2].surface==0?"":content.bayList[idx2].surface==1?"grass":content.bayList[idx2].surface==2?"gravel":content.bayList[idx2].surface==2?"hard":""}</div>}
                <div className="pitch-number-booking"> 
                  <div className="pitch-number">{idx2+1}</div>
                  {/*<div className={`booking-card ${card.inPast?(content.bayList?'previous':'previous-nullbay'):bay.booking?'':(content.bayList?'empty':'empty-nullbay')}`} onClick={e=> handleAddBooking(card, idx2)} onDrop={(e)=> handleDragDrop(e, card, idx2)} onDragStart={(e)=> handleDragStart(e, card, idx2)} onDragLeave={(e)=> handleDragLeave(e, card, idx2)}  onDragOver={(e)=> handleDragOver(e, card, idx2)} onDragEnter={(e)=> handleDragEnter(e, card, idx2)} draggable={!card.inPast && bay.booking?true:false} key={"emp"+idx2}>{bay.booking?bay.booking.reg:card.inPast?<>&nbsp;</>:content.bayList?"+ Add booking":<>&nbsp;</>}</div>*/}
                  <div className={`booking-card ${card.inPast?(content.bayList?'previous':'previous-nullbay'):bay.booking?'':(content.bayList?'empty':'empty-nullbay')}`} onClick={e=> handleAddBooking(card, idx2)} key={"emp"+idx2}>{bay.booking?bay.booking.reg:card.inPast?<>&nbsp;</>:content.bayList?"+ Add booking":<>&nbsp;</>}</div>
                </div>
              </div>))
            : emptyBookingsArr.map((obj, idx) => (
                <div key={"pitch-card" + idx} className="pitch-card">
                  <div className="pitch-number-booking"> 
                    <div className="pitch-number">{idx+1}</div>
                    <div className={`booking-card ${card.inPast?'previous-nullbay':'empty-nullbay'}`} key={"emp"+idx} onClick={e=> handleAddBooking(card, idx)}>{card.inPast?<>&nbsp;</>:!content.bayList && idx==0?"+ Add booking":<>&nbsp;</>}</div>
                  </div>
                </div>          
            ))}
            {/* --------FACILITIES--------*/}
            <div className="dailytrans-month"><img src={iTopFacilities} width='auto' height='28' /></div>
            <div className="dailytrans-pitches">{card.dayBookings?card.dayBookings.facilities.length + " facility use payments":"0 facility use payments"}</div>
            <Box pb={1}>
              <LoaderButtonX
                  type="button"
                  isLoading={false}
                  size="small"
                  onClick={e=> handleAddFacilitiesPayment(card, index)}
                  >
                  + Add payment
              </LoaderButtonX>
            </Box>
            {card.dayBookings && card.dayBookings.facilities.map((booking, index) => (   
                <div key={"detail-card-facilitiesuse" + index} className="detail-card facilitiesuse" onClick={e=> handleAddFacilitiesPayment(card, index)} ><div className="detail-inner facilitiesuse"></div>&nbsp;&nbsp;{booking.reg}</div>
            ))}        
          </div>
        ))}
      </div>
    </div>
    </>
  );
};
export default DailyTransactions;
        {/*}<div className={`pitch-card ${card.inPast?'previous':'empty'}`} key={"emp"+idx}><div className="booking-card">{idx+1}</div></div>
        <div className="parent-div"> <div className="detail-inner">{idx+1}</div><div className={`detail-card ${card.inPast?'previous':'empty'}`} key={"emp"+idx}>aaaaaa</div></div>
         className={`detail-card ${card.inPast?'previous':bay.booking?'':'empty'}`}><div className="detail-inner">{String(idx2+1)}</div>&nbsp;&nbsp;{bay.booking && bay.booking.reg}</div>

      disabled={!canShowSites}
      onClick={!isLoading ? handleVisitorBookClick : null}



         <div 
                
                
                draggable={!card.inPast}
                    
                    onDragStart={(e) => handleDragStart(e)}
                
                className={`detail-card ${card.inPast?'previous':bay.booking?'':'empty'}`}><div className="detail-inner">{String(idx2+1)}</div>&nbsp;&nbsp;{bay.booking && bay.booking.reg}</div>
        */}

