import React, { useState, useEffect } from "react";
import { useHistory} from "react-router-dom";
import { onError } from "../libs/errorLib";

import { API } from "aws-amplify";
import "./Sites.css";
//import LoaderButton2 from "../components/LoaderButton2";
//import LoaderButton3 from "../components/LoaderButton3";

import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import { BsArrowRepeat } from "react-icons/bs";


import Table from "react-bootstrap/Table";

import { makeStyles, withStyles, styled } from '@material-ui/core/styles';


import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Button from '@material-ui/core/Button';
//import Button from "../components/LoaderButtonX";
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';

import ButtonBase from '@material-ui/core/ButtonBase';


import Fab from '@material-ui/core/Fab';


import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

//import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
//import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
//import Brightness3Icon from '@material-ui/icons/Brightness3';
//import InvertColorsIcon from '@material-ui/icons/InvertColors';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

//import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';

import iTopOvernightOff from'../images/preview/Pay Per Night - Grey.svg';
import iTopBaysOff from'../images/preview/Parking - Grey.svg';
import iTopFacilitiesOff from'../images/preview/Facilities Only - Grey.svg';

import iTopOvernight from'../images/preview/Pay Per Night - Blue.svg';
import iTopBays from'../images/preview/Parking - Blue.svg';
import iTopFacilities from'../images/preview/Facilities Only - Blue.svg';


import iRecyclingOff from'../images/facilities/Recycling - Grey.svg';
import iBlackWaterOff from'../images/facilities/Black Water Disposal - Grey.svg';
import iElectricOff from'../images/facilities/Electric Hook Up - Grey.svg';
import iGreyWaterOff from'../images/facilities/Grey Water Disposal - Grey.svg';
import iLaundryOff from'../images/facilities/Laundry - Grey.svg';
import iOvernightOff from'../images/facilities/Overnight Parking - Grey.svg';
import iRubbishOff from'../images/facilities/Rubbish - Grey.svg';
import iShowerOff from'../images/facilities/Shower - Grey.svg';
import iToiletOff from'../images/facilities/Toilet - Grey.svg';
import iWaterOff from'../images/facilities/Water - Grey.svg';
import iWifiOff from'../images/facilities/Wifi - Grey.svg';

import iRecycling from'../images/facilities/Recycling - Coral.svg';
import iBlackWater from'../images/facilities/Black Water Disposal - Coral.svg';
import iElectric from'../images/facilities/Electric Hook Up - Coral.svg';
import iGreyWater from'../images/facilities/Grey Water Disposal - Coral.svg';
import iLaundry from'../images/facilities/Laundry - Coral.svg';
import iOvernight from'../images/facilities/Overnight Parking - Coral.svg';
import iRubbish from'../images/facilities/Rubbish - Coral.svg';
import iShower from'../images/facilities/Shower - Coral.svg';
import iToilet from'../images/facilities/Toilet - Coral.svg';
import iWater from'../images/facilities/Water - Coral.svg';
import iWifi from'../images/facilities/Wifi - Coral.svg';



import iCampra from'../images/campra/Campra Accredited - Green.svg';

import defaultSiteImage from'../images/Default-Image-Road.jpg';
import spinner from'../images/Driving-SVG.gif';


import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';


import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import Container from '@material-ui/core/Container';


import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';


import Form from "react-bootstrap/Form";

import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline';

import parse from 'html-react-parser';


import jsPDF from 'jspdf';

import iLogo from "../images/logo.jpg";
import iQRLogo from "../images/qrcode_logo.svg";

import iCampraA from'../images/campra/Campra Accredited - Green.svg';
import iCampraX from'../images/campra/Campra Accredited - Grey.svg';


import QRCode from 'qrcode';
import { createCanvas, loadImage } from "node-canvas";

import '../fonts/Montserrat-Regular-normal.js';


//import Calendar from 'react-calendar';
//import 'react-calendar/dist/Calendar.css';

//import CalendarPicker from 'react-native-calendar-picker';

//import DatePicker from "react-multi-date-picker";
import { Calendar, DateObject } from "react-multi-date-picker"
import "react-multi-date-picker/styles/colors/xxx.css"
import "react-multi-date-picker/styles/colors/red.css"
import colors from "react-multi-date-picker/plugins/colors"

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Collapse from '@material-ui/core/Collapse';


//import CheckboxLabels from "../components/CheckboxLabels";
const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});
const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },

  },
})(TextField);

const BlueTooltip = withStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #10385A"
    },
    color: '#10385Acc'
  },
  tooltip: {
    backgroundColor: '#10385Add',
    color: '#ffffff',
    border: "1px solid #10385A",
    fontSize: 12,
    fontFamily: "Montserrat",
    fontWeight: 400,

  },
}))(Tooltip);


const useStyles = makeStyles((theme) => ({


  root: {
    '& > *': {
      margin: theme.spacing(1),
      background: "#000",
    },
  },
  imageList: {
    width: 500,
    height: 48,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  card: {
    margin: theme.spacing(2),
  },
  h4: {
    fontFamily: 'Comfortaa',
    color: '#10385A4'
  },
  h6: {
    color: '#F18C7E'
  },

  dialogContent: {
    width: '100%',
    backgroundColor: '#10385A',
    paddingTop: '80px',
    height:"100vh",
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  divcontrol: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  label: {
    display: "flex"
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    color: 'white'
  },
  pdf: {cursor:'pointer', marginTop: '-0px', marginRight: '-8px', color:'#10385A4'},

  link: {
      color: "#F18C7E",
      "&:hover": {
          color: "#F18C7E",
          textDecoration: "underline #F18C7E"
      }
  },
  spreadBox: {
    justifyContent: "space-between"
  },

  textField: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "& .MuiOutlinedInput-input": {
      color: "red"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "red"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "red"
    },
    "& .MuiInputLabel-outlined": {
      color: "red"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "red"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "red"
    },


  }



}







));

const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  // Return the width so we can use it in our components
  return { width };
}


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Sites() {
  let classes = useStyles();

  const history = useHistory();
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    reg: "",
  });
  const [tncs, setTncs] = useState(false);

  const { isCampra } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isSwitching, setIsSwitching] = useState(false);
  const [switchingId, setSwitchingId] = useState(null);

  const [open, setOpen] = React.useState([]);
  const [paymentResult, setPaymentResult] = React.useState(false);
  const [sessionValues, setSessionValues] = React.useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [alertVals, handleAlertChange] = useFormFields({
    title: "",
    message: "",
  });



  const [currentSite, setCurrentSite] = useState(null);
  const [content, setContent] = useState("");
  const [siteOpen, setSiteOpen] = useState(false);
  //const [base64Image, setBase64Image] = useState("");
  //const [siteCode, setSiteCode] = useState("");
  //const [desc, setDesc] = useState("");
  //const [rules, setRules] = useState("");
  const [address, setAddress] = useState("");
  //const [accreditations, setAccreditations] = useState([]);


  const [sites, setSites] = useState([]);



  const [calendarVal, onCalendarVal] = useState([
  //new DateObject().setDay(5),
  ///new DateObject().setDay(12).color="red",
  //new DateObject().setDay(14).add(1, "month"),
  //new DateObject().setDay(23).add(1, "month"),
]);
//const [maxBookAhead, setMaxBookAhead] = useState(0);
//const [maxNights, setMaxNights] = useState(0);

const [closedDatesChanged, setClosedDatesChanged] = React.useState([]);
const [calendarExpanded, setCalendarExpanded] = React.useState([]);

const handleExpandCalendarClick = (idx) => {
    const curr = calendarExpanded[idx];
    let newArr = new Array(calendarExpanded.length).fill(false);
    newArr[idx] = !curr;
    setCalendarExpanded(newArr);
  };


  const [forceUpdate, setForceUpdate] = React.useState(false);




  const { width } = useViewport();
  const breakpoint = 600;

  useEffect(() => {

    onLoad();
  }, []);
  

/*
function getDayRange() {
  var d = new Date();
  d.setHours(0,0,0,0);
  var t1 = d.getTime();
  return {start: t1, end: t1 + 86400000};
}
*/

function getOpenStatus(status, dates) {
  if (status<2) {
    return status===1;
  } else if (status==2) {
    if (!dates) {
      return true;
    }
    const d = new Date();
    d.setHours(12,0,0,0);
    const dt = d.getTime();
    var found = dates.find(function(element) {
      //console.log(element == dt);
      return element == dt;
    });
    //console.log(dt);
    //console.log(dates);
    //console.log(found!==dt);
    return found!==dt;
  } else {
    return false;
  }
}


function getTransactions() {
  return API.post("aires", `/tran`, {
    body: {}
  });
}

function getTransactionsByAdminForSite(siteId) {
  return API.post("aires", `/tranadmin`, {
    body: {siteId:siteId}
  });
}


function getSites() {
  return API.get("aires", `/sites`);
}
function getSitesByAdmin() {
  return API.get("aires", `/sitesadmin`);
}

  async function onLoad() {
    if (isCampra) {history.push("/")}
    setIsLoading(true);
    try {




      const result = await getSites();
      //const result = await getSitesByAdmin();

//console.log(result.data);
      

      result.data.sort((a,b)=>{
        return a.content.siteName.localeCompare(b.content.siteName);
      })
        
      const sitesArr = result.data.filter((site) => site.itemStatus != 8);


      if (result.status && sitesArr) {
        var calendarObjects = [];
        const openArray = new Array(sitesArr.length).fill(false);
        sitesArr.forEach((site, index) => {
          site["trans"] = {"overnight": []};
          site.trans["overnightNextDay"] = [];
          site.trans["facilities"] = [];
          var dateObjects = [];
          var maxBookAhead=-1;
          var maxNights=0;
          if (site.content.closedDates) {
            site.content.closedDates.forEach(ts => {
              const dobj = new DateObject({
              date: ts
              })
              dateObjects.push(dobj);
            });
            //console.log(dateObjects);
          }
          //console.log("||||||||||||||||||||||||||||||||||");
          //console.log(site.content.maxBookAhead, maxBookAhead);
          //console.log(site.content.maxBookAhead && site.content.maxBookAhead != -1);
          //if (site.content.maxBookAhead && site.content.maxBookAhead != -1) {
            //console.log("mmm");
            maxBookAhead = site.content.maxBookAhead;
          //}
          //console.log(site.content.maxBookAhead, maxBookAhead);

          if (site.content.maxNights && site.content.maxNights > 0) {
            maxNights = site.content.maxNights;
          }
          calendarObjects.push({dates:dateObjects, maxBookAhead: maxBookAhead, maxNights: maxNights});
          openArray[index] = getOpenStatus(site.itemStatus, site.content.closedDates);
        });
        setOpen(openArray);
        onCalendarVal(calendarObjects);
        

        sitesArr.push({add: true});
        setSites(sitesArr);
        const expandArray = new Array(sitesArr.length).fill(false);
        setCalendarExpanded(expandArray);
        setClosedDatesChanged(expandArray);
        //console.log(result);
        if (sitesArr.length>1) {



        const trans = await getTransactions();
        //const trans = await getTransactionsByAdminForSite('V10407');
        //console.log(trans);

          if (trans.status && trans.data && trans.data.length>0)  {
            const today = new Date();
            today.setHours(12,0,0,0);
            const t1 = today.getTime();
            const t2 = t1 + (24*60*60*1000);
            const twoHours = 2*60*60*1000;
            trans.data.forEach(tran => {
              const code = tran.GSI1SK.split("-")[0];
              //console.log(code);
              var site = sitesArr.find(obj => {
                return obj.itemCode === code;
              })
              //console.log(site);
              if (tran.content.metadata.payType==="overnight") {
                const whenDate = parseInt(tran.content.metadata.whenDate);
                //console.log("overnight");
                //console.log(Math.abs(t1 - whenDate)<twoHours);
                //console.log(Math.abs(t2 - whenDate)<twoHours);
                //if (t1 == whenDate) {
                if (Math.abs(t1 - whenDate)<twoHours) {
                  site.trans.overnight.push(tran.vreg);
                  //console.log(1,tran.vreg);
                //} else if (t2 == whenDate) {
                } else if (Math.abs(t2 - whenDate)<twoHours) {
                  site.trans.overnightNextDay.push(tran.vreg);
                  //console.log(2,tran.vreg);
                }
              } if (tran.content.metadata.payType==="facilities") {
                site.trans.facilities.push(tran.vreg);
                //console.log(3,tran.vreg);
              }
            });
          }
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
        alertVals.title="AireStop - Oops!";
        alertVals.message="Unable to retrieve sites, please check your internet connection and try later.";
        setAlertOpen(true);
      }
    } catch (e) {
      onError(e);
      history.push("/");
    }
  }


  const OpenClosedSwitch = withStyles({
    switchBase: {
      color: red[700],
      '&$checked': {
        color: green[500],
      },
      '&$checked + $track': {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);


  function handleAlertClose() {
    setAlertOpen(false);
    setDeleteAlertOpen(false);
  };


function handleNewSiteClick() {
  history.push("/sites/create/new");
}

function handleEditSiteClick(site) {
  history.push("/sites/" + site.itemCode + "/edit");
}



/*

{site.trans?
<Box pb={1}>
<Link component="button" disabled={site.trans.overnight.length==0} onClick={() => { handleOvernightTransClick(site) }}>Overnight: {site.trans.overnight.length}</Link> &nbsp;&nbsp;&nbsp;&nbsp;
<Link component="button" disabled={site.trans.facilities.length==0} onClick={() => { handleFacilitiesTransClick(site) }}>Facilities: {site.trans.facilities.length}</Link>

  <Divider width="100%"/>
</Box>
:<></>}


*/



function handleTransactionsClick(site) {
  alertVals.title=<>Todays Bookings<br/><span style={{fontSize:"14px"}}>{site.content.siteName}</span></>;
  alertVals.message=<><b>Overnight Stays</b> <br/>Today ({site.trans.overnight.length})<br/>
  {site.trans.overnight.length>0?

  <>Vehicle registration{site.trans.overnight.length>1?"s":""}:<br/>{
     site.trans.overnight.map((reg, index) => {
      return (
        <><i>{reg.toUpperCase()}</i><br/></>
      )
    })

  }
  </>
  :
  <></>

  }
  <br/>
  Tomorrow ({site.trans.overnightNextDay.length})<br/>
  {site.trans.overnightNextDay.length>0?

  <>Vehicle registration{site.trans.overnightNextDay.length>1?"s":""}:<br/>{
     site.trans.overnightNextDay.map((reg, index) => {
      return (
        <><i>{reg.toUpperCase()}</i><br/></>
      )
    })

  }
  </>
  :
  <></>

  }
  <br/>
  <b>Facilities Use</b> ({site.trans.facilities.length})<br/>

  {site.trans.facilities.length>0?

  <>Vehicle registration{site.trans.facilities.length>1?"s":""}:<br/>{
     site.trans.facilities.map((reg, index) => {
      return (
        <><i>{reg.toUpperCase()}</i><br/></>
      )
    })
  }
  </>
  :
  <></>


  }
  </>;
  setAlertOpen(true);
}



function handlePrintSiteClick(site) {
  handlePrintPDF(site);
}
function handleOrderSiteClick(site) {
  history.push("/shop");
}
function handleDeleteSiteClick(site) {
  alertVals.title="AireStop - Confirm Delete";
  alertVals.message="Are you sure you wish to delete this AireStop. This cannot be undone and your customers will lose access to this site.";
  setCurrentSite(site);
  setDeleteAlertOpen(true);
}

async function handleSiteDelete(e) {
  e.preventDefault();
  setIsLoading(true);
  setDeleteAlertOpen(false);
  const newStatus = 9; //DELETED
  const result = await updateSiteStatus(currentSite.itemCode, newStatus);
  if (result.status) {
    setCurrentSite(null);
  }
  onLoad(); //refresh sites list
}

function onCalendarValChange(idx, e) {
  //console.log(e);
  //console.log(idx);
  calendarVal[idx] = {dates:e,maxBookAhead:calendarVal[idx].maxBookAhead, maxNights:calendarVal[idx].maxNights};


  onCalendarVal(calendarVal);
  determineClosedDatesChanged(idx);
}

function determineClosedDatesChanged(idx) {
  var oldDates = sites[idx].content.closedDates;
  var dates = [];
  calendarVal[idx].dates.forEach(dateObj => {
    const ts = dateObj.valueOf();
    const dt = new Date(ts);
    dt.setHours(12,0,0,0);
    dates.push(dt.getTime());
  });
  dates.sort();
  const aaa = dates.toString();
  var bbb = "";
  if (oldDates) {
    oldDates.sort();
    bbb = oldDates.toString();
  }
  //console.log(aaa);
  //console.log(bbb);
  closedDatesChanged[idx] = aaa !== bbb || sites[idx].content.maxBookAhead !== calendarVal[idx].maxBookAhead || sites[idx].content.maxNights !== calendarVal[idx].maxNights;
  setClosedDatesChanged(closedDatesChanged);
  setForceUpdate(!forceUpdate);
  //console.log(closedDatesChanged);
}


async function handleSaveClosedDatesClick(idx, site, e) {
  e.preventDefault();
  setSwitchingId("switching-id-" + idx);
  setIsSwitching(true);
  setForceUpdate(!forceUpdate);
  var dates = [];
  calendarVal[idx].dates.forEach(dateObj => {
    const ts = dateObj.valueOf();
    const dt = new Date(ts);
    dt.setHours(12,0,0,0);
    dates.push(dt.getTime());
  });
  const result = await updateSiteClosedDates(site.itemCode, dates, calendarVal[idx].maxBookAhead, calendarVal[idx].maxNights);
  if (result.status) {
    site.content["closedDates"]=result.result.Attributes.content.closedDates;
    site.content["maxBookAhead"]=result.result.Attributes.content.maxBookAhead
    site.content["maxNights"]=result.result.Attributes.content.maxNights
    open[idx] = getOpenStatus(site.itemStatus, site.content.closedDates);
  }
  determineClosedDatesChanged(idx);
  setIsSwitching(false);
  setSwitchingId(null);
}

 const handleStatusChange = async (event, idx) => {
   //console.log(event);
  event.preventDefault();
  setSwitchingId("switching-id-" + idx);
  setIsSwitching(true);
  const newStatus = event.target.value;
  const result = await updateSiteStatus(sites[idx].itemCode, newStatus);
  if (result.status) {
    sites[idx].itemStatus=result.result.Attributes.itemStatus;
  }
  open[idx] = getOpenStatus(sites[idx].itemStatus, sites[idx].content.closedDates);

  setOpen(open);
  setIsSwitching(false);
  setSwitchingId(null);
};


const handleMaxBookAheadChange = async (event, idx) => {
  //console.log(event);
 event.preventDefault();
 const newVal = event.target.value;
 calendarVal[idx] = {dates:calendarVal[idx].dates,maxBookAhead:newVal, maxNights:calendarVal[idx].maxNights};
 onCalendarVal(calendarVal);
 determineClosedDatesChanged(idx);
};

const handleMaxNightsChange = async (event, idx) => {
  //console.log(event);
 event.preventDefault();
 const newVal = event.target.value;
 calendarVal[idx] = {dates:calendarVal[idx].dates,maxBookAhead:calendarVal[idx].maxBookAhead, maxNights:newVal};
 onCalendarVal(calendarVal);
 determineClosedDatesChanged(idx);
};


/*
const handleSiteOpenChange = async (event) => {
  event.preventDefault();
  setSwitchingId(event.target.id);
  setIsSwitching(true);
  const newStatus = event.target.checked?1:0;
  const result = await updateSiteStatus(sites[event.target.id].itemCode, newStatus);
  if (result.status) {
    sites[event.target.id].itemStatus=result.result.Attributes.itemStatus;
    setSiteOpen(!siteOpen);
  }
  setIsSwitching(false);
  setSwitchingId(null);
}
*/

function updateSiteStatus(id, status) {
  return API.put("aires", `/sites/${id}`, {body: {
    type: 'STATUS',
    status: status
  }});
}
function updateSiteClosedDates(id, dates, maxBookAhead, maxNights) {
  return API.put("aires", `/sites/${id}`, {body: {
    type: 'CLOSED_DATES',
    dates: dates,
    maxBookAhead: maxBookAhead,
    maxNights: maxNights
  }});
}

async function handlePrintPDF(site) {
  const datestr = (new Date(Date.now())).toLocaleString();
  const fileName = "AireStop_" + site.itemCode + "_" + site.content.siteName.replace(/\s/g, '_') + "_" + datestr + ".pdf";
  var doc = new jsPDF('l', 'px', 'a4');


  //doc.setFont('AvenirNextLTPro-Regular'); // set custom font
  doc.setFont('Montserrat-Regular'); // set custom font
  doc.setTextColor(16, 56, 90);
  doc.setCharSpace(6);

  const canvas = createCanvas(300, 300);
  const data = 'https://airestop.co.uk/sites/' + site.itemCode;
  const img = await loadImage(iQRLogo);

  var iii = new Image();
  iii.onload = function(){
      let zzz = doc.getImageProperties(iii);
  };

  QRCode.toCanvas(canvas, data,  {
      errorCorrectionLevel: "H",
      margin: 0,
      color: {
        dark: "#10385A",
        light: "#ffffff",
      },
      width: 300
    }
, function (error) {
      if (error) {
        console.error(error)
      } else {
      const ctx = canvas.getContext("2d");
      const w = 50*2;
      const h = 42.801556*2;
      const ctrex = (300-w) / 2;
      const ctrey = (300-h) / 2;
      ctx.drawImage(img, ctrex, ctrey, w, h);
      const b64qrcode = canvas.toDataURL("image/png");


      iii.src = b64qrcode;


      doc.setProperties({
      	title: 'AireStop Notice',
      	subject: site.content.siteName + " (" + site.itemCode + ")",
      	author: 'AireStop Limited',
      	keywords: '',
      	creator: 'AireStop Limited'
      });

      let pr = doc.getImageProperties(iLogo);
      const rat = pr.height/pr.width;
      doc.setFontSize(70);
      let tr = doc.getTextWidth(site.content.siteName);
      let docw = doc.internal.pageSize.width;
      let doch = doc.internal.pageSize.height;
      let margin = 60;
      let ypos = 30;
      let boxY = 0;
      doc.setFillColor(255,255,255);
      doc.rect(0, 0, docw, doch, 'F');

      doc.addImage(iLogo, "JPG", margin, ypos+10, docw-(margin*2),((docw-(ypos*2))*rat)-15);
      ypos = ypos + (docw-(ypos*2))*rat + 15;
      doc.setDrawColor(16, 56, 90);
      doc.setLineWidth(2);
      doc.setFillColor(0);
      doc.rect((margin/2), ypos, docw-(margin), 200, 'D');
      boxY = ypos;
      doc.addImage(b64qrcode, "PNG", docw-(margin/2)-185, ypos+16);
      doc.setFontSize(70);
      ypos += (16 + 40);
      doc.text("PAY HERE", (margin/2) + 12, ypos);
      doc.setFontSize(22);
      ypos += (10 + 16);
      doc.text("WWW.AIRESTOP.CO.UK", (margin/2) + 16, ypos);
      doc.setFontSize(30);
      doc.text("SITE CODE", (margin/2) + 16, boxY+200-16-50);
      doc.setFontSize(70);
      doc.text(site.itemCode, (margin/2) + 16, boxY+200-16);

      doc.setFontSize(30);


      tr = doc.getTextWidth(site.content.siteName.toUpperCase());
      doc.text(site.content.siteName.toUpperCase(), ((docw-tr)/2)-6, doch-75);
      doc.setLineWidth(1);
      doc.setFontSize(22);
      tr = doc.getTextWidth("ENJOY YOUR VISIT - LEAVE NO TRACE");
      doc.text("ENJOY YOUR VISIT - LEAVE NO TRACE", ((docw-tr)/2)-22, doch-40);
      doc.line(((docw-tr)/2)-22, doch-63, ((docw-tr)/2) + tr + 22, doch-63);
      doc.save(fileName);
}


   })


}




function renderAdd() {
  return (<>  <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"

    >

        <Button variant="contained" color="secondary"  onClick={() => { handleNewSiteClick() }}>
          Add new AireStop
        </Button>

        </Box>
</>);
}

function renderSite(site, index) {
  return (<><div>




  <Box display="flex" flexWrap="wrap"  alignItems='top' className={classes.spreadBox}>
    <Box display="flex" width="100%" flexWrap="wrap" justifyContent="top" alignItems='center' className={classes.spreadBox} >
      <Box display="flex" flexWrap="wrap" mb={0} className={classes.spreadBox} flexDirection="column" justifyContent="center" alignItems='center' mx={0} alignItems='top'>
        <span className="text-muted">{site.itemCode}</span>
        <h4 className={classes.h4}><span>{site.content.siteName}</span></h4>
      </Box>
      <Box display="flex" flexWrap="wrap" mb={0} className={classes.spreadBox} flexDirection="row" justifyContent="center" alignItems='center' mx={0} >
{/*
      <FormControlLabel
      style={{marginRight:"4px"}}
      control={
        <>
        {isSwitching && switchingId && switchingId===index?
        <BsArrowRepeat className="spinning" />
        :<></>}
        <OpenClosedSwitch id={index}
        checked={site.itemStatus==1}
        onChange={handleSiteOpenChange}

        name="siteOpen"
        color="secondary"
        />
        </>
      }
      label={ site.itemStatus==1?"Open":"Closed"}
      />
*/}
{isSwitching && switchingId && switchingId==="switching-id-" + index?
<BsArrowRepeat className="spinning" />
:<></>}
{/*}
&nbsp;&nbsp;
<FormControl variant="outlined" size="small">
<InputLabel className={classes.label} id="select-status-label">
   Status:
 </InputLabel>
 <Select
labelId="select-status-label2"
id={index}
value={site.itemStatus}
label="Status"
onChange={(e) => { handleStatusChange(e, index) }}
>
<MenuItem value={0}>Closed</MenuItem>
<MenuItem value={1}>Open</MenuItem>
<MenuItem value={2}>Calendar</MenuItem>
</Select>



</FormControl>
&nbsp;&nbsp;&nbsp;&nbsp;
*/}

      Manage availability &nbsp;<DateRangeIcon />
      <ExpandMore
        color="primary"
        expand={calendarExpanded[index]}
        onClick={() => { handleExpandCalendarClick(index) }}
        aria-expanded={calendarExpanded[index]}
        aria-label="show calendar"
        size="small"
        id={index}>
        <ExpandMoreIcon />
      </ExpandMore>
      </Box>
    </Box>


  </Box>
  <Collapse in={calendarExpanded[index]} timeout="auto" unmountOnExit>
  <Box my={1} >
  <Paper variant="outlined" >
  <Box p={1} >
<h6 className={classes.h6}><span>Select closed dates:</span></h6>
<Box mb={2} pl={5} pr={5} display="flex" width="100%"  justifyContent="center" alignItems='center' >Note: A selected day runs from noon on the selected day to noon of the following day.</Box>
  <Box mb={2} display="flex" width="100%"  justifyContent="center" alignItems='center' >
  
      <Calendar
        className="xxx"
        numberOfMonths={2}
        currentDate={new DateObject()}
        minDate={new Date()}
        value={calendarVal[index].dates}
        multiple
        onChange={(e) => { onCalendarValChange(index, e) }}/>
  </Box>


  <Box   display="flex" width="100%" flexDirection="row" alignItems="center" my={3}>
  <Grid container alignItems='center' spacing={1} >
  <Grid key={1} item>
  <Box mt={1}><h6 className={classes.h6}><span>Maximum book ahead:</span></h6></Box>
  </Grid>
  <Grid key={2}  width="100%" item>
  <FormControl variant="outlined" size="small">
  <InputLabel className={classes.label} id="select-status-label">
   Period:
 </InputLabel>

 <Select
labelId="select-status-label2"
id={index}
value={calendarVal[index].maxBookAhead}
label="Period"
onChange={(e) => { handleMaxBookAheadChange(e, index) }}
>
<MenuItem value={-2}>closed for bookings</MenuItem>
<MenuItem value={-1}>unlimited</MenuItem>
<MenuItem value={0}>current day only</MenuItem>
<MenuItem value={1}>1 day</MenuItem>
<MenuItem value={2}>2 days</MenuItem>
<MenuItem value={7}>1 week</MenuItem>
<MenuItem value={30}>1 month</MenuItem>
<MenuItem value={92}>3 months</MenuItem>
<MenuItem value={182}>6 months</MenuItem>
<MenuItem value={365}>1 year</MenuItem>
<MenuItem value={730}>2 years</MenuItem>
</Select>



</FormControl>  
</Grid>
  </Grid>
   
  
  



  </Box>

  <Box   display="flex" width="100%" flexDirection="row" alignItems="center" my={3}>
  <Grid container alignItems='center' spacing={1} >
  <Grid key={1} item>
  <Box mt={1}><h6 className={classes.h6}><span>Maximum nights booking:</span></h6></Box>
  </Grid>
  <Grid key={2} item>
  <FormControl variant="outlined" size="small">
  <InputLabel className={classes.label} id="select-status-label">
   Period:
 </InputLabel>

 <Select
labelId="select-status-label2"
id={index}
value={calendarVal[index].maxNights}
label="Period"
onChange={(e) => { handleMaxNightsChange(e, index) }}
>
<MenuItem value={0}>unlimited</MenuItem>
<MenuItem value={1}>1 night</MenuItem>
<MenuItem value={2}>2 nights</MenuItem>
<MenuItem value={7}>1 week</MenuItem>
<MenuItem value={30}>1 month</MenuItem>
</Select>



</FormControl>  
</Grid>
  </Grid>
   
  
  



  </Box>

  <Box   display="flex" width="100%"  justifyContent="right" alignItems='right'>
  <Fab disabled={!closedDatesChanged[index]} size="small" color="secondary" aria-label="add" variant="extended" onClick={(e) => { handleSaveClosedDatesClick(index, site, e) }}>
  <span class="material-icons-round" style={{ color: "#fff"}}>
    save
  </span>
  <span style={{ color: "#fff"}}>
  &nbsp;Save Calendar Rules
  </span>
  </Fab>

  </Box>

  </Box>
  </Paper>
  </Box>
</Collapse>


  <Paper variant="outlined">
  <Grid container justifyContent="center" alignItems='center' spacing={0}>
    {/*}
  <Grid key={1} item>
    <BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"AireStop is "+(open[index]?"Open":"Closed")}><Box className="noselect" display="flex" flexWrap="wrap" my={0.3} mr={1} alignItems='center'><FiberManualRecordIcon style={open[index]?{ color: green[700] }:{ color: red[700] }} />{open[index]?'Open':'Closed'}</Box></BlueTooltip>
  </Grid>
  */}
  <Grid key={2} item>
    <BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Overnight Parking (including facilities use)"}><Box className="noselect" display="flex" flexWrap="wrap" my={0.3}  mx={1} alignItems='center'><img src={site.content.priceStay==null || site.content.priceStay==""?iTopOvernightOff:iTopOvernight} width='auto' height='28'/>&nbsp;{(site.content.priceStay==null || site.content.priceStay=="")?"":(site.content.priceStay>0?"£"+site.content.priceStay:"free")}</Box></BlueTooltip>
  </Grid>
  <Grid key={3} item>
    <BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Facilities use only"}><Box className="noselect" display="flex" flexWrap="wrap" my={0.3}  mx={1} alignItems='center'><img src={site.content.priceFacilities==null || site.content.priceFacilities==''?iTopFacilitiesOff:iTopFacilities} width='auto' height='28'/>&nbsp;{site.content.priceFacilities==null || site.content.priceFacilities==''?"":(site.content.priceFacilities>0?"£"+site.content.priceFacilities:"free")}</Box></BlueTooltip>
  </Grid>
  <Grid key={4} item>
    <BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"No. Of Parking Bays"}><Box className="noselect" display="flex" flexWrap="wrap" my={0.3}  mx={1} alignItems='center'><img src={site.content.bays>0?iTopBays:iTopBaysOff} width='auto' height='24'/>&nbsp;{site.content.bays>0?site.content.bays:""}</Box></BlueTooltip>
  </Grid>
  <Grid key={5} item>
    <BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"CAMpRA have"+(site.accreditations?" ":" not ")+"certified this Aire"}><Box className="noselect" display="flex" flexWrap="wrap" my={0.3}  ml={1} alignItems='center'><img src={site.accreditations?iCampraA:iCampraX} width='auto' height='18'/></Box></BlueTooltip>
  </Grid>
   </Grid>
</Paper>
<br/>


</div>
<div>


<Grid container spacing={1}  wrap='nowrap'>
  <Grid item >
  <Img alt="sitepic" src={site.image?site.image:defaultSiteImage} style={{borderRadius: '4px'}}/>

  </Grid>

  </Grid>





<div>
<Box display="flex" width="100%" flexDirection="column" justifyContent="center" alignItems='center' >
<Box display="inline-block" my={1} justifyContent="center" alignItems='center' flexDirection="column">
<Box display="flex" flexWrap="wrap" p={-0.3}
  justifyContent="center"
  alignItems="center"
  width="100%"
>


<Box component="span" p={0.3} ><BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Overnight Parking is"+(site.content.facilities.facONS?"":" not")+" available."}><img src={site.content.facilities.facONS?iOvernight:iOvernightOff} width='40' height='40'/></BlueTooltip></Box>
<Box component="span" p={0.3} ><BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Black Water Disposal is"+(site.content.facilities.facBWD?"":" not")+" available."}><img src={site.content.facilities.facBWD?iBlackWater:iBlackWaterOff} width='40' height='40'/></BlueTooltip></Box>
<Box component="span" p={0.3} ><BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Grey Water Disposal is"+(site.content.facilities.facGWD?"":" not")+" available."}><img src={site.content.facilities.facGWD?iGreyWater:iGreyWaterOff} width='40' height='40'/></BlueTooltip></Box>
<Box component="span" p={0.3} ><BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Drinking Water is"+(site.content.facilities.facWTR?"":" not")+" available."}><img src={site.content.facilities.facWTR?iWater:iWaterOff} width='40' height='40'/></BlueTooltip></Box>
<Box component="span" p={0.3} ><BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Electric Hookup is"+(site.content.facilities.facEHU?"":" not")+" available."}><img src={site.content.facilities.facEHU?iElectric:iElectricOff} width='40' height='40'/></BlueTooltip></Box>
<Box component="span" p={0.3} ><BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Rubbish Bins are"+(site.content.facilities.facRUB?"":" not")+" available."}><img src={site.content.facilities.facRUB?iRubbish:iRubbishOff} width='40' height='40'/></BlueTooltip></Box>
<Box component="span" p={0.3} ><BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Recycling Bins are"+(site.content.facilities.facREC?"":" not")+" available."}><img src={site.content.facilities.facREC?iRecycling:iRecyclingOff} width='40' height='40'/></BlueTooltip></Box>
<Box component="span" p={0.3} ><BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Toilet is"+(site.content.facilities.facTLT?"":" not")+" available."}><img src={site.content.facilities.facTLT?iToilet:iToiletOff} width='40' height='40'/></BlueTooltip></Box>
<Box component="span" p={0.3} ><BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Shower is"+(site.content.facilities.facSWR?"":" not")+" available."}><img src={site.content.facilities.facSWR?iShower:iShowerOff} width='40' height='40'/></BlueTooltip></Box>
<Box component="span" p={0.3} ><BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Laundry is"+(site.content.facilities.facLND?"":" not")+" available."}><img src={site.content.facilities.facLND?iLaundry:iLaundryOff} width='40' height='40'/></BlueTooltip></Box>
<Box component="span" p={0.3} ><BlueTooltip arrow placement="top" enterTouchDelay={100} enterDelay={50} title={"Wifi is"+(site.content.facilities.facWFI?"":" not")+" available."}><img src={site.content.facilities.facWFI?iWifi:iWifiOff} width='40' height='40'/></BlueTooltip></Box>


</Box></Box>


</Box>

</div>

</div>


<div>
<Box display="flex" width="100%" flexDirection="column" justifyContent="center" alignItems='center'>

<Box display="inline-block" my={1}>
<Paper elevation={5} >

<Box display="flex" flexWrap="wrap"
  justifyContent="center"
  alignItems="center"
  width="100%"
>



    <Box  whiteSpace="nowrap"  p={1} >

    <Fab size="small" color="primary" aria-label="add" variant="extended" onClick={() => { handleEditSiteClick(site) }}>
    <span class="material-icons-round" style={{ color: "#fff"}}>
      edit
    </span>
    <span style={{ color: "#fff"}}>
    &nbsp;Edit Profile
    </span>
    </Fab>

    </Box>
    <Box  whiteSpace="nowrap"  p={1} >
    <Fab size="small" color="primary" aria-label="add" variant="extended" onClick={() => { handlePrintSiteClick(site) }}>
    <span class="material-icons-round" style={{ color: "#fff"}}>
      picture_as_pdf
    </span>
    <span style={{ color: "#fff"}}>
    &nbsp;Print Sign
    </span>
    </Fab>



    </Box>
    <Box  whiteSpace="nowrap"  p={1} >
    <Fab size="small" color="primary" aria-label="add" variant="extended" onClick={() => { handleOrderSiteClick(site) }}>
    <span class="material-icons-round" style={{ color: "#fff"}}>
      storefront
    </span>
    <span style={{ color: "#fff"}}>
    &nbsp;Order Signs
    </span>
    </Fab>

    </Box>
{/*}
    <Box  whiteSpace="nowrap"  p={1} >
    <Fab size="small" color="primary" aria-label="add" variant="extended" onClick={() => { handleTransactionsClick(site) }}>
    <span class="material-icons-round" style={{ color: "#fff"}}>
      menu_book
    </span>
    <span style={{ color: "#fff"}}>
    &nbsp;Guest Log
    </span>
    </Fab>

    </Box>
*/}
    <Box  whiteSpace="nowrap"  p={1}  >

    <Fab size="small" color="primary" aria-label="add" variant="extended" onClick={() => { handleDeleteSiteClick(site) }}>
    <span class="material-icons-round" style={{ color: "#fff"}}>
      delete_forever
    </span>
    </Fab>
    </Box>


  </Box>
</Paper>

  </Box>  </Box>

  </div>


</>);





}

function renderLoader() {
  return (
    <div id="splash" style={{paddingTop: 100, zIndex:999}}>

      <center id="splash">
        <img src={spinner} height="30px" width="auto"/>
        {/*}<Typography style={{padding: '4px'}} variant="caption">{label}</Typography>*/}
      </center>
    </div>


  );

}


  function renderLander() {
    return (
      <div style={isSwitching?{pointerEvents: "none", opacity: "0.7"}:{}}>
        <Box display="flex" flexDirection="column" flexWrap="nowrap" my={0}  mx={2} alignItems='top'>
        <Box display="flex" flexWrap="wrap" mb={0}  mx={0} alignItems='top'> <h2  className={classes.h2}>My AireStops</h2>  </Box>
        <Box mx={0.5}>
        {sites.length>1?
          <p>This is where you can find, manage and edit your AireStop{sites.length>1?"s":""}</p>
        :
          <p>Create your first AireStop site.</p>
        }
        </Box>
        </Box>
        <Box display="flex" width="100%" flexDirection="column" justifyContent="center" alignItems='center'>
        {renderSites()}
        </Box>
      </div>
    );
  }

  function renderSites() {
    return sites.map((site, index) => {
      return (
        <div key={site.itemCode?site.itemCode:"addSite"}>

        <Box maxWidth="650px">
        <Card className={classes.card}>
          <CardContent>
            {site.add?renderAdd():renderSite(site, index)}
          </CardContent>
        </Card>
        </Box>
        </div>
      );
    })
  }




  return (

    <div className="Sites">
      {isLoading?renderLoader():renderLander()}
      <Dialog
      open={alertOpen || deleteAlertOpen}
      fullWidth={false}
      maxWidth = {'md'}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      >
      <DialogTitle id="alert-dialog-slide-title" style={{color:"#10385A"}} >
      {alertVals.title}
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
      {alertVals.message}
      </DialogContentText>
      </DialogContent>
      <DialogActions>
      {deleteAlertOpen?
        <>
        <Button onClick={handleAlertClose} color="primary">
        Cancel
        </Button>
        <Button onClick={handleSiteDelete} color="secondary">
        Delete
        </Button>
      </>
      :
      <Button onClick={handleAlertClose} color="secondary">
      Ok
      </Button>
    }
      </DialogActions>
      </Dialog>
    </div>
  );
}
