import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './HostBookingDetails.css';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import TextField from '@material-ui/core/TextField';

//import ToggleButton from '@mui/material/ToggleButton';
//import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';



const HostBookingDetails = ({ bookingData, setBookingData, bookingState, setBookingState, handleStripePayClick }) => {
  const history = useHistory();
  const [price, setPrice] = useState(bookingData.totalAmount);
  const [email, setEmail] = useState(bookingData.email);
  const [payMethod, setPayMethod] = useState(bookingData.payMethod);
  const [vehicleRegistration, setVehicleRegistration] = useState(bookingData.vehicleRegistration);
  const [phoneNumber, setPhoneNumber] = useState(bookingData.phoneNumber);
  const [payType, setPayType] = useState(bookingState.dialogType=="facilities"?'facilities':'overnight');
  const [isPaying, setIsPaying] = useState(false);
  
  //console.log(bookingData);
  //console.log(bookingState);



  //console.log(isLoading, isPaying, isXXX);
  

  const handleSubmit = (e) => {
    e.preventDefault();
    
   // console.log(isLoading, isPaying, isXXX);
   // console.log(bookingState.card.date);
    setBookingData({ ...bookingData, email, vehicleRegistration, phoneNumber, payMethod, payType, totalAmount: price, paying: true});
    setIsPaying(true);
    //setBookingState({current:"paymentdonation", prev:bookingState.prev});
    //setBookingState({current:"bookingsummary", prev:bookingState.prev});


    



  };
  const handleBack = () => {
    //history.push('/calendar');
    //setBookingData({ ...bookingData, email, vehicleRegistration, phoneNumber, payMethod, payType});
    setBookingState({...bookingState, current:bookingState.prev, prev:bookingState.prev});

  };

  useEffect(() => {
    if (bookingData.paying) {
         handleStripePayClick();
    }
   }, [bookingData]);




  const handlePayMethod = (e, method) => {
    e.preventDefault();
    setPayMethod(method);
    if (method=="no-charge") {
        setPrice("0.00");
    }


  };
  function handlePriceChange(event) {
    const val = event.target.value;
    if (!isNaN(val)) {
      if (val.indexOf(".")>-1) {
        let parts = val.split(".");
        if (parts[1].length > 2) {
          return;
        }
        if (val.indexOf("0")===0 && val.length>1 && val.indexOf(".")!==1) {
          return;
        }
      } else if (val.indexOf("0")===0 && val.length>1) {
        return;
      }
      
      setPrice(val);
    }
  }
  
  function handlePriceOnBlur(event) {
    const val = event.target.value;
    let priceStr = "";
    if (val.indexOf(".")>-1) {
      let parts = val.split(".");
      if (parts[1].length === 2) {
        return;
      } else if (parts[1].length === 1) {
        priceStr = val + "0";
      } else if (parts[1].length === 0) {
        priceStr = val + "00";
      }
    } else {
      if (val.length === 0) {
        priceStr = val + "0.00";
      } else {
        priceStr = val + ".00";
      }
    }
    setPrice(priceStr);
  }



  return (
    <div className="hostbooking-details">
             <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 0,
          m: 0,
          borderRadius: 1,
          alignContent: 'center',
        }}
      >
        <Box><h4>Details</h4></Box>
        {bookingState.dialogType!='facilities' &&
        <Box  mt={-0.4}>
        <Button
         size="small"
        color="primary"
        onClick={handleBack}
        startIcon={<ArrowBackIcon />}
      >Back
      </Button>
      </Box>
}
      </Box>
 





      <div className="details">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">{bookingState.dialogType=='facilities'?'Customer':'Guest'} Email Address *</label>
          <input
            type="email"
            id="email"
            value={email}
            disabled={bookingData.paying}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="vehicleRegistration">{bookingState.dialogType=='facilities'?'Customer':'Guest'} Vehicle Registration *</label>
          <input
            type="text"
            id="vehicleRegistration"
            disabled={bookingData.paying}
            value={vehicleRegistration}
            onChange={(e) => setVehicleRegistration(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phoneNumber">{bookingState.dialogType=='facilities'?'Customer':'Guest'} Phone Number</label>
          <input
            type="text"
            id="phoneNumber"
            disabled={bookingData.paying}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="phoneNumber">Payment Method</label>
       <div className="pay-types">
      <Button disabled={bookingData.paying} color="primary" variant={payMethod=="card"?"contained":"outlined"} onClick={(e) => handlePayMethod(e, 'card')}>
  Card
</Button>
<Button disabled={bookingData.paying} color="primary" variant={payMethod=="cash"?"contained":"outlined"} onClick={(e) => handlePayMethod(e, 'cash')}>
  Cash
</Button>
<Button disabled={bookingData.paying} color="primary"  variant={payMethod=="paypal"?"contained":"outlined"} onClick={(e) => handlePayMethod(e, 'paypal')}>
  Paypal
</Button>
<Button disabled={bookingData.paying} color="primary" variant={payMethod=="no-charge"?"contained":"outlined"} onClick={(e) => handlePayMethod(e, 'no-charge')}>
  No Charge
</Button>   
</div>
</div>    




<div className="form-group">
          <label htmlFor="amount">Amount</label>
</div>
<Box mt={-2} mb={4}>
<TextField id="price"
  disabled={payMethod=='no-charge' || bookingData.paying}
  value={price}
  onChange={handlePriceChange}
  onBlur={handlePriceOnBlur}
  name="price"
  InputProps={{
    startAdornment:
    <>£</>
  }}

  fullWidth
   type="text" variant="outlined" size="small"/>
</Box>  

<button type="submit" 
disabled={bookingData.paying || payMethod=="" || (payMethod!="no-charge" && Number(price)==0)} 
className="submit-btn">
    {bookingState.dialogType=='facilities'?'Add Payment':'Create Booking'}
</button>
      </form>
      </div></div>
  );
};

export default HostBookingDetails;
