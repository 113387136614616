import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './CalendarView.css';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import ForwardIcon from '@material-ui/icons/KeyboardArrowRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';


import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import NativeSelect from '@material-ui/core/NativeSelect';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import iTopFacilities from '../images/preview/Facilities Only - Blue.svg';

import { 
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ToggleButton,
  styled
} from '@mui/material';

import GrassIcon from'../images/req_grass.svg';
import GrassGreyIcon from'../images/req_grass_grey.svg';
import GravelIcon from'../images/req_gravel.svg';
import GravelGreyIcon from'../images/req_gravel_grey.svg';
import HardstandingIcon from'../images/req_hardstanding.svg';
import HardstandingGreyIcon from'../images/req_hardstanding_grey.svg';
import EHUIcon from'../images/req_EHU.svg';
import EHUGreyIcon from'../images/req_EHU_grey.svg';

/*
import GrassIcon from '@mui/icons-material/Grass';
import GridOnIcon from '@mui/icons-material/GridOn';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';*/




const CalendarView = ({ bookingData, setBookingData, bookingState, setBookingState, content, bookings, setPayType, pitchfeatures, setPitchfeatures, hasFacilities}) => {
  const history = useHistory();
  const today = new Date();
  today.setHours(12,0,0,0);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDates, setSetSelectedDates] = useState(bookingData.selectedDates);


  const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();


  const [pitchfeaturesIsOpen, setPitchfeaturesIsOpen] = useState(false);
  const [alertVehicleLengthOpen, setAlertVehicleLengthOpen] = useState(false);

  const lengthOptions = Array.from(
    { length: ((14 - 3) * 2) + 1 },
    (_, i) => 30 + (i * 5)
  );


  /*
  let clonedBayList = null;
    if (content.bayList) {
      clonedBayList = [];
      content.bayList.forEach(bay => {
      clonedBayList.push({id: bay.id, name: bay.name, surface: bay.surface, maxlength: bay.maxlength, electric: bay.electric, price: bay.price});
    });  
  }     
    */ 


  const getDateCard = (date) => {
    date.setHours(12,0,0,0);
    //let cutoff = 0;
    let cutoff = -1; // unlimited
    let status = "ok"; 
    let tooltipText = "" + (content.bays) + " pitches remaining";
    //if (content.maxBookAhead && content.maxBookAhead!=-1) {
        cutoff = content.maxBookAhead;
    //}
    const datesStr = date.toISOString().split('T')[0];


    let clonedBayList = null;
    if (content.bayList) {
      clonedBayList = [];
      //content.bayList.forEach(bay => {
      for (let bayIdx = 0; bayIdx<content.bayList.length;bayIdx++) {
          //content.bayList.forEach(bay => {
          const bay = content.bayList[bayIdx];
          clonedBayList.push({bayIndex: bayIdx, id: bay.id, name: bay.name, surface: bay.surface, maxlength: bay.maxlength, electric: bay.electric, price: bay.price});
    };  
  }      

    /// Reserved BAYS
    let bayReserveCount = 0;
     if (bookings && bookings.length>0) {
      bookings.forEach(booking => {
        //if (booking.date == date.getTime()) {
        if (booking.date == datesStr) {
            bayReserveCount = booking.bayReserveCount;
              if (content.bays - bayReserveCount<=0) {
                status="full";
                tooltipText="No available pitches";
              } else {
                tooltipText = "" + (content.bays - bayReserveCount) + " pitches remaining";
              }
              booking.pitches.forEach( pitch => {
                if (pitch.id>-1) {
                  for (let bayidx=0;bayidx<clonedBayList.length;bayidx++) {
                    if (pitch.id == clonedBayList[bayidx].id) {
                      clonedBayList.splice(bayidx, 1);
                      break;
                    }
                  }                   
                  }
              });
          }
      });
    }




    if (content.closedDates && content.closedDates.length>0) {
        if (content.closedDates.includes(date.getTime())) {
            status="closed";
            tooltipText = "closed";
        }
    }
    const diffTime = Math.abs(date - today);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); 
    if (cutoff==-2 || (cutoff>-1 && (diffDays>cutoff))) {
        status="unavailable";
        tooltipText = "unavailable";
    }
    if (today>date) {
        status="prevday";
        tooltipText = "unavailable";
    }

       /////BAY feature check  
       let assignedBay = null;
       let possibles = [];
       if (status=="ok") {               
        if (clonedBayList) {
            let assignBay = false;
            for (let i=0;i<clonedBayList.length;i++) {
              let assignBay = false;
                if (clonedBayList[i].maxlength>=Number(pitchfeatures.reqLength) && (!pitchfeatures.hookup || clonedBayList[i].electric)) {
                    if (!pitchfeatures.surfaces.grass && !pitchfeatures.surfaces.gravel && !pitchfeatures.surfaces.hard) {
                        assignBay = true;
                    } else {
                        switch (clonedBayList[i].surface) {
                            case 0: //unset
                                assignBay = true;
                                break;
                            case 1: //grass
                                if (pitchfeatures.surfaces.grass) {
                                    assignBay = true;
                                }
                                break;
                            case 2: //gravel
                                if (pitchfeatures.surfaces.gravel) {
                                    assignBay = true;
                                }
                                break;
                            case 3: //hard
                                if (pitchfeatures.surfaces.hard) {
                                    assignBay = true;
                                }
                                break;
                        }
                    }
                }
                //console.log(assignBay,content.bayList[i], pitchfeatures);
                //if (assignBay) {
                //    assignedBay = clonedBayList[i];
                //    break;
                //}
                if (assignBay) {
                  possibles=[...possibles, {index: clonedBayList[i].bayIndex, weighting: clonedBayList[i].maxlength-Number(pitchfeatures.reqLength), EHUMatch: pitchfeatures.hookup == clonedBayList[i].electric}];
                }
            }

            if (possibles.length>0) {

              /*
              if (possibles.length==1) {
                assignedBay = clonedBayList[possibles[0].index];
              } else {
                possibles.sort((a,b)=>{
                  if (a.weighting!=b.weighting) {
                    return a.weighting-b.weighting;
                  } else {
                    return b.EHUMatch - a.EHUMatch;
                  }
                })
                assignedBay = clonedBayList[possibles[0].index];
              }
              */


              possibles.sort((a,b)=>{
                if (a.weighting!=b.weighting) {
                  return a.weighting-b.weighting;
                } else {
                  return b.EHUMatch - a.EHUMatch;
                }
              })
              bayReserveCount = content.bays-possibles.length;
              tooltipText = "" + (content.bays - bayReserveCount) + " pitches remaining";
          } else {
            status="full";
            tooltipText="no pitches available that match your criteria";
            bayReserveCount = content.bays;
          }               
        }
    }
    //return {date:date, status: status, bayReserveCount: bayReserveCount, remainingBays: content.bays-bayReserveCount, tooltipText: tooltipText, bay: assignedBay};
    return {date:date, status: status, bayReserveCount: bayReserveCount, remainingBays: content.bays-bayReserveCount, tooltipText: tooltipText, possibles: possibles};
  }

/*
  const handleDateSelectX = (day, datacard) => {
    const selectedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day,12);
    const dateString = selectedDate.toISOString().split('T')[0];
    const newSelectedDates = [...selectedDates];
    const index = newSelectedDates.indexOf(dateString);
    if (datacard.status!="ok") {
      if (index > -1) {
        console.log(index);
        newSelectedDates.splice(index, 1);
      } else {
        return;
      }
    } else {
      if (index > -1) {
        newSelectedDates.splice(index, 1);
      } else {
        newSelectedDates.push(dateString);
      }
    }
    setSetSelectedDates(newSelectedDates);
  };
  */

  const handleDateSelect = (day, selected) => {
    if (pitchfeatures.reqLength==0) {
      setAlertVehicleLengthOpen(true);
      return;
  }
    var newSelectedDates = [...selectedDates];
    const dateString = selected.date.toISOString().split('T')[0];
    const currentStartDate = new Date(newSelectedDates[0]); 
    currentStartDate.setHours(12,0,0,0);
    var dateRangeResult = {dates: [], pitch: null};
    if (selected.status!="ok") {
      const index = newSelectedDates.indexOf(dateString);
        if (index > -1) {
          //newSelectedDates = [];
        } else {
          return;
        }
      } else {
        if (newSelectedDates.length==0) {
            //newSelectedDates.push(dateString);
            dateRangeResult = dateRange(selected.date,selected.date);
        } else {
            if (selected.date>currentStartDate) {
                //newSelectedDates = dateRange(currentStartDate,selected.date);
                dateRangeResult = dateRange(currentStartDate,selected.date);
            } else if (selected.date<currentStartDate) {
                //newSelectedDates = dateRange(selected.date, currentStartDate);
                dateRangeResult = dateRange(selected.date, currentStartDate);
            } else {
                if (newSelectedDates.length==1) {
                    //newSelectedDates = [];
                } else {
                    //newSelectedDates = [dateString];
                    dateRangeResult = dateRange(selected.date,selected.date);
                }
                
            }
        }
    }

    //console.log(content.bayList[dateRangeResult.pitchIndex]);
    setBookingData({ ...bookingData, pitch:content.bayList[dateRangeResult.pitchIndex]});
    //console.log(newSelectedDates);
    setSetSelectedDates(dateRangeResult.dates);
  };





  const handleMonthChange = (increment) => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + increment);
    setCurrentDate(newDate);
  };

  const handleConfirm = () => {
    if (selectedDates.length > 0) {
      if (setPayType) {
        setPayType("overnight");
      }


     //get associated pitches
     /*
     let selectedPitches = {};
     selectedDates.forEach(strDate => {
       const dc = getDateCard(new Date(strDate));
       selectedPitches[strDate] = dc.bay;
       
     });
     */
      setBookingData({ ...bookingData, selectedDates: selectedDates, pitchRequirements: pitchfeatures});
      setBookingState({...bookingState, current:"userdetails", prev:"calendarview"});
      //console.log(selectedPitches);
  }
  };

  const handleFacilitiesUse = () => {
    if (setPayType) {
      setPayType("facilities");
    }
    setBookingData({ ...bookingData, selectedDates: [(new Date()).toISOString().split('T')[0]]});
    setBookingState({...bookingState, current:"userdetails", prev:"calendarview"});
};

  const handleDateView = () => {
    //history.push('/calendar');
    setBookingData({ ...bookingData, selectedDates: selectedDates });
    setBookingState({...bookingState, current:"dateselection", prev:"calendarview"});

  };


  const handlePitchFeaturesClick = () => {
    setPitchfeaturesIsOpen(!pitchfeaturesIsOpen);
  };






  const handlePitchfeaturesHookupChange = (e) => {
    e.preventDefault();
    //if (e.target.checked) {
      setSetSelectedDates([]); 
    //}
    setPitchfeatures({reqLength:pitchfeatures.reqLength, hookup: !pitchfeatures.hookup, surfaces:pitchfeatures.surfaces})
}

const handlePitchfeaturesGrassChange = (e) => {
    e.preventDefault();
    setSetSelectedDates([]);
    setPitchfeatures({reqLength:pitchfeatures.reqLength, hookup: pitchfeatures.hookup, surfaces:{grass:!pitchfeatures.surfaces.grass,gravel:pitchfeatures.surfaces.gravel,hard:pitchfeatures.surfaces.hard}})
}

const handlePitchfeaturesGravelChange = (e) => {
    e.preventDefault();
    setSetSelectedDates([]);
    setPitchfeatures({reqLength:pitchfeatures.reqLength, hookup: pitchfeatures.hookup, surfaces:{grass:pitchfeatures.surfaces.grass,gravel:!pitchfeatures.surfaces.gravel,hard:pitchfeatures.surfaces.hard}})
}

const handlePitchfeaturesHardChange = (e) => {
    e.preventDefault();
    setSetSelectedDates([]);
    setPitchfeatures({reqLength:pitchfeatures.reqLength, hookup: pitchfeatures.hookup, surfaces:{grass:pitchfeatures.surfaces.grass,gravel:pitchfeatures.surfaces.gravel,hard:!pitchfeatures.surfaces.hard}})
}

const handlePitchfeaturesLengthChange = (e) => {
    e.preventDefault();
    //console.log(pitchfeatures.reqLength,e.target.value,pitchfeatures.reqLength<e.target.value);
    //if (e.target.value>pitchfeatures.reqLength) { // need to reset the selected dates as they may not have a sufficiently long pitch available
      setSetSelectedDates([]);
    //}
    setPitchfeatures({reqLength:e.target.value, hookup: pitchfeatures.hookup, surfaces:pitchfeatures.surfaces})
}




function handleAlertClose(event) {
  event.preventDefault();
 setAlertVehicleLengthOpen(false);
}



  return (
    <>
    <Dialog
    maxWidth='xs'
     open={alertVehicleLengthOpen}
     onClose={handleAlertClose}
     aria-labelledby="alert-dialog-slide-title"
     aria-describedby="alert-dialog-slide-description">
     <DialogTitle id="alert-dialog-slide-title" style={{color:"#10385A"}} >Vehicle Length required</DialogTitle>
     <DialogContent>
       <DialogContentText id="alert-dialog-slide-description">
           Please first select your vehicle length and any other pitch requirements.
       </DialogContentText>
     </DialogContent>
     <DialogActions>
       <Button onClick={handleAlertClose} color="primary" >
         Ok
       </Button>
     </DialogActions>
   </Dialog>
   <Paper variant="outlined" >
    <div className="calendar-date-selection">





              <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 0,
          m: 0,
          borderRadius: 1,
          alignContent: 'center',
        }}
      >
        <Box><h4>Select date(s)</h4></Box>
        <Box  mt={-0.4}>
        <Button
         size="small"
        color="primary"
        onClick={handleDateView}
        startIcon={<DateRangeRoundedIcon />}
      >Day view
      </Button>
      </Box>
      </Box>



{/*}

      <Box>
      <div className='features-card'>
        <Button
         size="small"
        color="primary"
        onClick={handlePitchFeaturesClick}
        endIcon={pitchfeaturesIsOpen?<ExpandLessIcon/>:<ExpandMoreIcon/>}
      >pitch features
      </Button>
      {pitchfeatures.reqLength>0 && 
      <>
      <Chip key={"key1"}
      variant="default"
      size="small"
      color="secondary"
      style={{height:"100%",margin: '3px'}}
      label={<Typography style={{whiteSpace: 'normal', fontSize: '12px'}}>{(pitchfeatures.reqLength/10.0)+" meters"}</Typography>}
      
      />
      {pitchfeatures.hookup &&
      <Chip key={"key2"}
      variant="default"
      size="small"
      color="secondary"
      style={{height:"100%",margin: '3px'}}
      label={<Typography style={{whiteSpace: 'normal', fontSize: '12px'}}>{"electric hookup"}</Typography>}
      />
    }
    {pitchfeatures.surfaces.grass &&
      <Chip key={"surfacesgrass"}
      variant="default"
      size="small"
      color="secondary"
      style={{height:"100%",margin: '3px'}}
      label={<Typography style={{whiteSpace: 'normal', fontSize: '12px'}}>grass</Typography>}
      />
    }
    {pitchfeatures.surfaces.gravel &&
      <Chip key={"surfacesgravel"}
      variant="default"
      size="small"
      color="secondary"
      style={{height:"100%",margin: '3px'}}
      label={<Typography style={{whiteSpace: 'normal', fontSize: '12px'}}>gravel</Typography>}
      />
    }
    {pitchfeatures.surfaces.hard &&
      <Chip key={"surfaceshard"}
      variant="default"
      size="small"
      color="secondary"
      style={{height:"100%",margin: '3px'}}
      label={<Typography style={{whiteSpace: 'normal', fontSize: '12px'}}>hard</Typography>}
      />
    }
      </>
}

      {pitchfeaturesIsOpen &&
      <>
      <Box m={1}>


<div className='features-card darker'>
<Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: '4px',
                m: 0,
                borderRadius: 1,
                alignItems: 'center',
                
        
                
              }}
            >

<Box>Maximum vehicle length</Box>
<NativeSelect
          value={pitchfeatures.reqLength}
          onChange={handlePitchfeaturesLengthChange}
          
          name="pitchfeatureslength"
           color="secondary"
           
        >

          <option value={0}>not set</option>
          <option value={30}>3 metres</option>
          <option value={35}>3.5 metres</option>
          <option value={40}>4 meters</option>
          <option value={45}>4.5 meters</option>
          <option value={50}>5 meters</option>
          <option value={55}>5.5 meters</option>
          <option value={60}>6 meters</option>
          <option value={65}>6.5 meters</option>
          <option value={70}>7 meters</option>
          <option value={75}>7.5 meters</option>
          <option value={80}>8 meters</option>
          <option value={85}>8.5 meters</option>
          <option value={90}>9 meters</option>
          <option value={95}>9.5 meters</option>
          <option value={100}>10 meters</option>
          <option value={105}>10.5 meters</option>
          <option value={110}>11 meters</option>
          <option value={115}>11.5 meters</option>
          <option value={120}>12 meters</option>
          <option value={125}>12.5 meters</option>


        </NativeSelect>
        </Box>
        </div>
       




       <div className='features-card darker'>
              <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: '4px',
                m: 0,
                borderRadius: 1,
                alignItems: 'center',
              }}
            >
              <Box>Electric hookup (EHU)</Box>
             
              <Switch
            checked={pitchfeatures.hookup}
            onChange={handlePitchfeaturesHookupChange}
            name="hookup"
            size="small"
            color="secondary"
          />           
            </Box>
            </div>

            <div className='features-card darker'>
              <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: '4px',
                m: 0,
                borderRadius: 1,
                alignItems: 'center',
              }}
            >
              <Box>Grass surface</Box>
             
              <Switch
            checked={pitchfeatures.surfaces.grass}
            onChange={handlePitchfeaturesGrassChange}
            name="grass surface"
            size="small"
            color="secondary"
          />           
            </Box>
            </div>
            <div className='features-card darker'>
              <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: '4px',
                m: 0,
                borderRadius: 1,
                alignItems: 'center',
              }}
            >
              <Box>Gravel surface</Box>
             
              <Switch
            checked={pitchfeatures.surfaces.gravel}
            onChange={handlePitchfeaturesGravelChange}
            name="gravelsurface"
            size="small"
            color="secondary"
          />           
            </Box>
            </div>
            <div className='features-card darker'>
              <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: '4px',
                m: 0,
                borderRadius: 1,
                alignItems: 'center',
              }}
            >
              <Box>Hard surface</Box>
             
              <Switch
            checked={pitchfeatures.surfaces.hard}
            onChange={handlePitchfeaturesHardChange}
            name="hardsurface"
            size="small"
            color="secondary"
          />           
            </Box>
            </div>
 




            </Box>
            </>
      
      
      }
      </div>
 </Box>
*/}


 <Box className="filter-container">
      {/* Vehicle length dropdown section 
      <Box className="select-wrapper">
        <Select
          value={length}
          onChange={handleLengthChange}
          className="custom-select"
        >
          {lengthOptions.map((value) => (
            <MenuItem key={value} value={value}>
              {value}m
            </MenuItem>
          ))}
        </Select>
        <span className="select-label">VEHICLE LENGTH</span>
        
      </Box>*/}


      <Box>
      <FormControl  >
      <InputLabel sx={{
          color: "gray", // Default label color
          "&.Mui-focused": {
            color: "#10385A", // Label color when focused
          }
        }}>Vehicle Length</InputLabel>
             <Select
             sx={{minWidth:"120px",
              "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "gray", // Default border color
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#10385A", // Border color on hover
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#10385A !important", // Change border color on focus
            borderWidth: "2px", // Optional: Make border thicker on focus
          },
             }}
          value={pitchfeatures.reqLength}
          
          onChange={handlePitchfeaturesLengthChange} //handleLengthChange} //handlePitchfeaturesLengthChange
          
          label="Vehicle Length"
        >
          {pitchfeatures.reqLength==0 &&
           <MenuItem key={0} value={0}>
            unset
          </MenuItem>
}
           {lengthOptions.map((value) => (
            <MenuItem key={value} value={value}>
            {value/10}m
          </MenuItem>
      ))}




        </Select>
        </FormControl>


    </Box>


    <Box
      
      onClick={handlePitchfeaturesGrassChange}

      
      
      sx={{
        minWidth: 80,
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        
        color: pitchfeatures.surfaces.grass ? "#10385A" : "gray",
        
        cursor: "pointer",
        transition: "background-color 0.3s, border-bottom 0.3s",
        borderBottom: pitchfeatures.surfaces.grass ? "2px solid #10385A" : "2px solid transparent", // Underline effect

        "&:hover": {
          backgroundColor: "lightgray",
        },
      }}
    >
      {/* Icon */}
      {pitchfeatures.surfaces.grass ?
      <img src={GrassIcon} />
      :
      <img src={GrassGreyIcon} />
      }
      
      {/* Text */}
      <Typography
        variant="body2"
        sx={{
          marginTop: "5px",
          fontSize: "14px",
          textAlign: "center",
          
        }}
      >
        Grass
      </Typography>
    </Box>

    

    <Box
      onClick={handlePitchfeaturesGravelChange}
      sx={{
        minWidth: 80,
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        
        color: pitchfeatures.surfaces.gravel ? "#10385A" : "gray",
        
        cursor: "pointer",
        transition: "background-color 0.3s, border-bottom 0.3s",
        borderBottom: pitchfeatures.surfaces.gravel ? "2px solid #10385A" : "2px solid transparent", // Underline effect

        "&:hover": {
          backgroundColor: "lightgray",
        },
      }}
    >
      {/* Icon */}
      {pitchfeatures.surfaces.gravel ?
      <img src={GravelIcon} />
      :
      <img src={GravelGreyIcon} />
      }
      
      {/* Text */}
      <Typography
        variant="body2"
        sx={{
          marginTop: "5px",
          fontSize: "14px",
          textAlign: "center",
          
        }}
      >
        Gravel
      </Typography>
    </Box>

    <Box
      onClick={handlePitchfeaturesHardChange}
      sx={{
        minWidth: 80,
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        
        color: pitchfeatures.surfaces.hard ? "#10385A" : "gray",
        
        cursor: "pointer",
        transition: "background-color 0.3s, border-bottom 0.3s",
        borderBottom: pitchfeatures.surfaces.hard ? "2px solid #10385A" : "2px solid transparent", // Underline effect

        "&:hover": {
          backgroundColor: "lightgray",
        },
      }}
    >
      {/* Icon */}
      {pitchfeatures.surfaces.hard ?
      <img src={HardstandingIcon}/>
      :
      <img src={HardstandingGreyIcon} />
      }
      
      {/* Text */}
      <Typography
        variant="body2"
        sx={{
          marginTop: "5px",
          fontSize: "14px",
          textAlign: "center",
          
        }}
      >
        Hardstanding
      </Typography>
    </Box>






    <Box
      onClick={handlePitchfeaturesHookupChange}
      sx={{
        minWidth: 80,
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        
        color: pitchfeatures.hookup ? "#10385A" : "gray",
        
        cursor: "pointer",
        transition: "background-color 0.3s, border-bottom 0.3s",
        borderBottom: pitchfeatures.hookup ? "2px solid #10385A" : "2px solid transparent", // Underline effect

        "&:hover": {
          backgroundColor: "lightgray",
        },
      }}
    >
      {/* Icon */}
      {pitchfeatures.hookup ?
      <img src={EHUIcon} />
      :
      <img src={EHUGreyIcon} />
      }
      
      {/* Text */}
      <Typography
        variant="body2"
        sx={{
          marginTop: "5px",
          fontSize: "14px",
          textAlign: "center",
          
        }}
      >
        EHU
      </Typography>
    </Box>
    </Box>












 
   




       <div className="calendar-view">
       <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 0,
          m: 0,
          borderRadius: 1,
          alignContent: 'center',
        }}
      >
        <Box> <h6>{currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}</h6></Box>
        <Box  mt={-0.4}>
        <IconButton
         size="small"
        color="primary"
        onClick={() => handleMonthChange(-1)}

      ><BackIcon /></IconButton>
    
      <IconButton
         size="small"
        color="primary"
        onClick={() => handleMonthChange(1)}
        
      ><ForwardIcon /></IconButton>
    
      </Box>
      </Box>
      {/*}
     <div className="calendar-header">
        <button onClick={() => handleMonthChange(-1)}>&lt;</button>
        <h5>{currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}</h5>
        <button onClick={() => handleMonthChange(1)}>&gt;</button>
      </div>
      */}



      <div className="calendar-grid">
        {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
          <div key={day + index} className="calendar-day day-name">{day}</div>
        ))}
        {[...Array(firstDayOfMonth).keys()].map(i => (
          <div key={`empty-${i}`} className="calendar-day empty"></div>
        ))}
        {[...Array(daysInMonth).keys()].map(i => {
          const day = i + 1;
          const d = new Date(currentDate.getFullYear(), currentDate.getMonth(), day,12);
          const date = d.toISOString().split('T')[0];
          const dc = getDateCard(d);

          //console.log(dc);
          return (
            
            <div
              key={day}
              className={`calendar-day ${selectedDates.includes(date) ? 'selected' : dc.status=="ok"?'':'unavailable'}`}
              onClick={() => handleDateSelect(day, dc)}
            >
            
              {day}
              <span className="tooltiptext">{dc.tooltipText}</span>
            </div>
          );
        })}
      </div></div>
      <div className="summary">
      <p><b>You have {selectedDates.length} night{selectedDates.length !== 1 ? 's' : ''} selected</b></p>
      {content.maxNights>0 && selectedDates.length==content.maxNights && 
      <p style={{ color: 'red' }}>Maximum nights stay reached</p>
      }     

      <button className="confirm-dates-btn" onClick={handleConfirm}>Select Dates</button>
      </div></div>


      </Paper>
{setPayType && hasFacilities &&
<Box mt={2}>
<Paper variant="outlined" >
    <div className="date-selection">
              <Box
        sx={{
          flexDirection:"column",
          display: 'flex',
          justifyContent: 'center',
          alignItems:'center',
          alignContent:'center',
          p: 0,
          m: 0,
          borderRadius: 1,
       
        }}

      >

<Box mb={2} mt={1}><img src={iTopFacilities} width='auto' height='100' /></Box>


<button className="select-dates-btn" onClick={handleFacilitiesUse}>Pay for service use only</button>


      </Box>
      </div>
      </Paper>
      </Box>
}




      </> 
  );



/*
  function dateRange(startDate, endDate, steps = 1) {
    const dateArray = [];
    let currentDate = startDate;
    const maxNights = content.maxNights || 0;
    while (currentDate <= endDate && (maxNights==0 || dateArray.length<=maxNights-1)) {
      const card = getDateCard(currentDate, 0);
      console.log(card.status);
      if (card.status!="ok") {
        return dateArray;
      }

      dateArray.push(currentDate.toISOString().split('T')[0]);
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }
    return dateArray;
  }
*/


  function dateRange(startDate, endDate, steps = 1) {
    const dateArray = [];
    var possibles = {};
    var currentDate = new Date(startDate.getTime());
    const maxNights = content.maxNights || 0;
    while (currentDate <= endDate && (maxNights==0 || dateArray.length<=maxNights-1)) {
      const card = getDateCard(currentDate, 0);
      const currentDateStr = currentDate.toISOString().split('T')[0];
      //console.log(card.status);
      if (card.status!="ok") {
        //return dateArray;
        break;
      }
      possibles = {...possibles, [currentDateStr]: card.possibles}
      dateArray.push(currentDate.toISOString().split('T')[0]);
      currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }


    //console.log(content.bayList);

   ///////////////////// work out if each selected date can have the same pitch and assign the pitch////////////////////////
   //console.log(possibles);
   let maxDateIndex = 0;
   let bestPitchIndex = possibles[dateArray[0]][0].index;
   let newDatesArray = [dateArray[0]];
   for (let possIdx=0;possIdx<possibles[dateArray[0]].length;possIdx++){
    let bayIdx = possibles[dateArray[0]][possIdx].index
    let dateIndex = 0;
    for(let idx=1;idx<dateArray.length;idx++) {
      let hasSamePitchAvailable = false;
      for (let possIdx2=0;possIdx2<possibles[dateArray[idx]].length;possIdx2++){
        //console.log(bayIdx, idx,possibles[dateArray[idx]][possIdx2].index, bayIdx==possibles[dateArray[idx]][possIdx2].index);
        if (bayIdx==possibles[dateArray[idx]][possIdx2].index) {
          hasSamePitchAvailable = true;
          dateIndex = idx;
          if (maxDateIndex<idx) {
            maxDateIndex = idx;
            bestPitchIndex = bayIdx;
          }
          break;
        }  
      }
      if (!hasSamePitchAvailable) {
        newDatesArray = [dateArray[0]];
        break;
      }
    }
    if (maxDateIndex==dateArray.length-1) {
     break;
    }

  }
  //console.log(maxDateIndex,bestPitchIndex);
  if (maxDateIndex>0) {
    for(let idx=1;idx<=maxDateIndex;idx++) {
      newDatesArray.push(dateArray[idx]);
    }
  }

  return {dates:newDatesArray, pitchIndex: bestPitchIndex}; 
}
  
  
  function isFullOnDate(timestamp) {
    let isFull = false;
    if (bookings && bookings.length>0) {
        bookings.forEach(booking => {
            if (booking.date == timestamp) {
                if (content.bays - booking.bayReserveCount<=0) {
                    isFull = true;
                }
            }
        });
    } 
    return isFull;
  }
};


/*
function dateRange(startDate, endDate, steps = 1) {
  const dateArray = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dateArray.push(currentDate.toISOString().split('T')[0]);
    currentDate.setUTCDate(currentDate.getUTCDate() + steps);
  }
  return dateArray;
}
  */





export default CalendarView;
