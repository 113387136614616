import React, { useState , useEffect} from "react";
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { grey } from '@material-ui/core/colors';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';

const GreenCheckbox = withStyles({
  root: {
    color: "#fff",
    '&$checked': {
      color: "#fff",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function CheckboxFacilities(props) {
  const [state, setState] = React.useState({
    facONS: props.values?props.values.facONS:false,
    facBWD: props.values?props.values.facBWD:false,
    facGWD: props.values?props.values.facGWD:false,
    facEHU: props.values?props.values.facEHU:false,
    facLND: props.values?props.values.facLND:false,
    facREC: props.values?props.values.facREC:false,
    facRUB: props.values?props.values.facRUB:false,
    facSWR: props.values?props.values.facSWR:false,
    facTLT: props.values?props.values.facTLT:false,
    facWTR: props.values?props.values.facWTR:false,
    facWFI: props.values?props.values.facWFI:false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    if (props.onChange) {
      props.onChange(state)
    }
  }, [state.facONS, state.facBWD, state.facGWD, state.facEHU, state.facLND, state.facREC, state.facRUB, state.facSWR, state.facTLT, state.facWTR, state.facWFI])
  return (
    <FormGroup row>
    {/*}<FormControlLabel
      control={<Checkbox checked={state.facONS} onChange={handleChange} name="facONS" color="secondary"/>}
      label="Overnight Stay"/>*/}
      <FormControlLabel
        control={<Checkbox checked={state.facBWD && !props.disabled} disabled={props.disabled} onChange={handleChange} name="facBWD" color="secondary"/>}
        label="Black Water Disposal"/>
        <FormControlLabel
          control={<Checkbox checked={state.facGWD && !props.disabled} disabled={props.disabled} onChange={handleChange} name="facGWD" color="secondary"/>}
          label="Grey Water Disposal"/>
          {/*<FormControlLabel
            control={<Checkbox checked={state.facEHU && !props.disabled} disabled={props.disabled} onChange={handleChange} name="facEHU" color="secondary"/>}
            label="Electric Hookup"/>*/}
            <FormControlLabel
              control={<Checkbox checked={state.facLND && !props.disabled} disabled={props.disabled} onChange={handleChange} name="facLND" color="secondary"/>}
              label="Laundry"/>
              <FormControlLabel
                control={<Checkbox checked={state.facREC && !props.disabled} disabled={props.disabled} onChange={handleChange} name="facREC" color="secondary"/>}
                label="Recycling"/>
                <FormControlLabel
                  control={<Checkbox checked={state.facRUB && !props.disabled} disabled={props.disabled} onChange={handleChange} name="facRUB" color="secondary"/>}
                  label="Rubbish"/>
                  <FormControlLabel
                    control={<Checkbox checked={state.facSWR && !props.disabled} disabled={props.disabled} onChange={handleChange} name="facSWR" color="secondary"/>}
                    label="Shower"/>
                    <FormControlLabel
                      control={<Checkbox checked={state.facTLT && !props.disabled} disabled={props.disabled} onChange={handleChange} name="facTLT" color="secondary"/>}
                      label="Toilet"/>
                      <FormControlLabel
                        control={<Checkbox checked={state.facWTR && !props.disabled} disabled={props.disabled} onChange={handleChange} name="facWTR" color="secondary"/>}
                        label="Water"/>
                        <FormControlLabel
                          control={<Checkbox checked={state.facWFI && !props.disabled} disabled={props.disabled} onChange={handleChange} name="facWFI" color="secondary"/>}
                          label="Wifi"/>
    </FormGroup>
  );
}
