import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import Form from "react-bootstrap/Form";
import LoaderButtonX from "../components/LoaderButtonX";
//import LoaderButtonTextOnly from "../components/LoaderButtonTextOnly";
import { useLocation, useHistory, useParams} from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import "./Contact.css";

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';



import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const FooterTypography = withStyles({
  root: {
    color: "#ccc"
  }
})(Typography);

export default function RegisterContact() {
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  //const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState("Message Sent");
  const [alertMessage, setAlertMessage] = useState("Thank you. Your message has been sent.");
  const [fields, handleFieldChange] = useFormFields({
    userid: location.state.userid,
    siteid: id,
    sitename: location.state.sitename,
    email: "",
    name: "",
    feedback: "",
    origin: "",
    type: "SITECLAIM"
  });



  function validateForm() {
    //console.log(fields);
    return fields.email.length > 5 && fields.email.indexOf("@")>0 && fields.feedback.length > 10 && fields.name.length > 3;
  }

  function handleAlertClose(event) {
    event.preventDefault();
    history.goBack();
  }

  async function handleButtonSubmit(event) {
    event.preventDefault();
   // console.log(location);
   // console.log(fields);
    //return;
    setIsLoading(true);
    try {
      const result = await createFeedback({ content: fields });
      if (result.status) {
        setAlertTitle("Message Sent");
        setAlertMessage("Thank you. Your message has been sent.");
      } else {
        setAlertTitle("Message Error");
        if (result.error) {
          setAlertMessage("Your message was not able to be sent. " + result.error);
        } else {
          setAlertMessage("Your message was not able to be sent.");
        }
      }
      setAlertOpen(true);
    } catch (e) {
      setAlertTitle("Message Error");
      setAlertMessage("Your message was not able to be sent. " + e.message);
    }
    setIsLoading(false);}

  async function handleSubmit(event) {
    event.preventDefault();
  }

  function createFeedback(feedback) {
    return API.post("aires", "/anon/feedback", {
      body: feedback
    });
  }


  return (
    <div className="Contact">
    <div className="lander">
    <Box display="flex" flexDirection="column" flexWrap="nowrap" my={0}  mx={2} alignItems='top'>
    <Box display="flex" flexWrap="wrap" mb={0}  mx={0} alignItems='top'> <h3>Request to claim {location.state.sitename}</h3>  </Box>
    <Box mx={0.5}>
    <p style={{color: "#10385A"}}>To claim this site please enter your details and a message describing your claim to the site and we’ll respond as soon as possible.<br/>Please ensure your email address is correct.</p>
    </Box>
    </Box>

    <Form onSubmit={handleSubmit} disabled={isLoading}>

      <Box my={2}>
      <TextField id="name"
      style={{width:"100%"}}
      value={fields.name}
      onChange={handleFieldChange}
      name="name"
      label="Name" type="text" variant="outlined" size="small"/>
      </Box>

      <Box my={2}>
      <TextField id="email"
      style={{width:"100%"}}
      value={fields.email}
      onChange={handleFieldChange}
      name="email"
      label="Email" type="email" variant="outlined" size="small"/>
      </Box>


      <Box pb={2}>
      <TextField id="feedback"
      multiline
      rows = {10}
      inputProps={{maxLength: 2000}}
      style={{width:"100%"}}
      value={fields.feedback}
      onChange={handleFieldChange}
      name="feedback"
      label="Message" type="text" variant="outlined" size="small"
      />
      </Box>


      <LoaderButtonX
      block
      size="lg"
      type="submit"
      isLoading={isLoading}
      onClick={handleButtonSubmit}
      disabled={!validateForm()}
      >
      Send My Message
      </LoaderButtonX>






<br/><br/>




      </Form>
      </div>

      <Dialog
        maxWidth='xs'
         open={alertOpen}
         onClose={handleAlertClose}
         aria-labelledby="alert-dialog-slide-title"
         aria-describedby="alert-dialog-slide-description">
         <DialogTitle id="alert-dialog-slide-title" style={{color:"#10385A"}} >{alertTitle}</DialogTitle>
         <DialogContent>
           <DialogContentText id="alert-dialog-slide-description">
               {alertMessage}
           </DialogContentText>
         </DialogContent>
         <DialogActions>
           <Button onClick={handleAlertClose} color="primary" >
             Ok
           </Button>
         </DialogActions>
       </Dialog>

      </div>



    );
  }
