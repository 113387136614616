import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { onError } from "../libs/errorLib";

import { API } from "aws-amplify";
import "./VisitorBook.css";
//import LoaderButton2 from "../components/LoaderButton2";
//import LoaderButton3 from "../components/LoaderButton3";

import LoaderButtonX from "../components/LoaderButtonX";


import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import { BsArrowRepeat } from "react-icons/bs";


import Table from "react-bootstrap/Table";

import { makeStyles, withStyles, styled } from '@material-ui/core/styles';


import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Button from '@material-ui/core/Button';
//import Button from "../components/LoaderButtonX";
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';

import ButtonBase from '@material-ui/core/ButtonBase';


import Fab from '@material-ui/core/Fab';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';



import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

//import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
//import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
//import Brightness3Icon from '@material-ui/icons/Brightness3';
//import InvertColorsIcon from '@material-ui/icons/InvertColors';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

//import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';

import iTopOvernightOff from '../images/preview/Pay Per Night - Grey.svg';
import iTopBaysOff from '../images/preview/Parking - Grey.svg';
import iTopFacilitiesOff from '../images/preview/Facilities Only - Grey.svg';

import iTopOvernight from '../images/preview/Pay Per Night - Blue.svg';
import iTopBays from '../images/preview/Parking - Blue.svg';
import iTopFacilities from '../images/preview/Facilities Only - Blue.svg';


import iRecyclingOff from '../images/facilities/Recycling - Grey.svg';
import iBlackWaterOff from '../images/facilities/Black Water Disposal - Grey.svg';
import iElectricOff from '../images/facilities/Electric Hook Up - Grey.svg';
import iGreyWaterOff from '../images/facilities/Grey Water Disposal - Grey.svg';
import iLaundryOff from '../images/facilities/Laundry - Grey.svg';
import iOvernightOff from '../images/facilities/Overnight Parking - Grey.svg';
import iRubbishOff from '../images/facilities/Rubbish - Grey.svg';
import iShowerOff from '../images/facilities/Shower - Grey.svg';
import iToiletOff from '../images/facilities/Toilet - Grey.svg';
import iWaterOff from '../images/facilities/Water - Grey.svg';
import iWifiOff from '../images/facilities/Wifi - Grey.svg';

import iRecycling from '../images/facilities/Recycling - Coral.svg';
import iBlackWater from '../images/facilities/Black Water Disposal - Coral.svg';
import iElectric from '../images/facilities/Electric Hook Up - Coral.svg';
import iGreyWater from '../images/facilities/Grey Water Disposal - Coral.svg';
import iLaundry from '../images/facilities/Laundry - Coral.svg';
import iOvernight from '../images/facilities/Overnight Parking - Coral.svg';
import iRubbish from '../images/facilities/Rubbish - Coral.svg';
import iShower from '../images/facilities/Shower - Coral.svg';
import iToilet from '../images/facilities/Toilet - Coral.svg';
import iWater from '../images/facilities/Water - Coral.svg';
import iWifi from '../images/facilities/Wifi - Coral.svg';



import iCampra from '../images/campra/Campra Accredited - Green.svg';

import defaultSiteImage from '../images/Default-Image-Road.jpg';
import spinner from '../images/Driving-SVG.gif';


import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';


import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import Container from '@material-ui/core/Container';


import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';


import Form from "react-bootstrap/Form";

import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline';

import parse from 'html-react-parser';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

import iLogo from "../images/logo.jpg";
import iQRLogo from "../images/qrcode_logo.svg";

import iCampraA from '../images/campra/Campra Accredited - Green.svg';
import iCampraX from '../images/campra/Campra Accredited - Grey.svg';


import QRCode from 'qrcode';
import { createCanvas, loadImage } from "node-canvas";

import '../fonts/Montserrat-Regular-normal.js';


//import Calendar from 'react-calendar';
//import 'react-calendar/dist/Calendar.css';

//import CalendarPicker from 'react-native-calendar-picker';

//import DatePicker from "react-multi-date-picker";
import { Calendar, DateObject } from "react-multi-date-picker"
import "react-multi-date-picker/styles/colors/xxx.css"
import "react-multi-date-picker/styles/colors/red.css"
import colors from "react-multi-date-picker/plugins/colors"

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Collapse from '@material-ui/core/Collapse';





import DownloadCSV from '../components/DownloadCSV';


//const data = "[{name:'John Doe',age:18,profession:'asfgad'},{name:'John Doe',age:18,profession:'asfgad'},{name:'John Doe',age:18,profession:'asfgad'}]";



//import CheckboxLabels from "../components/CheckboxLabels";
const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});
const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },

  },
})(TextField);

const BlueTooltip = withStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #10385A"
    },
    color: '#10385Acc'
  },
  tooltip: {
    backgroundColor: '#10385Add',
    color: '#ffffff',
    border: "1px solid #10385A",
    fontSize: 12,
    fontFamily: "Montserrat",
    fontWeight: 400,

  },
}))(Tooltip);


const useStyles = makeStyles((theme) => ({


  root: {
    '& > *': {
      margin: theme.spacing(1),
      background: "#000",
    },
  },
  imageList: {
    width: 500,
    height: 48,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  card: {
    margin: theme.spacing(2),
  },
  h4: {
    fontFamily: 'Comfortaa',
    color: '#10385A4'
  },
  h6: {
    color: '#F18C7E'
  },

  dialogContent: {
    width: '100%',
    backgroundColor: '#10385A',
    paddingTop: '80px',
    height: "100vh",
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  divcontrol: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  label: {
    display: "flex"
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    color: 'white'
  },
  pdf: { cursor: 'pointer', marginTop: '-0px', marginRight: '-8px', color: '#10385A4' },

  link: {
    color: "#F18C7E",
    "&:hover": {
      color: "#F18C7E",
      textDecoration: "underline #F18C7E"
    }
  },
  spreadBox: {
    justifyContent: "space-between"
  },

  textField: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "& .MuiOutlinedInput-input": {
      color: "red"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "red"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "red"
    },
    "& .MuiInputLabel-outlined": {
      color: "red"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "red"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "red"
    },


  }



}







));

const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  // Return the width so we can use it in our components
  return { width };
}


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Sites() {
  let classes = useStyles();

  const history = useHistory();
  //const { userHasAuthenticated, isAuthenticated, isCampra, setShowSites} = useAppContext();

  const [fields, handleFieldChange] = useFormFields({
    email: "",
    reg: "",
  });
  const [tncs, setTncs] = useState(false);

  const { isAuthenticated, isCampra } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isSwitching, setIsSwitching] = useState(false);
  const [switchingId, setSwitchingId] = useState(null);

  const [open, setOpen] = React.useState([]);
  const [paymentResult, setPaymentResult] = React.useState(false);
  const [sessionValues, setSessionValues] = React.useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [alertVals, handleAlertChange] = useFormFields({
    title: "",
    message: "",
  });



  const [currentSite, setCurrentSite] = useState(null);
  const [content, setContent] = useState("");
  const [siteOpen, setSiteOpen] = useState(false);
  //const [base64Image, setBase64Image] = useState("");
  //const [siteCode, setSiteCode] = useState("");
  //const [desc, setDesc] = useState("");
  //const [rules, setRules] = useState("");
  const [address, setAddress] = useState("");
  //const [accreditations, setAccreditations] = useState([]);


  const [sites, setSites] = useState([]);



  const [calendarVal, onCalendarVal] = useState([
    //new DateObject().setDay(5),
    ///new DateObject().setDay(12).color="red",
    //new DateObject().setDay(14).add(1, "month"),
    //new DateObject().setDay(23).add(1, "month"),

  ]);

  const [closedDatesChanged, setClosedDatesChanged] = React.useState([]);
  const [calendarExpanded, setCalendarExpanded] = React.useState([]);
  const handleExpandCalendarClick = (idx) => {
    const curr = calendarExpanded[idx];
    let newArr = new Array(calendarExpanded.length).fill(false);
    newArr[idx] = !curr;
    setCalendarExpanded(newArr);
  };


  const [forceUpdate, setForceUpdate] = React.useState(false);

  const [dateRange, setDateRange] = useState([
    new DateObject()
  ])

  const { width } = useViewport();
  const breakpoint = 600;

  useEffect(() => {

    onLoad();
  }, []);






  const [tabSite, setTabSite] = useState(0);






  /*
  function getDayRange() {
    var d = new Date();
    d.setHours(0,0,0,0);
    d.setHours(23,59,59,999);
    var t1 = d.getTime();
    return {start: t1, end: t1 + 86400000};
  }
  */

  function getOpenStatus(status, dates) {
    if (status < 2) {
      return status === 1;
    } else if (status == 2) {
      if (!dates) {
        return true;
      }
      const d = new Date();
      d.setHours(12, 0, 0, 0);
      const dt = d.getTime();
      var found = dates.find(function (element) {
        //console.log(element == dt);
        return element == dt;
      });
      //console.log(dt);
      //console.log(dates);
      //console.log(found!==dt);
      return found !== dt;
    } else {
      return false;
    }
  }

  function getTransactions() {
    return API.post("aires", `/tran`, {
      body: { all: true }
    });
  }

  function getTransactionsByAdminForSite(siteId) {
    return API.post("aires", `/tranadmin`, {
      body: { siteId: siteId }
    });
  }

  function getTransactionsByAdminForDates(start, end) {
    return API.post("aires", `/tranadmin`, {
      body: { start: start, end: end }
    });
  }
  function getSitesByAdmin() {
    //("getSitesByAdmin");
    return API.post("aires", `/tranadmin`, {
      body: { sitesonly: true }
    });
  }

  function getSites() {
    return API.get("aires", `/sites`);
  }
  /*
  function getSitesByAdmin() {
    return API.get("aires", `/sitesadmin`);
  }
*/
  async function onLoad() {
    if (!isAuthenticated) {history.push("/"); return;}

  }

  function validateForm() {
    return dateRange.length > 1;
  }

async function processCsvSiteList() {
  const datestr = (new Date(Date.now())).toLocaleString();
  const optionsShort = {year: '2-digit', month: '2-digit', day: '2-digit' };
  const result = await getSitesByAdmin();


  const hosts = result.data.hosts;


  const sites = result.data.sites.sort(
           //return a.itemStatus<=b.itemStatus;
           function(a, b){return a.itemStatus - b.itemStatus}
       );

       

       sites.forEach(site=>{
        for (let i=0;i<hosts.length;i++) {
          if (hosts[i].pk==site.pk) {
            site["host"] = hosts[i];
            break;
          }
        }
       })
      
       
       
       
       //console.log(sites);
       let dataForCSV = [];
       dataForCSV.push({isAireStop: "Airestop", status:"Status",siteName: "Site name", siteCode:"Site code", bays: "Pitches", priceStay: "Price Stay", priceFacilities: "Price Facilities", facilities: "Facilities", hostName:"Host name", email:"Email", phone:"Phone", createdDate: "Created on"});
       sites.forEach(site=>{
        const date = new Date(site.host.createdAt);
        let facilities = [];
        const keys = Object.keys(site.content.facilities);
        keys.forEach(key=>{
          if (site.content.facilities[key]==true) {
            facilities.push(key);
          }
          });
 

        if (site.itemStatus==8) {
          dataForCSV.push({isAireStop: "false", status:"non-airestop",siteName: "\"" + site.content.siteName + "\"" , siteCode:site.itemCode, bays: site.content.bays, priceStay: site.content.priceStay, priceFacilities: site.content.priceFacilities, facilities: "\"" + facilities + "\"", hostName:"n/a", email:"n/a", phone:"n/a", createdDate: "n/a"});
        } else {
          dataForCSV.push({isAireStop: "true", status:site.itemStatus==0?"closed":(site.itemStatus==1?"open":(site.itemStatus==2?"calendar":(site.itemStatus==9?"defunct":"unknown"))),siteName: "\"" + site.content.siteName + "\"" , siteCode:site.itemCode, bays: site.content.bays, priceStay: site.content.priceStay, priceFacilities: site.content.priceFacilities, facilities: "\"" + facilities + "\"", hostName:site.host.userName, email:site.host.email, phone:site.host.phone, createdDate: date.toLocaleDateString(undefined, optionsShort)});
        }
        
       });
       downloadCSV(dataForCSV, "Sites_" + datestr);
}
  
  async function handleButtonSubmit(event, format) {
    event.preventDefault();
     setIsLoading(true);
     const startDate = dateRange[0].toDate();
     startDate.setHours(0,0,0,0);
     const endDate = dateRange[1].toDate();
     endDate.setHours(23,59,59,999);
     let dataForCSV = [];
     let dataForHostCSV = [];

     if (format=="sitelist") {
        
        processCsvSiteList();
        
        setIsLoading(false);
      return;
     }


     const trans = await getTransactionsByAdminForDates(startDate.getTime(), endDate.getTime());
     //console.log(trans);

     
     if (trans.status && trans.data.hosts && trans.data.trans.length > 0) {
       
       const today = new Date();
       today.setHours(12, 0, 0, 0);
       const t1 = today.getTime();
       const t2 = t1 + (24 * 60 * 60 * 1000);
       const twoHours = 2 * 60 * 60 * 1000;
       

       const sortedHosts = trans.data.hosts.sort((a, b) => {
           return a.userName.localeCompare(b.userName);
       });
       //console.log(trans);




       const r = 1;//size==0?1:1.8;//2.125984;
       //const qrd = size==0?300:526;
       //const qroffset = size==0?185:322;
       const datestr = (new Date(Date.now())).toLocaleString();
       //const fileName = "AireStop_" + site.itemCode + "_" + site.content.siteName.replace(/\s/g, '_') + "_" + datestr + ".pdf";
       const fileName = "AireStop_Transactions_" + datestr + ".pdf";
       //const canvas = createCanvas(qrd, qrd);
       //const data = 'https://airestop.co.uk/sites/' + site.itemCode;
       //const img = await loadImage(iQRLogo);
       var doc;
   
       doc = new jsPDF('p', 'px', 'a4');
      doc.setFont('Montserrat-Regular');
       doc.setTextColor(16, 56, 90);
       doc.setCharSpace(6);
   
       //console.log(doc.getFontList());
/*
       const ctx = canvas.getContext("2d");
       const w = 50*2*r;
       const h = 50*2*r;//42.801556*2;
       const ctrex = ((qrd)-w) / 2;
       const ctrey = ((qrd)-h) / 2;
       ctx.drawImage(img, ctrex, ctrey, w, h);
       const b64qrcode = canvas.toDataURL("image/png");


       iii.src = b64qrcode;
*/

const options = {year: 'numeric', month: 'short', day: 'numeric' };
const optionsShort = {year: '2-digit', month: '2-digit', day: '2-digit' };


//console.log(startDate.toLocaleDateString(undefined, options));
const dateRangeStr = startDate.toLocaleDateString(undefined, options) + " - " + endDate.toLocaleDateString(undefined, options);

       doc.setProperties({
         title: 'AireStop Limited',
         subject: "Transactions: " + dateRangeStr,
         author: 'AireStop Limited',
         keywords: '',
         creator: 'AireStop Limited'
       });

       //let pr = doc.getImageProperties(iLogo);
       //const rat = pr.height/pr.width;
       //doc.setFontSize(70*r);
       //let tr = doc.getTextWidth(site.content.siteName);
       let docw = doc.internal.pageSize.width;
       let doch = doc.internal.pageSize.height;
       let middle = doch/2;
       let margin = 60;
       let margin2 = 30;
       let ypos = margin;
       //let boxY = 0;
       //let fh = 115*r;
       doc.setFillColor(255,255,255);
       doc.setCharSpace(0);
       //doc.rect(0, 0, docw, doch, 'F');
       //const sdate = (new Date(Date.now())).toLocaleString();
       //const edate = (new Date(Date.now())).toLocaleString();


      //####################################################################
      //
      //    FRONT PAGE
      //
      //####################################################################
      
      ypos = middle-40;
      doc.setFontSize(24);
      let tr = doc.getTextWidth("AireStop Limited");
      doc.text("AireStop Limited", ((docw-tr)/2), ypos,{});
      //doc.line(((docw-tr)/2)-22, doch-(23*r)-40, ((docw-tr)/2) + tr + 22, doch-(23*r)-40);
      ypos+=20;
      doc.setFontSize(16);
      tr = doc.getTextWidth("GUEST TRANSACTIONS");
      doc.text("GUEST TRANSACTIONS", ((docw-tr)/2), ypos);

      ypos+=16;
      doc.setFontSize(10);
      tr = doc.getTextWidth(dateRangeStr);
      doc.text(dateRangeStr, ((docw-tr)/2), ypos);


      


      
      
      //####################################################################
      //
      //    CONTENT
      //
      //####################################################################
      doc.setFont('helvetica','bold');
      



      dataForHostCSV.push({name:"Host name", email:"Email", phone:"Phone"});//, sites:"Sites"});
      dataForCSV.push({siteName:"Site Name",siteId:"Site Id", name:"Host name", hostemail:"Host Email", tranId:"Tran Id", tranDate:"Tran Date", bookingStatus: "Status", tranType:"Tran Type", dates:"Dates", fee:"Fee", campra:"Campra", marketing:"Marketing", reg:"Vehicle Reg", email:"Guest Email"});
       sortedHosts.forEach(host => {
        
        let hostTrans = trans.data.trans.filter((trn) => trn.pk === host.pk);
        //console.log(hostTrans.length);
        let hostsites=[];
        
        if (hostTrans.length > 0) {
          doc.setFontSize(12);
          ypos = margin2;
          doc.addPage("p");
          let line = "HOST: " + host.userName + " (" + host.email + ")";
          tr = doc.getTextWidth(line);
          doc.text(line, margin2, ypos);
          ypos+=4;
          let siteCode = "";
          let siteName = "Error - Not Found!";
          //trans.data.trans.filter((trn) => trn.pk === host.pk).map(tran => {


            //console.log(trans.data.sites);
            //console.log(host);

            //const hostSites = trans.data.sites.filter((sit) => sit.itemId == host.pk);
            //console.log(hostSites);


            hostTrans.map(tran => {
              if (siteCode == "" || siteCode != tran.content.metadata.siteCode) {
                //console.log(trans.data.sites);
                //console.log(tran.content.metadata.siteCode);
                //console.log(tran.content.metadata.siteCode);
                let site = trans.data.sites.filter((sit) => sit.itemCode === tran.content.metadata.siteCode);
                
                //console.log(site);
                if (site.length > 0 && site[0].content.siteName) {
                  siteName = site[0].content.siteName;
                }
                //console.log(siteName);
                //hostsites.push(`"${siteName.replace(/,/g, " - ")}"`);
                hostsites.push(siteName.replace(/,/g, " - "));
                siteCode = tran.content.metadata.siteCode;
                const siteStr = siteName + " (" + siteCode + ")";
              ypos+=10;
              doc.setFont('helvetica',"italic");
              doc.setFontSize(10);
                doc.text(siteStr, margin2, ypos); 
              
              ypos+=15;
              doc.setFont('helvetica',"normal");
              doc.setFontSize(9);
    
              doc.text("tran id", margin2, ypos); 
              doc.text("tran date", margin2+45, ypos); 
              doc.text("type", margin2+80, ypos); 
              doc.text("when", margin2+115, ypos); 
              doc.text("fee", margin2+140, ypos); 
              doc.text("campra", margin2+160, ypos); 
              doc.text("mkt", margin2+190, ypos); 
              doc.text("reg", margin2+210, ypos); 
              doc.text("email", margin2+250, ypos); 
              ypos+=3;
              doc.line(margin2, ypos, docw - margin2, ypos);
            }
            let nightCount = 0;
            if (tran.content.metadata.version==2 && tran.content.metadata.when==3 && tran.content.metadata.dates && tran.content.metadata.dates!="") {
              let datesArr = tran.content.metadata.dates.split(",");
              nightCount = datesArr.length;
            }
            const tdate = (new Date(tran.createdAt)).toLocaleDateString(undefined, optionsShort);
            const when = tran.content.metadata.when==0?"now":tran.content.metadata.when==1?"next":tran.content.metadata.when==2?"prev":tran.content.metadata.when==3?nightCount+"n":"err";
            let don = 0;
            if (tran.content.metadata.campraDonation) {
              don = parseInt(tran.content.metadata.campraDonation);
            }
           const fee = tran.fee - don;

            const lCampra = tr = doc.getTextWidth("campra");
            const lFeeStr = tr = doc.getTextWidth("fee");
            const lfee = tr = doc.getTextWidth(fee.toString());
            const ldon = tr = doc.getTextWidth(don.toString());
            
            ypos+=10;
            //console.log(tran);
            doc.text(tran.GSI1SK, margin2, ypos);
            doc.text(tdate, margin2+45, ypos); 
            doc.text(tran.content.metadata.payType, margin2+80, ypos); 
            doc.text(when, margin2+115, ypos); 
            doc.text(fee.toString(), margin2+140+lFeeStr-lfee, ypos); 
            doc.text(don.toString(), margin2+160+lCampra-ldon, ypos); 
            doc.text(tran.marketing==0?"no":"yes", margin2+190, ypos); 
            doc.text(tran.vreg, margin2+210, ypos); 
            doc.text(tran.email, margin2+250, ypos); 

            let dates = when;
            if (tran.content.metadata.dates) {
              const dateArr = tran.content.metadata.dates.split(",");
              dates = dateArr[0];
              if (dateArr.length>1) {
                dates += " - " + dateArr[dateArr.length-1];
              }

            }
             let status = "unknown";
             switch (tran.itemStatus) {
              case 0:
                status = "not completed";
                break;
                case 1:
                  status = "guest tran (stripe) successfull";
                  break;
                  case 2:
                    status = "host tran (non-stripe) successfull";
                    break;
                    case 91:
                      status = "guest tran cancelled (stripe refund)";
                      break;
                      case 92:
                        status = "host tran cancelled (non-stripe)";
                        break;
                    
             }

            //dataForCSV.push({siteName:`"${siteName}"`,siteId:siteCode, name:host.userName, hostemail:host.email, tranId:tran.GSI1SK, tranDate:tdate, bookingStatus: tran.itemStatus==2?"yes":"no", tranType:tran.content.metadata.payType, dates:tran.content.metadata.dates?`"${tran.content.metadata.dates}"`:when, fee:fee.toString(), campra:don.toString(), marketing:tran.marketing==0?"no":"yes", reg:tran.vreg, email:tran.email});
            dataForCSV.push({siteName:`"${siteName}"`,siteId:siteCode, name:host.userName, hostemail:host.email, tranId:tran.GSI1SK, tranDate:tdate, bookingStatus: status, tranType:tran.content.metadata.payType, dates: dates, fee:fee.toString(), campra:don.toString(), marketing:tran.marketing==0?"no":"yes", reg:tran.vreg, email:tran.email});


            if (ypos>doch-margin2-10) {
              doc.addPage("p");
              ypos=margin2;
            }
          })
          ypos+=5;
          //doc.line(margin2, ypos, docw - margin2, ypos);
          ypos+=10;
        }

        dataForHostCSV.push({name:host.userName, email:host.email, phone:host.phone});//, sites: `${hostsites.join(" + ")}`});

       });

//console.log(dataForCSV);

       

       /*
       //doc.addImage(iLogo, "JPG", margin, ypos+10, docw-(margin*2),((docw-(ypos*2))*rat)-15);
       ypos = ypos + (docw-(ypos*2))*rat + 15;
       doc.setDrawColor(16, 56, 90);
       doc.setLineWidth(size==0?2:4);
       doc.setFillColor(0);
       //doc.rect((margin/2), ypos, docw-(margin), 200, 'D');
       doc.rect((margin/2), ypos, docw-(margin), doch-(35*r*2)-40-ypos, 'D');
       let aaa = doch-(35*r*2)-40-ypos;
       boxY = ypos;
       doc.addImage(b64qrcode, "PNG", docw-(margin/2)-qroffset, ypos+(14*r));
       doc.setFontSize(70*r);
       ypos += (16 + 35)*r;
       doc.text("PAY HERE", (margin/2) + (12*r), ypos);
       doc.setFontSize(22*r);
       ypos += (10 + 16)*r;
       doc.text("WWW.AIRESTOP.CO.UK", (margin/2) + (16*r), ypos);
       doc.setFontSize(30*r);
       doc.text("SITE CODE", (margin/2) + (16*r), doch-(35*r*2)-40-((16+50)*r));
       doc.setFontSize(70*r);
       doc.text(site.itemCode, (margin/2) + (12*r), doch-(35*r*2)-40-(16*r));
       doc.setFontSize(30*r);
       tr = doc.getTextWidth(site.content.siteName.toUpperCase());
       doc.text(site.content.siteName.toUpperCase(), ((docw-tr)/2)-6, doch-(35*r)-40);
       doc.setLineWidth(size==0?1:2);
       doc.setFontSize(22*r);
       */
      

       /*
       let tr = doc.getTextWidth("ENJOY YOUR VISIT - LEAVE NO TRACE");
       doc.text("ENJOY YOUR VISIT - LEAVE NO TRACE", ((docw-tr)/2)-22, doch-40);
       doc.line(((docw-tr)/2)-22, doch-(23*r)-40, ((docw-tr)/2) + tr + 22, doch-(23*r)-40);
       */

       if (format=="pdf") {
        doc.save(fileName);
       } else if (format=="csv") {
        downloadCSV(dataForCSV, "AireStopTransactions_" + datestr);
       } else if (format=="hostcsv") {
        downloadCSV(dataForHostCSV, "HostDetails_" + datestr);
       }
       








    
      } else {
        alert("There are no transactions for this date range.")
      }
      setIsLoading(false);
  }


  const OpenClosedSwitch = withStyles({
    switchBase: {
      color: red[700],
      '&$checked': {
        color: green[500],
      },
      '&$checked + $track': {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);


  function handleAlertClose() {
    setAlertOpen(false);
    setDeleteAlertOpen(false);
  };


  function handleNewSiteClick() {
    history.push("/sites/create/new");
  }

  function handleEditSiteClick(site) {
    history.push("/sites/" + site.itemCode + "/edit");
  }



  /*
  
  {site.trans?
  <Box pb={1}>
  <Link component="button" disabled={site.trans.overnight.length==0} onClick={() => { handleOvernightTransClick(site) }}>Overnight: {site.trans.overnight.length}</Link> &nbsp;&nbsp;&nbsp;&nbsp;
  <Link component="button" disabled={site.trans.facilities.length==0} onClick={() => { handleFacilitiesTransClick(site) }}>Facilities: {site.trans.facilities.length}</Link>
  
    <Divider width="100%"/>
  </Box>
  :<></>}
  
  
  */



  function handleTransactionsClick(site) {
    alertVals.title = <>Todays Bookings<br /><span style={{ fontSize: "14px" }}>{site.content.siteName}</span></>;
    alertVals.message = <><b>Overnight Stays</b> <br />Today ({site.trans.overnight.length})<br />
      {site.trans.overnight.length > 0 ?

        <>Vehicle registration{site.trans.overnight.length > 1 ? "s" : ""}:<br />{
          site.trans.overnight.map((reg, index) => {
            return (
              <><i>{reg.toUpperCase()}</i><br /></>
            )
          })

        }
        </>
        :
        <></>

      }
      <br />
      Tomorrow ({site.trans.overnightNextDay.length})<br />
      {site.trans.overnightNextDay.length > 0 ?

        <>Vehicle registration{site.trans.overnightNextDay.length > 1 ? "s" : ""}:<br />{
          site.trans.overnightNextDay.map((reg, index) => {
            return (
              <><i>{reg.toUpperCase()}</i><br /></>
            )
          })

        }
        </>
        :
        <></>

      }
      <br />
      <b>Facilities Use</b> ({site.trans.facilities.length})<br />

      {site.trans.facilities.length > 0 ?

        <>Vehicle registration{site.trans.facilities.length > 1 ? "s" : ""}:<br />{
          site.trans.facilities.map((reg, index) => {
            return (
              <><i>{reg.toUpperCase()}</i><br /></>
            )
          })
        }
        </>
        :
        <></>


      }
    </>;
    setAlertOpen(true);
  }



  function handleOrderSiteClick(site) {
    history.push("/shop");
  }
  function handleDeleteSiteClick(site) {
    alertVals.title = "AireStop - Confirm Delete";
    alertVals.message = "Are you sure you wish to delete this AireStop. This cannot be undone and your customers will lose access to this site.";
    setCurrentSite(site);
    setDeleteAlertOpen(true);
  }

  async function handleSiteDelete(e) {
    e.preventDefault();
    setIsLoading(true);
    setDeleteAlertOpen(false);
    const newStatus = 9; //DELETED
    const result = await updateSiteStatus(currentSite.itemCode, newStatus);
    if (result.status) {
      setCurrentSite(null);
    }
    onLoad(); //refresh sites list
  }


  function onCalendarValChange(e) {
    //console.log(e);
    //console.log(dateRange);
    //console.log(idx);
    //calendarVal[idx] = e;
   // onCalendarVal(calendarVal);
    //determineClosedDatesChanged(idx);
  }
  /*
  function onCalendarValChange(idx, e) {
    //console.log(e);
    //console.log(idx);
    calendarVal[idx] = e;
    onCalendarVal(calendarVal);
    determineClosedDatesChanged(idx);
  }
  */

  function determineClosedDatesChanged(idx) {
    var oldDates = sites[idx].content.closedDates;
    var dates = [];
    calendarVal[idx].forEach(dateObj => {
      const ts = dateObj.valueOf();
      const dt = new Date(ts);
      dt.setHours(12, 0, 0, 0);
      dates.push(dt.getTime());
    });
    dates.sort();
    const aaa = dates.toString();
    var bbb = "";
    if (oldDates) {
      oldDates.sort();
      bbb = oldDates.toString();
    }
    //console.log(aaa);
    //console.log(bbb);
    closedDatesChanged[idx] = aaa !== bbb;
    setClosedDatesChanged(closedDatesChanged);
    setForceUpdate(!forceUpdate);
    //console.log(closedDatesChanged);
  }


  async function handleSaveClosedDatesClick(idx, site, e) {
    e.preventDefault();
    setSwitchingId("switching-id-" + idx);
    setIsSwitching(true);
    setForceUpdate(!forceUpdate);
    var dates = [];
    calendarVal[idx].forEach(dateObj => {
      const ts = dateObj.valueOf();
      const dt = new Date(ts);
      dt.setHours(12, 0, 0, 0);
      dates.push(dt.getTime());
    });
    const result = await updateSiteClosedDates(site.itemCode, dates);
    if (result.status) {
      site.content["closedDates"] = result.result.Attributes.content.closedDates;
      open[idx] = getOpenStatus(site.itemStatus, site.content.closedDates);
    }
    determineClosedDatesChanged(idx);
    setIsSwitching(false);
    setSwitchingId(null);
  }

  const handleStatusChange = async (event, idx) => {
    //console.log(event);
    event.preventDefault();
    setSwitchingId("switching-id-" + idx);
    setIsSwitching(true);
    const newStatus = event.target.value;
    const result = await updateSiteStatus(sites[idx].itemCode, newStatus);
    if (result.status) {
      sites[idx].itemStatus = result.result.Attributes.itemStatus;
    }
    open[idx] = getOpenStatus(sites[idx].itemStatus, sites[idx].content.closedDates);

    setOpen(open);
    setIsSwitching(false);
    setSwitchingId(null);
  };

  /*
  const handleSiteOpenChange = async (event) => {
    event.preventDefault();
    setSwitchingId(event.target.id);
    setIsSwitching(true);
    const newStatus = event.target.checked?1:0;
    const result = await updateSiteStatus(sites[event.target.id].itemCode, newStatus);
    if (result.status) {
      sites[event.target.id].itemStatus=result.result.Attributes.itemStatus;
      setSiteOpen(!siteOpen);
    }
    setIsSwitching(false);
    setSwitchingId(null);
  }
  */

  function updateSiteStatus(id, status) {
    return API.put("aires", `/sites/${id}`, {
      body: {
        type: 'STATUS',
        status: status
      }
    });
  }
  function updateSiteClosedDates(id, dates) {
    return API.put("aires", `/sites/${id}`, {
      body: {
        type: 'CLOSED_DATES',
        dates: dates
      }
    });
  }





  function TabContainer(props) {
    return (
      <Typography component="div" style={{ padding: 8 * 3 }}>
        {props.children}
      </Typography>
    );
  }

  function handleTabChange(event, value) {
    setTabSite(value);
  };

  const parentStyles = {
    // borderBottom: "3px solid gray",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  };

  const downloadCSV = (data, fileName) => {
    const csvData = new Blob([convertToCSV(data)], { type: 'text/csv' });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement('a');
    link.href = csvURL;
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
//console.log(array);
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ',';

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  };




  function renderLoader() {
    return (
      <div id="splash" style={{ paddingTop: 100, zIndex: 999 }}>

        <center id="splash">
          <img src={spinner} height="30px" width="auto" />
          {/*}<Typography style={{padding: '4px'}} variant="caption">{label}</Typography>*/}
        </center>
      </div>


    );

  }


  function renderLander() {
    return (
      <div style={isSwitching ? { pointerEvents: "none", opacity: "0.7" } : {}}>
        <Box display="flex" flexDirection="column" flexWrap="nowrap" my={0} mx={2} alignItems='top'>
          <Box display="flex" flexWrap="wrap" mb={0} mx={0} alignItems='top'> <h2 className={classes.h2}>Transactions Reporter</h2>  </Box>
          <Box mx={0.5}>

          <Box my={2} display="flex" width="100%"  justifyContent="center" alignItems='center' >
            {/*}
      <Calendar
        className="xxx"
        numberOfMonths={2}
        currentDate={new DateObject()}
        minDate={new Date()}
        value={calendarVal}
        multiple
        onChange={onCalendarValChange}/>
    */}

<Calendar
  numberOfMonths={3}
  maxDate={new Date() }
  value={dateRange}
  onChange={setDateRange}
  range
  rangeHover
/>
  </Box>




          </Box>
        </Box>

        <Box my={2}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"

                    >
                      <Button isLoading={isLoading} disabled={!validateForm()} align="center" variant="contained" color="secondary" onClick={(e)=>{handleButtonSubmit(e,"pdf")}}>Create PDF Report</Button>

                    </Box>

                    <Box my={2}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"

                    >
                      <Button isLoading={isLoading} disabled={!validateForm()} align="center" variant="contained" color="secondary" onClick={(e)=>{handleButtonSubmit(e,"csv")}}>Create CSV Report</Button>

                    {/*

                    </Box>
                    <Box my={2}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"

                    >
                      <Button isLoading={isLoading} disabled={!validateForm()} align="center" variant="contained" color="secondary" onClick={(e)=>{handleButtonSubmit(e,"hostcsv")}}>Create host List (CSV)</Button>

                   

                    </Box>
                    <Box my={2}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"

                    >
                      <Button isLoading={isLoading} disabled={!validateForm()} align="center" variant="contained" color="secondary" onClick={(e)=>{handleButtonSubmit(e,"sitelist")}}>Create site List (CSV)</Button>

                    */}

                    </Box>
       </div>
    );
  }

/*

  
  function renderSites() {

    return (
      <>
        <Box maxWidth="650px">
          <Tabs
            value={tabSite}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="false"
          >
            {sites.map((site, index) => {

              return (site.add ? <></> : <Tab label={site.content.siteName} />)
            })}
          </Tabs>
          {sites && sites.length > 1 && tabSite >= 0 && renderSiteDetails()}
        </Box>
      </>
    );
  }

  function renderSiteDetails() {
    return (
      <>
        <Paper elevation={0} >
          <Box px={1} py={4} maxWidth="650px">
            <Box display="flex" flexWrap="wrap" mb={0} mx={0} py={0} my={-1} alignItems='top'> <h4 className={classes.h2}>Today</h4>  </Box>
            <Box px={0} pb={3}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="left"
                alignItems="left"
                width="100%"
              >
                <Box p={0}>
                  <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs>
                      <Box py={1} ><b>Date</b></Box>
                    </Grid>
                    <Grid itemRight xs="auto">
                      <Box py={1}><b>Service</b></Box>
                    </Grid>
                    <Grid itemRight xs={3}>
                      <Box m={1} py={1} display="flex" justifyContent="right" alignItems="right"><b>Vehicle Reg.</b></Box>
                    </Grid>
                  </Grid>
                </Box>
                <Divider style={{ height: "2px" }} />

                {sites[tabSite].trans.today.map((trn, index) => {
                  return (
                    <>
                      <Box p={0} >
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                          <Grid item xs>
                            <Box py={1} >{new Date(Number(trn.date)).toLocaleDateString(undefined, { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-')} </Box>
                          </Grid>
                          <Grid itemRight xs="auto">
                            <Box py={1} >
                              <BlueTooltip arrow placement="left" enterTouchDelay={100} enterDelay={50} title={trn.type == "overnight" ? "Overnight Parking (including facilities use)" : trn.type == "facilities" ? "Facilities use only" : ""}><Box className="noselect" display="flex" flexWrap="wrap" my={0.3} mx={1} alignItems='center'><img src={trn.type == "overnight" ? iTopOvernight : trn.type == "facilities" ? iTopFacilities : ""} width='auto' height='28' /></Box></BlueTooltip>
                            </Box>
                          </Grid>
                          <Grid itemRight xs={3}>
                            <Box m={1} py={1} display="flex" justifyContent="right" alignItems="right">{trn.reg}</Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider style={{ height: "1px" }} />
                    </>
                  );
                })}
              </Box>
            </Box>
            <Box display="flex" flexWrap="wrap" mb={0} mx={0} py={0} my={-1} alignItems='top'> <h4 className={classes.h2}>Tomorrow</h4>  </Box>
            <Box px={0} pb={3}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="left"
                alignItems="left"
                width="100%"
              >
                <Box p={0}>
                  <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs>
                      <Box py={1} ><b>Date</b></Box>
                    </Grid>
                    <Grid itemRight xs="auto">
                      <Box py={1}><b>Service</b></Box>
                    </Grid>
                    <Grid itemRight xs={3}>
                      <Box m={1} py={1} display="flex" justifyContent="right" alignItems="right"><b>Vehicle Reg.</b></Box>
                    </Grid>
                  </Grid>
                </Box>
                <Divider style={{ height: "2px" }} />

                {sites[tabSite].trans.tomorrow.map((trn, index) => {
                  return (
                    <>
                      <Box p={0} >
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                          <Grid item xs>
                            <Box py={1} >{new Date(Number(trn.date)).toLocaleDateString(undefined, { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-')} </Box>
                          </Grid>
                          <Grid itemRight xs="auto">
                            <Box py={1} >
                              <BlueTooltip arrow placement="left" enterTouchDelay={100} enterDelay={50} title={trn.type == "overnight" ? "Overnight Parking (including facilities use)" : trn.type == "facilities" ? "Facilities use only" : ""}><Box className="noselect" display="flex" flexWrap="wrap" my={0.3} mx={0} alignItems='center'><img src={trn.type == "overnight" ? iTopOvernight : trn.type == "facilities" ? iTopFacilities : ""} width='auto' height='28' /></Box></BlueTooltip>
                            </Box>
                          </Grid>
                          <Grid itemRight xs={3}>
                            <Box m={1} py={1} display="flex" justifyContent="right" alignItems="right">{trn.reg}</Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider style={{ height: "1px" }} />
                    </>
                  );
                })}
              </Box>
            </Box>
            <Box display="flex" flexWrap="wrap" mb={0} mx={0} py={0} my={-1} alignItems='top'> <h4 className={classes.h2}>Previous</h4>  </Box>
            <Box px={0} pb={1}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="left"
                alignItems="left"
                width="100%"
              >
                <Box p={0}>
                  <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs>
                      <Box py={1} ><b>Date</b></Box>
                    </Grid>
                    <Grid itemRight xs="auto">
                      <Box py={1}><b>Service</b></Box>
                    </Grid>
                    <Grid itemRight xs={3}>
                      <Box m={1} py={1} display="flex" justifyContent="right" alignItems="right"><b>Vehicle Reg.</b></Box>
                    </Grid>
                  </Grid>
                </Box>
                <Divider style={{ height: "2px" }} />

                {sites[tabSite].trans.previous.map((trn, index) => {
                  return (
                    <>
                      <Box p={0} >
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                          <Grid item xs>
                            <Box py={1} >{new Date(Number(trn.date)).toLocaleDateString(undefined, { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-')} </Box>
                          </Grid>
                          <Grid itemRight xs="auto">
                            <Box py={1} >
                              <BlueTooltip arrow placement="left" enterTouchDelay={100} enterDelay={50} title={trn.type == "overnight" ? "Overnight Parking (including facilities use)" : trn.type == "facilities" ? "Facilities use only" : ""}><Box className="noselect" display="flex" flexWrap="wrap" my={0.3} mx={1.5} alignItems='center'><img src={trn.type == "overnight" ? iTopOvernight : trn.type == "facilities" ? iTopFacilities : ""} width='auto' height='28' /></Box></BlueTooltip>
                            </Box>
                          </Grid>
                          <Grid itemRight xs={3}>
                            <Box m={1} py={1} display="flex" justifyContent="right" alignItems="right">{trn.reg}</Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider style={{ height: "1px" }} />
                    </>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Paper>
      </>
    );

  }

  

  
    function renderSites() {
      return sites.map((site, index) => {
        return (
          <>
  
          <Box maxWidth="650px">
          <Card className={classes.card}>
            <CardContent>
              {site.add?renderAdd():renderSite(site, index)}
            </CardContent>
          </Card>
          </Box>
          </>
        );
      })
    }
  */

  return (

    <div className="Sites">
      {isLoading ? renderLoader() : renderLander()}
    </div>
  );
}