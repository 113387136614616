import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import Navbar from "react-bootstrap/Navbar";
import { AppContext } from "./libs/contextLib";
import { useHistory } from "react-router-dom";
import "./App.css";
import Routes from "./Routes";
import Nav from "react-bootstrap/Nav";
import { onError } from "./libs/errorLib";
import {  MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';


//import LogoutIcon from '@material-ui/icons/Logout';
import iLogo from "./images/logo_tm.png";
import pinIcon from'./images/pin.svg';


import { LinkContainer } from "react-router-bootstrap";


import styles from "./components/TopHeaderLrg.module.css";

const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat'
    ].join(','),
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#10385A',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {

      main: '#F18C7E',
      // dark: will be calculated from palette.secondary.main,
    },
    // error: will use the default color
  },
});



function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isCampra, userIsCampra] = useState(false);
  const [isAdmin, userIsAdmin] = useState(false);
  const [userName, setUserName] = useState("");
  const [showSites, setShowSites] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const history = useHistory();

  const [isMotoroamer, setIsMotoroamer] = useState(true);



  let listener = null

  

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    listener = document.addEventListener("scroll", e => {
      setExpanded(false);
    })
    return () => {
      document.removeEventListener("scroll", listener)
    }
  }, [])




  async function onLoad() {
    try {
      await Auth.currentSession();
      const cui = await Auth.currentUserInfo();
      setUserName(cui.attributes['custom:name']);
      userIsCampra(cui.attributes['custom:name']==="CAMpRA" && cui.attributes.email.length==21 && cui.attributes.email.includes("pra@aires"));
      userIsAdmin(cui.attributes['custom:name']==="Jamie" && cui.attributes.email.length==22 && cui.attributes.email.includes("pratt@gmai"));
      //console.log(cui.attributes);
      userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        onError(e);
      }
    }

    setIsAuthenticating(false);
  }


  const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
      const handleWindowResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    // Return the width so we can use it in our components
    return { width };
  }

  const { width } = useViewport();
  const breakpoint = 768;

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    userIsCampra(false);
    history.push("/");
  }
  function handleAccount(event) {
    event.preventDefault();
    history.push("/account");
    setExpanded(false);
  }
  function handleHub(event) {
    event.preventDefault();
    history.push("/");
    setExpanded(false);
  }
  function handleManager(event) {
    event.preventDefault();
    history.push("/admin/manager");
    setExpanded(false);
  }
  function handleAbout(event) {
    event.preventDefault();
    history.push("/about");
    setExpanded(false);
  }
  function handleLogin(event) {
    event.preventDefault();
    history.push("/login");
    setExpanded(false);

  }
  function handleSignup(event) {
    event.preventDefault();
    setIsMotoroamer(false);
    history.push("/host");
    setExpanded(false);
  }



  function handleThinking(event) {
    event.preventDefault();
    history.push("/hosting");
    setExpanded(false);
  }
  function handleHome(event) {
    event.preventDefault();
    setIsMotoroamer(true);
    history.push("/");
    setExpanded(false);
  }
  return (
    !isAuthenticating && (
      <MuiThemeProvider theme={theme}>
       <CssBaseline/>
      <div className={width>breakpoint?"container":"mobile-container"} >
        <Navbar  collapseOnSelect  expanded={expanded}  expand="md" className="mb-3" style={{left:0, paddingLeft: 78, paddingRight: 75, paddingBottom: 6, paddingTop: 8, borderBottom: '4px solid #10385A', background: 'white'}}>
          <LinkContainer to="/">
            <Navbar.Brand  onClick={() => setExpanded(expanded ? false : "")}>
         <div className={styles.logoRegmarkLarge}>
          <img
            className={styles.logoIcon}
            loading="lazy"
            alt=""
            src="/logo.svg"
          />
        </div>

            </Navbar.Brand>


          </LinkContainer>

          <Navbar.Toggle style={width>breakpoint?{}:{marginRight: 16}}   onClick={() => setExpanded(expanded ? false : "expanded")} onBlur={() => setExpanded(false)}/>
          <Navbar.Collapse className="justify-content-end" >
              {isAuthenticated ?
                (isCampra ?
                <Nav activeKey={window.location.pathname} onClick={() => setExpanded(false)} onBlur={() => setExpanded(false)}>
                <Nav.Link className={width>breakpoint?"":"ml-auto"} style={width>breakpoint?{}:{marginLeft: 16, marginRight: 22}} onMouseDown={handleAccount} onBlur={() => setExpanded(false)}>
                <Box display="flex" justifyContent="center" alignItems="center" flexWrap="nowrap" whiteSpace="nowrap">
                <span className="material-icons-round" style={{ color: "#555", fontSize: "16px"}}>
                  account_circle
                </span>
                &nbsp;{userName}
                </Box>
                </Nav.Link>
                <Nav.Link className={width>breakpoint?"":"ml-auto"} style={width>breakpoint?{}:{marginLeft: 16, marginRight: 22}} onMouseDown={handleLogout}>
                <Box display="flex" justifyContent="center" alignItems="center" flexWrap="nowrap" whiteSpace="nowrap">
                &nbsp;Logout
                </Box>
                </Nav.Link>
                </Nav>
               :
                <Nav activeKey={window.location.pathname} onClick={() => setExpanded(false)} onBlur={() => setExpanded(false)}>
                <Nav.Link className={width>breakpoint?"":"ml-auto"} style={width>breakpoint?{}:{marginLeft: 16, marginRight: 22}} onMouseDown={handleAccount} onBlur={() => setExpanded(false)}>
                <Box display="flex" justifyContent="center" alignItems="center" flexWrap="nowrap" whiteSpace="nowrap">
                <span className="material-icons-round" style={{ color: "#555", fontSize: "16px"}}>
                  account_circle
                </span>
                &nbsp;{userName}
                </Box>
                </Nav.Link>
                {isAdmin ? 
                  <Nav.Link className={width>breakpoint?"":"ml-auto"} style={width>breakpoint?{}:{marginLeft: 16, marginRight: 22}} onMouseDown={handleManager}>
                <Box display="flex" justifyContent="center" alignItems="center" flexWrap="nowrap" whiteSpace="nowrap">
                &nbsp;MANAGER
                </Box>
                </Nav.Link>
                :<></>
}
                <Nav.Link className={width>breakpoint?"":"ml-auto"} style={width>breakpoint?{}:{marginLeft: 16, marginRight: 22}} onMouseDown={handleHub}>
                <Box display="flex" justifyContent="center" alignItems="center" flexWrap="nowrap" whiteSpace="nowrap">
                &nbsp;Host Hub
                </Box>
                </Nav.Link>
                <Nav.Link className={width>breakpoint?"":"ml-auto"} style={width>breakpoint?{}:{marginLeft: 16, marginRight: 22}} onMouseDown={handleLogout}>
                <Box display="flex" justifyContent="center" alignItems="center" flexWrap="nowrap" whiteSpace="nowrap">
                &nbsp;Logout
                </Box>
                </Nav.Link>
                </Nav>

              ) : (
                <Nav  activeKey={window.location.pathname} onClick={() => setExpanded(false)} onBlur={() => setExpanded(false)}>
                  {/*}
                  <Nav.Link className={width>breakpoint?"":"ml-auto"} style={width>breakpoint?{}:{marginLeft: 16, marginRight: 22}} onMouseDown={handleAbout} onBlur={() => setExpanded(false)}>
                  <Box display="flex" justifyContent="center" alignItems="center" flexWrap="nowrap" whiteSpace="nowrap">
                  &nbsp;About
                  </Box>
                  </Nav.Link>
                  <Nav.Link className={width>breakpoint?"":"ml-auto"} style={width>breakpoint?{}:{marginLeft: 16, marginRight: 22}} onMouseDown={handleThinking}>
                  <Box display="flex" justifyContent="center" alignItems="center" flexWrap="nowrap" whiteSpace="nowrap">
                  &nbsp;Thinking of Hosting?
                  </Box>
                  </Nav.Link>
                  */}
                  <Nav.Link className={width>breakpoint?"":"ml-auto"} style={width>breakpoint?{}:{marginLeft: 16, marginRight: 22}} onMouseDown={handleHome}>
                  <Box display="flex" justifyContent="center" alignItems="center" flexWrap="nowrap" whiteSpace="nowrap">
                    {isMotoroamer?<span className={styles.aboutUs} style={{color:"#000", fontSize:"13px"}}  >I'm a Motoroamer</span>:<span className={styles.aboutUs} style={{fontSize:"13px"}}  >I'm a Motoroamer</span>}
                  
      
                  </Box>
                  </Nav.Link>
                  <Nav.Link className={width>breakpoint?"":"ml-auto"} style={width>breakpoint?{}:{marginLeft: 16, marginRight: 22}} onMouseDown={handleSignup}>
                  <Box display="flex" justifyContent="center" alignItems="center" flexWrap="nowrap" whiteSpace="nowrap">
                  
                  {isMotoroamer?<span className={styles.aboutUs} style={{fontSize:"13px"}}  >I'm a Host</span>:<span className={styles.aboutUs} style={{color:"#000", fontSize:"13px"}}  >I'm a Host</span>}
          
                  </Box>
                  </Nav.Link>
                 {/* <Nav.Link className={width>breakpoint?"":"ml-auto"} style={width>breakpoint?{}:{marginLeft: 16, marginRight: 22}} onMouseDown={handleLogin}>
                  <Box display="flex" justifyContent="center" alignItems="center" flexWrap="nowrap" whiteSpace="nowrap">
                  <div className={styles.userTypeLabel1}>
        I’m a Host
          </div>

                  </Box>
                  </Nav.Link>*/}
                </Nav>
              )}

          </Navbar.Collapse>
        </Navbar>

        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, isCampra, userIsCampra, showSites, setShowSites, userName, setUserName, isAdmin, userIsAdmin}}>
          <Routes />
        </AppContext.Provider>
      </div>
      </MuiThemeProvider>
    )
  );
}

export default App;
