import PropTypes from "prop-types";
import styles from "./TestimonialSectionGuests.module.css";

const TestimonialSectionGuests = ({ className = "", feedback }) => {
  const images=["/vector.svg","/vector-6.svg","/vector-12.svg","/vector-18.svg"]

  function rendeFeedback() {
    return feedback.map((testamonial, index) => {
      let key = "feedback-"+index;
      let imageId = index%4;
      return (
        <div key={key}>
          <div className={styles.cardtestimonialCard}>
            <div className={styles.graphicGroup}>
              <div className={styles.testimonialImages} />
              <div className={styles.usericons}>
                <img 
                  className={styles.testimonialUserAvatars}
                  loading="lazy"
                  alt=""
                  src={images[imageId]}
                />
              </div>
            </div>
            <div className={styles.theBigOxmox}>
              {testamonial.text}
            </div>
            <div className={styles.vectorParent}>
              <img className={styles.vectorIcon} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon1} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon2} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon3} alt="" src="/vector-1.svg" />
              <img className={styles.vectorIcon4} alt="" src="/vector-1.svg" />
            </div>
            <div className={styles.testimonialUserInfo}>
              <div className={styles.name}>{testamonial.name}</div>
              <div className={styles.testimonialUserTypes}>
              <div className={styles.guestHost}>{testamonial.type}</div>
              </div>
            </div>
          </div>
        </div>
      );
    })
  }

  return (
    <section className={[styles.testimonialsectionGuests, className].join(" ")}>
      <div className={styles.content}>
        <div className={styles.titletext}>
          <h1 className={styles.whatOurGuests}>What Our Guests Are Saying</h1>
        </div>
        <div className={styles.cardwrap}>
          {rendeFeedback()}
        </div>
      </div>
    </section>
  );
};

TestimonialSectionGuests.propTypes = {
  className: PropTypes.string,
};

export default TestimonialSectionGuests;
