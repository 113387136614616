import React, { useState, useEffect } from 'react';
import './BookingSummary.css';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Paper } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { green } from '@material-ui/core/colors';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


import InfoIcon from '@material-ui/icons/HelpOutline';
import Link from '@material-ui/core/Link';

import LoaderButtonX from "../components/LoaderButtonX";







const BookingSummary = ({ bookingData , setBookingData, bookingState, setBookingState, setNewsInfo, isPaying, tncs, setTncs, news, setNews, content, payType, pitchfeatures, handleStripePayClick}) => {



  //console.log(bookingData);
  //console.log(content);


  //const [donation, setDonation] = useState(0);
  
  
  




  const handleNewsChange = (event) => {
    setNews(event.target.checked);
  }
  const handleTncsChange = (event) => {
    setTncs(event.target.checked);
  }
  const tandcs = (event) => {
    //event.preventDefault();
    window.open("https://airestop.co.uk/docs/tncs.pdf");
  };



  const handlePayment = () => {
    // Implement payment logic here
    /*
    console.log('Processing payment...');
    console.log(bookingData);
    
    
    var ddd = new Date(bookingData.selectedDates[0]);
    console.log(ddd);
    console.log(ddd.toISOString().split('T')[0]);
    */



    setBookingData({...bookingData, totalAmount: getNetPrice()});
    
    handleStripePayClick();
  };

  const handleBack = () => {
    setBookingState({current:"userdetails", prev:bookingState.prev});
  };
  function checkin() {
    return new Date(bookingData.selectedDates[0]);
  }
  function checkout() {
    let checkoutDate = new Date(bookingData.selectedDates[bookingData.selectedDates.length-1]);
    checkoutDate.setDate(checkoutDate.getDate() + 1);
    return checkoutDate;
  }


  //console.log(bookingData);
    function getNetPrice() {
      let total = 0;
      if (bookingData.pitch) {
        total = Number(bookingData.pitch.price)*bookingData.selectedDates.length;
        //bookingData.selectedPitches.forEach(pitch => {
        //  total+=Number(pitch.price);
        //});
      } else {
        total = Number(content.priceStay)*bookingData.selectedDates.length;
      }
      return total;
  }


  const handleDonationChange = (val) => {
    const donation = bookingData.donation+val;
    if (donation>=0) {
      setBookingData({ ...bookingData, donation });
      //setDonation(total);
    }
  };

  function currencyFormat(num) {
    return '£' + Number.parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }


  return (
    <Paper variant="outlined" >
    <div className="booking-summary">
             <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 0,
          m: 0,
          borderRadius: 1,
          alignContent: 'center',
        }}
      >
        {bookingData.payType=='overnight'?
        <Box><h4>Booking Summary</h4></Box>
        :
        <Box><h4>Payment Summary</h4></Box>
}
        <Box  mt={-0.4}>
        <Button
         size="small"
        color="primary"
        onClick={handleBack}
        startIcon={<ArrowBackIcon />}
      >Back
      </Button>
      </Box>
      </Box>
 

      {payType === 'overnight' ?
<div className="dates">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 0,
          m: 0,
          borderRadius: 1,
          alignContent: 'center',
        }}
      >
             
             <div className="infobox">
              <Box mb={1}>check-in</Box>
              <h6>{checkin().getDate()}<br></br>{checkin().toLocaleDateString('en-US', { month: 'long' })}</h6> 
                    </div>
                    


  <div className="infobox">
  <Box mb={1}>check-out</Box>
              <h6>{checkout().getDate()}<br></br>{checkout().toLocaleDateString('en-US', { month: 'long' })}</h6> 
                    </div>

                    {/*currentDate.getFullYear(), currentDate.getMonth()*/}


<div className="infobox">
<Box mb={1}>stay length</Box>
              <h6>{bookingData.selectedDates.length}<br></br>night{bookingData.selectedDates.length==1?"":"s"}</h6> 



{/*<h1>{bookingData.selectedDates.length}</h1>night{bookingData.selectedDates.length==1?"":"s"}*/}
                    </div>

                    

      </Box>

      </div>
:

<div className="infobox full">
<Box m={1}> <div className="summary"><h5>Payment for Facilities Use Only</h5></div></Box>
              </div>
}













      <div className="infobox full">
      <Box m={1}>{bookingData.email}</Box>
      <Box m={1}> {bookingData.vehicleRegistration}</Box>
                    </div>



                    <Box mb={2}></Box>






 <div className="infobox full">
 <Box m={1}
        sx={{
          display: 'grid',
          columnGap: 1,
          rowGap: 1,
          gridTemplateColumns: 'repeat(2, 1fr)',
        }}
      >
         <div className="summary-item">Site fee:</div>
        <Box
        sx={{
          display: 'grid',
          columnGap: 1,
          rowGap: 1,
          gridTemplateColumns: 'repeat(2, 1fr)',
        }}
      >

       

{/*<div className="summary-item right">{payType === 'overnight' ? currencyFormat(content.priceStay*bookingData.selectedDates.length) : currencyFormat(content.priceFacilities)}</div>*/}
<div className="summary-item right">{payType === 'overnight' ? currencyFormat(getNetPrice()) : currencyFormat(content.priceFacilities)}</div>
        
        <div className="summary-item"><Box></Box></div>

 </Box>


 <div className="summary-item">Platform fee:</div>




        <Box 
        sx={{
          display: 'grid',
          columnGap: 1,
          rowGap: 1,
         
          gridTemplateColumns: 'repeat(2, 1fr)',
        }}
      >

{/*<div className="summary-item right">{payType === 'overnight' ? currencyFormat((((content.priceStay*bookingData.selectedDates.length) * (1+content.fee))-(content.priceStay*bookingData.selectedDates.length)).toFixed(2)) : currencyFormat(((content.priceFacilities * (1+content.fee))-content.priceFacilities).toFixed(2))}</div>
<div className="summary-item right">{payType === 'overnight' ? currencyFormat((((getNetPrice()) * (1+content.fee))-(content.priceStay*bookingData.selectedDates.length)).toFixed(2)) : currencyFormat(((content.priceFacilities * (1+content.fee))-content.priceFacilities).toFixed(2))}</div>*/}
<div className="summary-item right">{payType === 'overnight' ? currencyFormat(((Math.round(getNetPrice() * content.fee * 100)/100)).toFixed(2)) : currencyFormat(Number((Math.round(Number(content.priceFacilities) * content.fee*100)/100)).toFixed(2))}</div>
<div className="summary-item"><Box></Box></div>
 </Box>
        <div className="summary-item">CAMpRA donation:</div>
        <Box
        sx={{
          display: 'grid',
          columnGap: 1,
          rowGap: 1,
          gridTemplateColumns: 'repeat(2, 1fr)',
        }}
      >
        <div className="summary-item right"><IconButton
         size="small"
        color="primary"
      
        onClick={() => handleDonationChange(-1)}

      ><RemoveIcon /></IconButton>{currencyFormat(bookingData.donation)}</div>
        <div className="summary-item"><Box mt={0} width={20}><IconButton
         size="small"
        color="primary"
        onClick={() => handleDonationChange(1)}

      ><AddIcon /></IconButton></Box></div>
</Box>
<div className="summary-item"><b>Total payable:</b></div>


        <Box
        sx={{
          display: 'grid',
          columnGap: 1,
          rowGap: 1,
          gridTemplateColumns: 'repeat(2, 1fr)',
        }}
      >

{/*<div className="summary-item right"><b>{payType === 'overnight' ? currencyFormat((((content.priceStay*bookingData.selectedDates.length) * (1+content.fee))+(bookingData.donation)).toFixed(2)) : currencyFormat(((content.priceFacilities * (1+content.fee))+(bookingData.donation)).toFixed(2))}</b></div>*/}
<div className="summary-item right"><b>{payType === 'overnight' ? currencyFormat((((getNetPrice()) + (Math.round(getNetPrice() * content.fee * 100)/100))+(bookingData.donation)).toFixed(2)) : currencyFormat(Number((Number(content.priceFacilities) + (Math.round(content.priceFacilities * content.fee * 100)/100) + bookingData.donation)).toFixed(2))}</b></div>
<div className="summary-item"><Box></Box></div>
        </Box></Box>
      </div>




      <div className="infobox full">
 <Box m={1}>

 <FormControlLabel
                  control={
                    <Checkbox id="news"

                      disabled={isPaying}
                      checked={news}
                      onChange={handleNewsChange}

                    />
                  }
                  label={
                    <div>
                      <span >Yes, I want to occasionally hear from AireStop.<sup>
                        <IconButton disabled={false} edge="start" color="inherit" onClick={(e) => { setNewsInfo(true); e.target.blur() }} aria-label="info">
                          <InfoIcon style={{ height: "18px" }} />
                        </IconButton></sup></span>
                    </div>
                  }
                />


                <FormControlLabel
                  control={
                    <Checkbox id="tandc"

                      disabled={isPaying}
                      checked={tncs}
                      onChange={handleTncsChange}

                    />
                  }
                  label={
                    <div>
                      <span >I accept the </span>
                      {isPaying ?
                        <>terms and conditions</>
                        :
                        <Link onClick={tandcs} className="link" color='secondary' hover='secondary' to={'/'}>terms and conditions</Link>
                      }
                    </div>
                  }
                />

 </Box>
 </div>










  




 <LoaderButtonX
                  size="large"
                  type="button"
                  isLoading={isPaying}
                  disabled={!tncs || isPaying}
                  onClick={() => { handlePayment() }}>Make Secure Payment</LoaderButtonX>
    </div>


    </Paper>



  );
};

export default BookingSummary;
