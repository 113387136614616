import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import Form from "react-bootstrap/Form";
import LoaderButtonX from "../components/LoaderButtonX";
//import LoaderButtonTextOnly from "../components/LoaderButtonTextOnly";
import { useHistory} from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import "./Contact.css";

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

import FooterPublicLrg from "../components/FooterPublicLrg";

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const FooterTypography = withStyles({
  root: {
    color: "#ccc"
  }
})(Typography);

export default function Contact() {
  const history = useHistory();
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState("Message Sent");
  const [alertMessage, setAlertMessage] = useState("Thank you. Your message has been sent.");
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    name: "",
    feedback: "",
    authemail: "",
    authname: "",
    authphone: "",
    origin: ""
  });


  useEffect(() => {
    async function onLoad() {
      if (history.location.state && history.location.state.from) {
        fields.origin = history.location.state.from;
        handleFieldChange();
      }
      if (isAuthenticated) {
        const cui = await Auth.currentUserInfo();
        if (cui && cui.attributes) {
          fields.name = cui.attributes['custom:name'];
          fields.email = cui.attributes.email;
          fields.authname = cui.attributes['custom:name'];
          fields.authemail = cui.attributes.email;
          fields.authphone = cui.attributes['custom:phone'];
          handleFieldChange();
          //console.log(fields);
        }
      }
    }
    window.scrollTo(0, 0);
    onLoad();
  }, []);




  function validateForm() {
    //console.log(fields);
    return fields.email.length > 5 && fields.email.indexOf("@")>0 && fields.feedback.length > 15 && fields.name.length > 3;
  }

  function handleAlertClose(event) {
    event.preventDefault();
    history.goBack();
  }


  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      const result = await createFeedback({ content: fields });
      if (result.status) {
        setAlertTitle("Message Sent");
        setAlertMessage("Thank you. Your message has been sent.");
      } else {
        setAlertTitle("Message Error");
        if (result.error) {
          setAlertMessage("Your message was not able to be sent. " + result.error);
        } else {
          setAlertMessage("Your message was not able to be sent.");
        }
      }
      setAlertOpen(true);
    } catch (e) {
      setAlertTitle("Message Error");
      setAlertMessage("Your message was not able to be sent. " + e.message);
    }
    setIsLoading(false);
  }

  function createFeedback(feedback) {
    return API.post("aires", isAuthenticated?"/user/feedback":"/anon/feedback", {
      body: feedback
    });
  }


  return (
    <div className="Contact">
    <div className="lander">
    <Box display="flex" flexDirection="column" flexWrap="nowrap" my={0}  mx={2} alignItems='top'>
    <Box display="flex" flexWrap="wrap" mb={0}  mx={0} alignItems='top'> <h3>Contact Us</h3>  </Box>
    <Box mx={0.5}>
    <p style={{color: "#10385A"}}>Have a question or feedback? Let us know below!<br/>Send us a message and we’ll respond as soon as possible.</p>
    </Box>
    </Box>

    <Form onSubmit={handleSubmit} disabled={isLoading}>

      <Box my={2}>
      <TextField id="name"
      style={{width:"100%"}}
      value={fields.name}
      onChange={handleFieldChange}
      name="name"
      label="Name" type="text" variant="outlined" size="small"/>
      </Box>

      <Box my={2}>
      <TextField id="email"
      style={{width:"100%"}}
      value={fields.email}
      onChange={handleFieldChange}
      name="email"
      label="Email" type="email" variant="outlined" size="small"/>
      </Box>


      <Box pb={2}>
      <TextField id="feedback"
      multiline
      rows = {10}
      inputProps={{maxLength: 2000}}
      style={{width:"100%"}}
      value={fields.feedback}
      onChange={handleFieldChange}
      name="feedback"
      label="Message" type="text" variant="outlined" size="small"
      />
      </Box>


      <LoaderButtonX
      block
      size="lg"
      type="submit"
      isLoading={isLoading}
      disabled={!validateForm()}
      >
      Send My Message
      </LoaderButtonX>






<br/><br/>




      </Form>
      </div>

      <FooterPublicLrg/>
{/*}
      <Box
      position="sticky"
      bottom="0px"
      left="0px"
      right="0px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      bgcolor="#10385A">
      <Divider width="100%" style={{ background: '#fff' }}/>


      <Box display="flex" p={1} width="100%">

      <Box p={0} flexGrow={1}>
      <FooterTypography variant="caption">AIRESTOP<sup>®️</sup> LIMITED<br/> */}{/* ® */}
      {/*Company No. SC707418
      </FooterTypography>
      </Box>
      <Box p={0} display="flex" flexDirection="column">
      <a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://airestop.co.uk/docs/faqs.pdf"} target="_blank">FAQs</a>
      <Box style={{textAlign:"right"}} py={0.5}><a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://airestop.co.uk/docs/privacy.pdf"} target="_blank">Privacy</a></Box>
      <a style={{color:"white", fontSize:"12px", textAlign:"right"}} href={"https://airestop.co.uk/docs/tncs.pdf"} target="_blank">Terms of Use</a>
      </Box>
      </Box>
      <Box width="100%" mt={-0.8} pl={1} style={{textAlign:"left"}}><a style={{color:"#ccc", fontSize:"10px", textAlign:"left"}} >©2025 AireStop Limited. All rights reserved.</a></Box>
      </Box>
*/}

      <Dialog
        maxWidth='xs'
         open={alertOpen}
         onClose={handleAlertClose}
         aria-labelledby="alert-dialog-slide-title"
         aria-describedby="alert-dialog-slide-description">
         <DialogTitle id="alert-dialog-slide-title" style={{color:"#10385A"}} >{alertTitle}</DialogTitle>
         <DialogContent>
           <DialogContentText id="alert-dialog-slide-description">
               {alertMessage}
           </DialogContentText>
         </DialogContent>
         <DialogActions>
           <Button onClick={handleAlertClose} color="primary" >
             Ok
           </Button>
         </DialogActions>
       </Dialog>

      </div>



    );
  }
