import React, { useState, useEffect } from "react";
import { useHistory} from "react-router-dom";
import { onError } from "../libs/errorLib";

import { API } from "aws-amplify";
import "./Sign.css";
//import LoaderButton2 from "../components/LoaderButton2";
//import LoaderButton3 from "../components/LoaderButton3";

import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';



import Table from "react-bootstrap/Table";

import { makeStyles, withStyles, styled } from '@material-ui/core/styles';


import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import Button from '@material-ui/core/Button';
//import Button from "../components/LoaderButtonX";
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';

import ButtonBase from '@material-ui/core/ButtonBase';


import Fab from '@material-ui/core/Fab';


import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

//import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
//import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
//import Brightness3Icon from '@material-ui/icons/Brightness3';
//import InvertColorsIcon from '@material-ui/icons/InvertColors';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';

import iTopOvernightOff from'../images/preview/Pay Per Night - Grey.svg';
import iTopBaysOff from'../images/preview/Parking - Grey.svg';
import iTopFacilitiesOff from'../images/preview/Facilities Only - Grey.svg';

import iTopOvernight from'../images/preview/Pay Per Night - Blue.svg';
import iTopBays from'../images/preview/Parking - Blue.svg';
import iTopFacilities from'../images/preview/Facilities Only - Blue.svg';


import iRecyclingOff from'../images/facilities/Recycling - Grey.svg';
import iBlackWaterOff from'../images/facilities/Black Water Disposal - Grey.svg';
import iElectricOff from'../images/facilities/Electric Hook Up - Grey.svg';
import iGreyWaterOff from'../images/facilities/Grey Water Disposal - Grey.svg';
import iLaundryOff from'../images/facilities/Laundry - Grey.svg';
import iOvernightOff from'../images/facilities/Overnight Parking - Grey.svg';
import iRubbishOff from'../images/facilities/Rubbish - Grey.svg';
import iShowerOff from'../images/facilities/Shower - Grey.svg';
import iToiletOff from'../images/facilities/Toilet - Grey.svg';
import iWaterOff from'../images/facilities/Water - Grey.svg';
import iWifiOff from'../images/facilities/Wifi - Grey.svg';

import iRecycling from'../images/facilities/Recycling - Coral.svg';
import iBlackWater from'../images/facilities/Black Water Disposal - Coral.svg';
import iElectric from'../images/facilities/Electric Hook Up - Coral.svg';
import iGreyWater from'../images/facilities/Grey Water Disposal - Coral.svg';
import iLaundry from'../images/facilities/Laundry - Coral.svg';
import iOvernight from'../images/facilities/Overnight Parking - Coral.svg';
import iRubbish from'../images/facilities/Rubbish - Coral.svg';
import iShower from'../images/facilities/Shower - Coral.svg';
import iToilet from'../images/facilities/Toilet - Coral.svg';
import iWater from'../images/facilities/Water - Coral.svg';
import iWifi from'../images/facilities/Wifi - Coral.svg';



import iCampra from'../images/campra/Campra Accredited - Green.svg';

import defaultSiteImage from'../images/Default-Image.jpg';
import spinner from'../images/Driving-SVG.gif';


import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';


import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import Container from '@material-ui/core/Container';


import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';


import Form from "react-bootstrap/Form";

import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline';

import parse from 'html-react-parser';


import jsPDF from 'jspdf';
import 'jspdf-autotable';


import iLogo from "../images/logo.jpg";
import iQRLogo from "../images/qrcode_logo.svg";

import iCampraA from'../images/campra/Campra Accredited - Green.svg';
import iCampraX from'../images/campra/Campra Accredited - Grey.svg';


import QRCode from 'qrcode';
import { createCanvas, loadImage } from "node-canvas";

import '../fonts/Montserrat-Regular-normal.js';


//import CheckboxLabels from "../components/CheckboxLabels";
const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});
const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },

  },
})(TextField);

const BlueTooltip = withStyles((theme) => ({
  arrow: {
    "&:before": {
      border: "1px solid #10385A"
    },
    color: '#10385Acc'
  },
  tooltip: {
    backgroundColor: '#10385Add',
    color: '#ffffff',
    border: "1px solid #10385A",
    fontSize: 12,
    fontFamily: "Montserrat",
    fontWeight: 400,

  },
}))(Tooltip);


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      background: "#000",
    },
  },
  imageList: {
    width: 500,
    height: 48,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  card: {
    margin: theme.spacing(2),
  },
  h4: {
    fontFamily: 'Comfortaa',
    color: '#10385A4'
  },

  dialogContent: {
    width: '100%',
    backgroundColor: '#10385A',
    paddingTop: '80px',
    height:"100vh",
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
    color: 'white'
  },
  pdf: {cursor:'pointer', marginTop: '-0px', marginRight: '-8px', color:'#10385A4'},

  link: {
      color: "#F18C7E",
      "&:hover": {
          color: "#F18C7E",
          textDecoration: "underline #F18C7E"
      }
  },
  spreadBox: {
    justifyContent: "space-between"
  },

  textField: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    },
    "& .MuiOutlinedInput-input": {
      color: "red"
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "red"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "red"
    },
    "& .MuiInputLabel-outlined": {
      color: "red"
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "red"
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "red"
    },


  }



}







));

/*
const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  // Return the width so we can use it in our components
  return { width };
}

*/


export default function Sign() {
  let classes = useStyles();

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [mode, setMode] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertVals, handleAlertChange] = useFormFields({
    title: "",
    message: "",
  });
  //const [siteCode, setSiteCode] = useState("");
  //const [size, setSize] = useState("");












  //const { width } = useViewport();
  //const breakpoint = 600;

  useEffect(() => {
    onLoad();
  }, []);

  function getSite(code) {
    return API.get("aires", `/sites/${code}/DETAILS`);
  }

  function updateTranStatus(tranId) {
    return API.put("aires", `/tran`, {body: {
      type: 'POSTED',
      tranId: tranId
    }});
  }

  function getTran(tranId) {
    return API.get("aires", `/tran/${tranId}`);
  }


  async function onLoad() {
    try {
      const parts = window.location.href.split("?");
      if (parts.length > 1) {
        if (parts[1].indexOf('s=')>-1 && parts[1].indexOf('d=')>-1) {
          setMode("PDF");
          const attbs = parts[1].split("&");
          const siteCode = attbs[0].split("=")[1];
          const size = attbs[1].split("=")[1];
          const result = await getSite(siteCode);
          if (result && result.hasOwnProperty("data")) {
            handlePrintPDF(result.data, size==="A4"?0:1);
            setIsSuccess(true);
          }
        } else if (parts[1].indexOf('p=')>-1) {
          setMode("POSTED");
          const tranId = getTranIdFromCode(parts[1].split("=")[1]);
          const result = await updateTranStatus(tranId);
          if (result.status) {
            setIsSuccess(true);
          }
        } else if (parts[1].indexOf('o=')>-1) {
          setMode("ORDER");
          const tranId = getTranIdFromCode(parts[1].split("=")[1]);
          const result = await getTran(tranId);
          //console.log(result);
          if (result && result.hasOwnProperty("tran")) {
            handlePrintNotePDF(result.tran);
            setIsSuccess(true);
          }
        }
      }
      setIsLoading(false);
    } catch (e) {
      onError(e);
      //history.push("/");
    }
  }

  function handleAlertClose() {
    setAlertOpen(false);
  };

  function getTranIdFromCode(code) {
    const charCount = parseInt(code.charAt(2));
    let tranId = "AS" + code.charAt(5);
    if (charCount>1) {
      tranId += code.charAt(10);
    }
    if (charCount>2) {
      tranId += code.charAt(14);
    }
    if (charCount>3) {
      tranId += code.charAt(21);
    }
    if (charCount>4) {
      tranId += code.charAt(25);
    }
    if (charCount>5) {
      tranId += code.charAt(30);
    }
    return tranId;
  }


  async function handlePrintPDF(site, size) {
    const r = size==0?1:1.8;//2.125984;
    const qrd = size==0?300:526;
    const qroffset = size==0?185:322;
    const datestr = (new Date(Date.now())).toLocaleString();
    const fileName = "AireStop_" + site.itemCode + "_" + site.content.siteName.replace(/\s/g, '_') + "_" + datestr + ".pdf";
    const canvas = createCanvas(qrd, qrd);
    const data = 'https://airestop.co.uk/sites/' + site.itemCode;
    const img = await loadImage(iQRLogo);
    var doc;

    if (size==0) {
      doc = new jsPDF('l', 'px', 'a4');
    } else {
      doc = new jsPDF('l', 'px', [992.22, 701.57]);// mm=[466x330]
    }
    //var doc = new jsPDF('l', 'px', [992.22, 701.57]);
    //var doc = new jsPDF('p//, 'mm', [508, 330]);
    doc.setFont('Montserrat-Regular');
    doc.setTextColor(16, 56, 90);
    doc.setCharSpace(6);

    var iii = new Image();
    iii.onload = function(){
      let zzz = doc.getImageProperties(iii);
    };

    QRCode.toCanvas(canvas, data,  {
      errorCorrectionLevel: "H",
      margin: 0,
      color: {
        dark: "#10385A",
        light: "#ffffff",
      },
      width: qrd
    }
    , function (error) {
      if (error) {
        console.error(error)
      } else {
        const ctx = canvas.getContext("2d");
        const w = 50*2*r;
        const h = 50*2*r;//42.801556*2;
        const ctrex = ((qrd)-w) / 2;
        const ctrey = ((qrd)-h) / 2;
        ctx.drawImage(img, ctrex, ctrey, w, h);
        const b64qrcode = canvas.toDataURL("image/png");


        iii.src = b64qrcode;


        doc.setProperties({
          title: 'AireStop Notice',
          subject: site.content.siteName + " (" + site.itemCode + ")",
          author: 'AireStop Limited',
          keywords: '',
          creator: 'AireStop Limited'
        });

        let pr = doc.getImageProperties(iLogo);
        const rat = pr.height/pr.width;
        doc.setFontSize(70*r);
        let tr = doc.getTextWidth(site.content.siteName);
        let docw = doc.internal.pageSize.width;
        let doch = doc.internal.pageSize.height;
        let margin = 60;
        let ypos = 30;
        let boxY = 0;
        //let fh = 115*r;
        doc.setFillColor(255,255,255);
        doc.rect(0, 0, docw, doch, 'F');

        doc.addImage(iLogo, "JPG", margin, ypos+10, docw-(margin*2),((docw-(ypos*2))*rat)-15);
        ypos = ypos + (docw-(ypos*2))*rat + 15;
        doc.setDrawColor(16, 56, 90);
        doc.setLineWidth(size==0?2:4);
        doc.setFillColor(0);
        //doc.rect((margin/2), ypos, docw-(margin), 200, 'D');
        doc.rect((margin/2), ypos, docw-(margin), doch-(35*r*2)-40-ypos, 'D');
        let aaa = doch-(35*r*2)-40-ypos;
        boxY = ypos;
        doc.addImage(b64qrcode, "PNG", docw-(margin/2)-qroffset, ypos+(14*r));
        doc.setFontSize(70*r);
        ypos += (16 + 35)*r;
        doc.text("PAY HERE", (margin/2) + (12*r), ypos);
        doc.setFontSize(22*r);
        ypos += (10 + 16)*r;
        doc.text("WWW.AIRESTOP.CO.UK", (margin/2) + (16*r), ypos);
        doc.setFontSize(30*r);
        doc.text("SITE CODE", (margin/2) + (16*r), doch-(35*r*2)-40-((16+50)*r));
        doc.setFontSize(70*r);
        doc.text(site.itemCode, (margin/2) + (12*r), doch-(35*r*2)-40-(16*r));
        doc.setFontSize(30*r);
        tr = doc.getTextWidth(site.content.siteName.toUpperCase());
        doc.text(site.content.siteName.toUpperCase(), ((docw-tr)/2)-6, doch-(35*r)-40);
        doc.setLineWidth(size==0?1:2);
        doc.setFontSize(22*r);
        tr = doc.getTextWidth("ENJOY YOUR VISIT - LEAVE NO TRACE");
        doc.text("ENJOY YOUR VISIT - LEAVE NO TRACE", ((docw-tr)/2)-22, doch-40);
        doc.line(((docw-tr)/2)-22, doch-(23*r)-40, ((docw-tr)/2) + tr + 22, doch-(23*r)-40);
        doc.save(fileName);
      }
    })
  }

  async function handlePrintNotePDF(order) {
    const datestr = (new Date(Date.now())).toLocaleString();
    const fileName = "AireStop_" + order.itemId + "_" + datestr + ".pdf";
    var doc = new jsPDF('p', 'px', 'a4');
    doc.setFont('Montserrat-Regular');
    doc.setTextColor(16, 56, 90);
    doc.setCharSpace(0);

    doc.setProperties({
      title: 'AireStop Package Note',
      subject: "Order no. " + order.itemId,
      author: 'AireStop Limited',
      keywords: '',
      creator: 'AireStop Limited'
    });

    let pr = doc.getImageProperties(iLogo);
    const rat = pr.height/pr.width;
    doc.setFontSize(70);
    let tr = doc.getTextWidth(order.itemId);
    let docw = doc.internal.pageSize.width;
    let doch = doc.internal.pageSize.height;
    let margin = 60;
    let ypos = 30;
    let boxY = 0;
    doc.setFillColor(255,255,255);
    doc.rect(0, 0, docw, doch, 'F');

    doc.addImage(iLogo, "JPG", margin + 50, ypos+10, docw-(margin*2)-100,((docw-(ypos*2)-50)*rat)-15);
    ypos = ypos + (docw-(ypos*2))*rat + 15;
    boxY = ypos;
    doc.setFontSize(22);
    ypos += (16 + 8);
    doc.text("Order no. " + order.itemId, (margin/2) + 12, ypos);
    doc.setFontSize(14);
    ypos += (10 + 4);

    doc.text(order.content.metadata.name + ", " + order.content.metadata.address, (margin/2) + 12, ypos);
    ypos += (10 + 8);

    doc.text("Order Details:", (margin/2) + 12, ypos);
    ypos += 6
    let lineItems = JSON.parse(order.content.metadata.line_items);
    let body = [];
    lineItems.map( (item) => {
      if (item.alu_quantity) {
        body.push([item.site, "Dibond (Aluminium)", item.alu_quantity]);
      }
      if (item.pla_quantity) {
        body.push([item.site, "Foamex (Plastic)", item.pla_quantity]);
      }
      if (item.sti_quantity || item.sti_quantity_free) {
        let tot = 0;
        if (item.sti_quantity) {
          tot += item.sti_quantity;
        }
        if (item.sti_quantity_free) {
          tot += item.sti_quantity_free;
        }
        body.push([item.site, "Vinyl Sticker", tot]);
      }
    });
    doc.setLineDash();
    doc.autoTable({
      didDrawPage: function(data) {
          ypos = data.cursor.y;
      },
      columns: [
        { header: 'Product', dataKey: 'product' },
        { header: 'Type', dataKey: 'type' },
        { header: 'Quantity', dataKey: 'quantity' },
      ],
      body: body,
      theme: 'grid',
      headStyles: {
        fillColor: [16, 56, 90],
      },
      columnStyles: {
        quantity:{halign: 'center'},
      },
      margin: {top: ypos, left: (margin/2) + 12, right: (margin/2) + 12},
    })
ypos += 16;
    doc.setDrawColor(16, 56, 90);
    doc.setLineWidth(1);
    doc.setFillColor(0);
    doc.rect((margin/2), boxY, docw-(margin), ypos-boxY, 'D');
    //doc.rect((margin/2), boxY, docw-(margin), doch-ypos-(margin), 'D');
    doc.setFontSize(10);

    //doc.text("©2025 AireStop Limited. All rights reserved.", (margin/2) + 0, doch-(margin) + 10);
    doc.text("©2025 AireStop Limited. All rights reserved.", (margin/2) + 0, ypos + 10);



    //ypos += (lineItems.length + 1) * 24;
    doc.setFontSize(14);

    //order.content.metadata.instructions = "khjsdg hgljagsdljg lvgljhg lhfkh fkjhaskfjhaksjhf kjsh vkjafskjha lkjfkajfj afkjh akjsfhak jsfkjahf kjahsf kja kjfh lkajfh lkajsfh lkjaf lkjh asflkjhalkjsf lkja flkja"
    let splitInstr = [];
    if (order.content.metadata.instructions && order.content.metadata.instructions.length>0) {
      let inst = "NOTE: Driver Instructions - " + order.content.metadata.instructions;
      splitInstr = doc.splitTextToSize(inst, docw-(margin)-24);
    }
    let addrParts = order.content.metadata.address.split(",");

    ypos = doch - ((12 * splitInstr.length) + ((10 + 8) * (addrParts.length + 1))  + margin );
    //doc.text("Address Details:", (margin/2) + 12, ypos);
    //ypos += (10 + 0);
    if (order.content.metadata.instructions && order.content.metadata.instructions.length>0) {
      ypos += (2);
      //let inst = "Driver Instructions: " + order.content.metadata.instructions;
      //var splitInstr = doc.splitTextToSize(inst, docw-(margin)-24);
      doc.text((margin/2) + 12, ypos, splitInstr);
      ypos += 12 * splitInstr.length;
    }
    doc.setDrawColor(150, 150, 150);
    doc.setLineWidth(1);
    doc.setFillColor(0);
    doc.setLineDash([2,4]);
    doc.line(0, ypos + 0, docw, ypos+0 , 'D');

    ypos += (10 + 8);
    doc.setFontSize(18);
    doc.text(order.content.metadata.name, (margin/2) + 12, ypos);
    ypos += (10 + 8);
    addrParts.map( (part) => {
      doc.text(part.trim(), (margin/2) + 12, ypos);
      ypos += (10 + 8);
    });
    ypos+=6+12;





    doc.save(fileName);
  }

function renderLoader() {
  return (
    <div id="splash" style={{paddingTop: 100, zIndex:999}}>
      <center id="splash">
        <img src={spinner} height="30px" width="auto"/>
      </center>
    </div>
  );
}


  function renderLander() {
    return (
      <>
        <Box display="flex" flexDirection="column" flexWrap="nowrap" my={0}  mx={2} alignItems='top'>
        <Box display="flex" flexWrap="wrap" mb={0}  mx={0} alignItems='top'> <h2  className={classes.h2}>{mode==="PDF"?"AireStops PDF":(mode==="POSTED"?"AireStop Signs Posted":(mode==="ORDER"?"AireStop Order - Package Note":""))}</h2>  </Box>
        <Box mx={0.5}>
        {isSuccess?
          <p>Success.</p>
        :
          <p>Failed.</p>
        }
        </Box>
        </Box>
      </>
    );
  }





  return (

    <div className="Sign">
      {isLoading?renderLoader():renderLander()}
      <Dialog
      open={alertOpen}
      fullWidth={false}
      maxWidth = {'md'}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      >
      <DialogTitle id="alert-dialog-slide-title" style={{color:"#10385A"}} >
      {alertVals.title}
      </DialogTitle>
      <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
      {alertVals.message}
      </DialogContentText>
      </DialogContent>
      <DialogActions>
      <Button onClick={handleAlertClose} color="secondary">
      Ok
      </Button>
      </DialogActions>
      </Dialog>
    </div>
  );
}
